import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledHeaderContainer, StyledIconOpenStatus, StyledListSelected,
  StyledSelect
} from '@h1-card/h1-lib-ui/dist/components/molecules/Select/components/SelectHeader/StyledSelect';
import Col from '@h1-card/h1-lib-ui/dist/components/atoms/Col';
import InfoLabel from '@h1-card/h1-lib-ui/dist/components/atoms/InfoLabel';
import Text from '@h1-card/h1-lib-ui/dist/components/atoms/Text';
import Tooltip from '@h1-card/h1-lib-ui/dist/components/atoms/Tooltip';
import ChevronDownIcon from '@h1-card/h1-lib-ui/dist/components/icons/ChevronDownIcon';
import {helpers} from '../../../../helpers';


const SingleLabel = ({item, showCounter, showImage}) => {
  const withImage = showImage && item.image;
  return (
    <div className='d-flex align-items-center justify-space-between' style={{width: '100%'}}>
      <div className='d-flex align-items-center justify-space-between' style={{width: '100%'}}>
        <div className='d-flex align-items-center'>
          {withImage && <span className='d-flex pre-image'>{item.image}</span>}
          <span className={`select-item-label`}>{item.label}</span>
        </div>
        {showCounter && item.count !== null && item.count !== undefined ? <InfoLabel
            className='custom-label'
            label={`${item.count}`}
            style={{backgroundColor: item.countColor ? item.countColor :'#f2f2f2'}}
          /> : ''}
      </div>
    </div>
  )
};

const MultipleLabel = ({items, showCounter}) => {
  let labels = items.map(item => item.label).join(', ');
  const count = items.length > 0
    ? items.map(item => item.count).reduce((a, b) => a + b)
    : 0;
  if (showCounter && labels.length > 22) labels = `${labels.substring(0, 20)}..`

  return (
    <div className='d-flex align-items-center justify-space-between' style={{width: '100%'}}>
      <div className='d-flex align-items-center justify-space-between' style={{width: '100%'}}>
        {labels}
        {showCounter && (
          <InfoLabel
            className='custom-label'
            label={`${count}`}
            style={{backgroundColor: '#f2f2f2'}}
          />
        )}
      </div>
    </div>
  )
};

const PlaceholderBox = ({placeholder, type, showCounter, options}) => {
  const label = placeholder || defaultPlaceholderByTypeMap[type];
  const optionsExist = options && options.length > 0;
  if (!showCounter || !optionsExist) {
    return (
      <Text tag='span' size='xbody'>
        {label}
      </Text>
    )
  } else {
    const valueCount = options.length > 0 ? helpers.getSumInList(options, 'count') : 0;
    return (
      <div className='d-flex align-items-center justify-space-between' style={{width: '100%'}}>
        <div className='d-flex align-items-center justify-space-between' style={{width: '100%'}}>
          {label}
          {helpers.isNaV(valueCount) && (
            <InfoLabel
              className='custom-label'
              label={valueCount.toString()}
              style={{backgroundColor: '#e8e8e8'}}
            />
          )}
        </div>
      </div>
    )
  }
}

const SelectedItemBox = ({
  labelsSelected,
  itemsSelected,
  activeCounter,
  isCalendar,
  disabled,
  type,
  showCounter,
  showImage,
  ...rest
}) => {
  const tooltipLabel = [];
  let labelsSelectedList = [];
  if (itemsSelected.length === 1) {
    labelsSelectedList = itemsSelected.map((item, index) => {
      tooltipLabel.push(item);
      return (
        <SingleLabel
          key={index}
          item={item}
          showCounter={showCounter}
          showImage={showImage}
        />
      );
    });
  } else {
    labelsSelectedList = (
      <MultipleLabel items={itemsSelected} showCounter={showCounter} />
    );
  }
  const tooltipLabelFormatted = tooltipLabel.map((item, index) => {
    return (
      <Text key={index} size='small' variant='light'>
        {item}
      </Text>
    );
  });

  return (
    <React.Fragment {...rest}>
      {labelsSelectedList}
      {activeCounter && !disabled && (
        <Tooltip content={tooltipLabelFormatted}>
          {/*<Counter*/}
          {/*  size='small'*/}
          {/*  variant='secondary'*/}
          {/*  values={labelsSelectedList.length}*/}
          {/*/>*/}
        </Tooltip>
      )}
    </React.Fragment>
  );
};

const defaultPlaceholderByTypeMap = {
  standard: 'All',
  multiple: 'All',
  singleTree: 'All',
  multipleTree: 'Categories selected',
  datePicker: 'Choose a time frame',
  timePicker: 'Select'
};

const SelectHeader = ({
  type,
  label,
  error,
  size,
  disabled,
  icon,
  labelsSelected,
  itemsSelected,
  activeCounter,
  onHeaderClick,
  children,
  isCalendar,
  placeholder,
  isSelectOpen,
  tabIndex,
  onBlur,
  onFocus,
  showCounter,
  showImage,
  options,
  customLabel,
  ...rest
}) => {
  const labelBox = label && (
    <Col span={'auto'}>
      <Text tag='label' variant='dark_lighter' size='small'>
        {label}
      </Text>
    </Col>
  );
  const isMultipleTreeType = type === 'multipleTree';
  const checkboxCounter = isMultipleTreeType && labelsSelected[0] + ' ';
  return (
    <StyledHeaderContainer
      aria-haspopup={true}
      aria-expanded={isSelectOpen}
      tabIndex={tabIndex}
      isSelectOpen={isSelectOpen}
      onBlur={() => {
        if (type === 'standard' && isSelectOpen) {
          onBlur && onBlur();
          setTimeout(() => onHeaderClick(false), 300)
        } else {
          onBlur && !isSelectOpen && onBlur();
        }
      }}
      onFocus={() => {
        onFocus && onFocus();
      }}
      error={error}
      disabled={disabled}
      size={size}
      role='button'
      type={type}
      {...rest}
    >
      {labelBox}
      <StyledSelect size={size} onClick={() => onHeaderClick(!isSelectOpen)}>
        <StyledListSelected>
          {labelsSelected.length > 0 &&
          labelsSelected[0] !== null &&
          !isMultipleTreeType ? (
            <SelectedItemBox
              labelsSelected={labelsSelected}
              itemsSelected={itemsSelected}
              activeCounter={activeCounter}
              isCalendar={isCalendar}
              disabled={disabled}
              type={type}
              showCounter={showCounter}
              showImage={showImage}
            />
          ) : customLabel ? customLabel : (
            <PlaceholderBox
              placeholder={placeholder}
              type={type}
              showCounter={showCounter}
              options={options}
              checkboxCounter={checkboxCounter} />
          )}
        </StyledListSelected>
        {icon && (
          <StyledIconOpenStatus>
            <ChevronDownIcon
              className='select-icon-status'
              variant='dark_lighter'
              size='xsmall'
            />
          </StyledIconOpenStatus>
        )}
      </StyledSelect>
      {children}
    </StyledHeaderContainer>
  );
};

SelectHeader.displayName = 'SelectHeaderCustom';
SelectHeader.propTypes = {
  type: PropTypes.oneOf(['standard', 'multiple', 'singleTree', 'multipleTree', 'datePicker', 'timePicker', 'inline']),
  label: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  icon: PropTypes.bool,
  isSelectOpen: PropTypes.bool,
  labelsSelected: PropTypes.array,
  onHeaderClick: PropTypes.func,
  activeCounter: PropTypes.bool,
  isCalendar: PropTypes.bool,
  size: PropTypes.oneOf(['standard', 'small', 'thin'])
};
SelectHeader.defaultProps = {
  type: 'standard',
  error: false,
  disabled: false,
  icon: true,
  selectFocus: false,
  activeCounter: false,
  isCalendar: false,
  showCounter: true,
};
export default SelectHeader;
