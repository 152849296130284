import {authHeader} from './authHeaders';
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import {alertActions, mainActions} from '../actions';
import {helpers} from '../../helpers';
import {helpers as servicesHelpers} from '../services/helpers';
import {localStorageKeysConstants} from '../../constants';

const {COMPANY_ID, PHONE_NUMBER, SESSION_ID, TOKEN} = localStorageKeysConstants;

// Function that will be called to refresh authorization
const refreshAuthLogic = failedRequest => {
  const token = JSON.parse(localStorage.getItem(TOKEN));
  const phoneNumber = localStorage.getItem(PHONE_NUMBER);
  const url = request.getRequestUrl('auth/login/tokens/refresh');
  if (token && token.refreshToken) {
    const data = {
      phone_number: atob(phoneNumber),
      refresh_token: token.refreshToken
    };
    return axios.post(url, data)
      .then(tokenRefreshResponse => {
        servicesHelpers.saveJWT(tokenRefreshResponse.data);
        failedRequest.response.config.headers['Authorization'] = 'Bearer ' + tokenRefreshResponse.data.AccessToken;
        return Promise.resolve();
      })
      .catch(err => {
        if (err.hasOwnProperty('response') && err.response.hasOwnProperty('status')) {
          if (err.response.status === 400) servicesHelpers.clearUserStoredData()
        }
      }) ;
  }
}

// Instantiate the interceptor
createAuthRefreshInterceptor(axios, refreshAuthLogic);

axios.interceptors.request.use(
  request => {
    const token = JSON.parse(localStorage.getItem(TOKEN));
    const company = localStorage.getItem(COMPANY_ID);
    const sessionId = localStorage.getItem(SESSION_ID);
    const apiType = request.params ? request.params.apiType : false;

    if (token && token.accessToken) request.headers['Authorization'] = `Bearer ${token.accessToken}`;
    if (sessionId) request.headers['SessionId'] = sessionId;
    if (company) {
      if (apiType) {
        request.headers[apiType === 'banking' ? 'company-id' : 'companyid'] = company; // 'companyid' for API && 'company-id' for Banking API
      } else {
        request.headers['companyid'] = company;
      }
    }
    if (apiType) delete request.params.apiType;
    return request;
  },
  error => Promise.reject(error)
);

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
const bankingApiEndpoint = process.env.REACT_APP_BANKING_API_ENDPOINT;

export const errorHandler = (error, errorFunc, props) => {
  const disableAlert = helpers.getObjProp(props, 'disableAlert', false);

  try {
    let errorMessage;
    const {status} = error.response;
    if (error.response) {
      const {data} = error.response;
      if (data) {
        if (data.hasOwnProperty('message')) {
          errorMessage = data.message;
        } else if (data.hasOwnProperty('detail')) {
          const {detail} = data;
          errorMessage = typeof (detail) === 'object' && detail.length > 0 ? detail[0].msg : detail.toString();
        }
      } else {
        errorMessage = error.toString();
      }
    } else {
      errorMessage = error.toString();
    }
    if (errorFunc) errorFunc(error);
    if (![401, 403].includes(status) && !disableAlert) alertActions.error(errorMessage);
  } catch (e) {
    if (errorFunc) errorFunc(error);
    alertActions.error(error.toString());
  }
}

class Request {
  constructor(endpoint, apiType = 'api') {
    this.endpoint = endpoint;
    this.apiType = apiType;
  }
  getAuthHeader = (params, shipAuthRefresh = false) => {
    return authHeader({apiType: this.apiType, ...params}, shipAuthRefresh);
  };
  getRequestUrl = url => `${this.endpoint}${url}`;
  get = (
    dispatch,
    url,
    query,
    success,
    error,
    loading = false,
    props = {}
  ) => {
    if (loading) mainActions.setLoading(true, dispatch);
    axios
      .get(this.getRequestUrl(url), this.getAuthHeader(query))
      .then((result) => {
        let {data} = result;
        if (data.hasOwnProperty('errors')){
          alertActions.error(data.errors.toString());
        } else {
          success(data);
        }
      })
      .catch((err) => errorHandler(err, error, props))
      .finally(() => {
        if (loading) mainActions.setLoading(false, dispatch);
      });
  }

  getWithPromise = (url, query) => axios.get(this.getRequestUrl(url), this.getAuthHeader(query))
  post = (dispatch,
         url,
         data,
         success,
         error,
         loading = false,
         props = {},
         skipAuthRefresh) => {
    if (loading) mainActions.setLoading(true, dispatch);
    axios
      .post(this.getRequestUrl(url), data, this.getAuthHeader({}, skipAuthRefresh))
      .then((result) => {
        let {data} = result;
        if (data.hasOwnProperty('errors')){
          alertActions.error(data.errors.toString());
        } else {
          success(data);
        }
      })
      .catch((err) => errorHandler(err, error, props))
      .finally(() => {
        if (loading) mainActions.setLoading(false, dispatch);
      });
  }
  patch = (
    dispatch,
    url,
    data,
    success,
    error,
    loading = false,
    props
  ) => {
    if (loading) mainActions.setLoading(true, dispatch);
    axios
      .patch(this.getRequestUrl(url), data, this.getAuthHeader())
      .then((result) => {
        let {data} = result;
        if (data.hasOwnProperty('errors')){
          alertActions.error(data.errors.toString());
        } else {
          success(data);
        }
      })
      .catch((err) => errorHandler(err, error, props))
      .finally(() => {
        if (loading) mainActions.setLoading(false, dispatch);
      });
  }
  put = (dispatch,
        url,
        data,
        success,
        error,
        loading = false,
        query = null,
        props = {}) => {
    if (loading) mainActions.setLoading(true, dispatch);
    axios
      .put(this.getRequestUrl(url), data, this.getAuthHeader(query))
      .then((result) => {
        let {data} = result;
        if (data.hasOwnProperty('errors')){
          alertActions.error(data.errors.toString());
        } else {
          success(data);
        }
      })
      .catch((err) => errorHandler(err, error, props))
      .finally(() => {
        if (loading) mainActions.setLoading(false, dispatch);
      });
  }
  delete = (dispatch,
           url,
           success,
           error,
           loading = false,
           query = null) => {
    if (loading) mainActions.setLoading(true, dispatch);
    axios
      .delete(this.getRequestUrl(url), this.getAuthHeader(query))
      .then((result) => {
        let {data} = result;
        if (data.hasOwnProperty('errors')){
          alertActions.error(data.errors.toString());
        } else {
          success(data);
        }
      })
      .catch((err) => errorHandler(err, error))
      .finally(() => {
        if (loading) mainActions.setLoading(false, dispatch);
      });
  }
}

export const request = new Request(apiEndpoint);

export const bankingRequest = new Request(bankingApiEndpoint, 'banking');


export const getMethodTemplate = (request, url, query, success, error, additionalProps) => {
  return (dispatch) => {
    request.get(
      dispatch,
      url,
      query,
      (data) => success && success(data),
      (data) => error && error(data),
      false,
      additionalProps
    );
  }
}

export const postMethodTemplate = (request, url, data, success, error, additionalProps) => {
  return (dispatch) => {
    request.post(
      dispatch,
      url,
      data,
      (data) => success && success(data),
      (data) => error && error(data),
      false,
      additionalProps
    );
  }
}

export const putMethodTemplate = (request, url, data, success, error) => {
  return (dispatch) => {
    request.put(
      dispatch,
      url,
      data,
      (data) => success && success(data),
      (data) => error && error(data)
    );
  }
}

export const deleteMethodTemplate = (request, url, success, error) => {
  return (dispatch) => {
    request.delete(
      dispatch,
      url,
      (data) => success && success(data),
      (data) => error && error(data)
    );
  }
}
