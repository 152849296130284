import React from 'react';
import ReactDOM from 'react-dom';
import {I18nextProvider} from 'react-i18next';
import { Provider } from 'react-redux';
import {createTheme, ThemeProvider as MuiThemeProvider} from '@mui/material';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import i18next from './translations';
import { store } from './state/store';
import theme from './theme';

const materialTheme = createTheme({
  palette: {
    primary: {
      main: theme.colors.secondary
    }
  }
});

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <Provider store={store}>
      <MuiThemeProvider theme={materialTheme}>
        <App />
      </MuiThemeProvider>
    </Provider>
  </I18nextProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
