import React from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@mui/styles';
import LogoWithText from '../../../../static/images/H1_LogoWhite.png';

const useStyles = makeStyles(theme => ({
  logo: {
    paddingLeft: 16,
    '& img': {
      height: 50
    },
    [theme.breakpoints.down('md')]: {
      '& img': {
        height: 40
      },
    }
  },
  background: {
    backgroundColor: '#000000',
    height: 88,
    '& a': {
      height: 50,
    },
    [theme.breakpoints.down('md')]: {
      height: 75,
      '& a': {
        height: 40,
      },
    }
  },
  container: {
    padding: '0 22px 0 10px',
    maxWidth: 1000,
    margin: '0 calc((100vw - 1000px) / 2)',
    color: '#fff',
    fontSize: 18,
    lineHeight: '16px',
    fontWeight: 500,
    '& svg': {
      height: 88,
      width: 'auto'
    },
    '& a': {
      marginRight: 12,
    },
    [theme.breakpoints.down('md')]: {
      '& svg': {
        height: 75,
      },
      fontSize: 16,
      lineHeight: '14px'
    },
    '@media (max-width: 1044px)': {
      marginLeft: 0
    }
  },
}));

const SignUpHeader = ({showButton, buttonLink, buttonText, buttonOnClick, ...rest}) => {
  const classes = useStyles();
  const [t] = useTranslation('main');

  return (
    <div className={`${classes.background} d-flex align-items-center`} {...rest}>
      <div className={`d-flex align-items-end ${classes.container}`}>
        <Link to='/' className={`${classes.logo} d-flex align-items-center`}>
          <img src={LogoWithText} alt='H1 logo' />
        </Link>
        {t('internal')}
      </div>
    </div>
  );
}

export default SignUpHeader;
