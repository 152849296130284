import React, {useState} from 'react';
import {makeStyles} from '@mui/styles';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import Alert from '../../../atoms/Alert';
import FeedbackTextArea from '../FeedbackTextArea';
import {H1KYCStatusesConstants, TZKYCReviewStatusesConstants, userTypesConstants} from '../../../../constants';
import {alertActions, submissionsActions} from '../../../../state/actions';

const { TREEZOR_REVIEWED, TREEZOR_SUBMITTED, ACCEPTED } = H1KYCStatusesConstants;
const { LEGAL_PERSON, LEGAL_REPRESENTATIVE, UBO } = userTypesConstants;
const { REFUSED, VALIDATED } = TZKYCReviewStatusesConstants;


const useStyles = makeStyles(() => ({
  providerResponse: {
    padding: 24
  },
  buttons: {
    textAlign: 'right',
    '& button': {
      marginTop: 20,
      height: 40,
      minWidth: 128,
      '&:not(:last-of-type)': {
        marginRight: 12
      }
    }
  }
}));

const BankingProviderResponse = ({
  status,
  users,
  reviewSubmission,
  successAlert
}) => {
  const [t] = useTranslation(['kyc', 'main']);
  const classes = useStyles();

  const [submittedUserId, setSubmittedUserId] = useState(null);

  const isReviewedStatus = (status) => status === TREEZOR_REVIEWED || ACCEPTED;
  const isRejectedUsers = (users) => users.some(u => u.kyc_review === REFUSED);
  const isReviewed = isReviewedStatus(status);

  const getUserTitle = (userType, index) => {
    return {
      [LEGAL_PERSON]: 'main:company',
      [LEGAL_REPRESENTATIVE]: 'kyc:legalRepresentative',
      [UBO]: `UBO ${index}`
    }[userType] || '';
  }

  const handleSendFeedback = (userId, comment) => {
    setSubmittedUserId(userId);
    reviewSubmission(
      userId,
      'reject',
      comment,
      () => {
        setSubmittedUserId(null);
        successAlert(t('feedbackSentSuccessfully'))
      }
    );
  }

  const getFeedbacks = () => {
    let uboIndex = 1;
    return users
      .sort((a, b) => a.user_type.localeCompare(b.user_type))
      .map(user => {
        const {
          id,
          user_type: userType,
          kyc_review_comment: comment,
          kyc_review: status
        } = user;
        const title = getUserTitle(userType, uboIndex);
        const isDisabledButton = status === VALIDATED;
        const isButtonLoading = submittedUserId === id;
        if (userType === UBO) uboIndex++;
        return (
          <div className={classes.feedback} key={`user-${id}`}>
            <h2>{t(title)}</h2>
            <FeedbackTextArea
              initialValue={comment || ''}
              disabled={true}
              disabledButton={isDisabledButton}
              onSubmit={() => handleSendFeedback(id, comment)}
              isButtonLoading={isButtonLoading}
            />
          </div>
        )
    })
  }

  const feedbacks = getFeedbacks();

  const getDescription = () => {
    let responseType = 'Waiting';
    if (isReviewed) {
      responseType = isRejectedUsers(users) ? 'Rejected' : 'Success';
    }
    return t(`bankingResponse${responseType}`)
  }

  const description = getDescription();

  return (
    <div className='mulish-font'>
      <Alert
        textColor='#fff'
        backgroundColor='#515FFF'
        message={t('responseFromBankingProvider')}
      />
      <div className={classes.providerResponse}>
        <p>{description}</p>
      </div>

      {isReviewed && feedbacks}

    </div>
  );
}

BankingProviderResponse.propTypes = {
  status: PropTypes.oneOf([TREEZOR_REVIEWED, TREEZOR_SUBMITTED]).isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      kyc_review_comment: PropTypes.string,
      kyc_review: PropTypes.number.isRequired,
      user_type: PropTypes.string.isRequired,
    })
  )
}

BankingProviderResponse.defaultProps = {
  users: []
}

const mapDispatchToProps = {
  reviewSubmission: submissionsActions.reviewSubmission,
  successAlert: alertActions.success,
}

export default connect(null, mapDispatchToProps)(BankingProviderResponse);


