import React from 'react';
import {makeStyles} from '@mui/styles';
import {useTranslation} from 'react-i18next';
import Button from '@h1-card/h1-lib-ui/dist/components/atoms/Button';
import errorImg from '../../static/images/pages/errors/404.png';
import {history} from '../../state/history';
import {helpers} from '../../helpers';

const useStyles = makeStyles(() => ({
  page: {
    paddingTop: 100,
    minHeight: 'calc(100vh - 100px)',
    '& p': {
      fontSize: '0.875rem',
      marginTop: '2.75rem',
      marginBottom: '1.75rem'
    },
    '& button': {
      width: 132,
      height: 40
    }
  },
}));

const NotFoundPage = ({className, ...rest}) => {
  const classes = useStyles();
  const [trans] = useTranslation('main');

  const t = (key) => trans(`pages.notFound.${key}`);

  const onGoBack = () => history.push('/');

  const cName = helpers.getClassName(`d-flex align-items-center flex-column ${classes.page}`, className);

  return (
    <div {...rest} className={cName}>
      <img src={errorImg} alt={t('title')} />
      <p>{t('description')}</p>
      <Button size='medium' onClick={onGoBack}>{t('button')}</Button>
    </div>
  );
}

export default NotFoundPage;
