import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@mui/styles';
import StatusLabel from '@h1-card/h1-lib-ui/dist/components/molecules/Label/StatusLabel';
import TableCol from '@h1-card/h1-lib-ui/dist/components/molecules/Table/components/TableCol';
import TableRow from '@h1-card/h1-lib-ui/dist/components/molecules/Table/components/TableRow';
import QuestionsIcon from '../../../icons/QuestionsIcon';
import {helpers} from '../../../../helpers';


const useStyles = makeStyles(() => ({
  tableRow: {
    transition: '0.3s ease',
    minWidth: 700,
    '& .row-container > div': {
      pointerEvents: 'unset',
      fontSize: 14,
      paddingTop: 12,
      paddingBottom: 12,
      fontWeight: 700,
      color: '#191927',
    },
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#F0F8FF',
    },
  },
  ticketUrl: {
    color: '#191927'
  },
  alertType: {
    minWidth: 40
  }
}));

const freshdeskLink = process.env.REACT_APP_FRESHDESK_LINK;

const AlertTableRow = ({
  row,
  handleRowClick
}) => {
  const [t] = useTranslation('main');
  const classes = useStyles();

  const {id, data} = row;

  const {ticket_id: ticketId} = data;

  const onRowClick = (e) => {
    if (e.target.tagName.toLowerCase() !== 'button' && handleRowClick) handleRowClick(id);
  }

  const status = helpers.getAlertStatus(data.alert_status);

  const date = helpers.getDateWithMonth(data.created_at);

  const ticketUrl = `${freshdeskLink}/a/tickets/${ticketId}`;

  return (
    <div
      data-testid='row'
      className={classes.tableRow}
      onClick={(e) => onRowClick(e)}>
      <TableRow key={id}>
        <TableCol>
          <span className={classes.alertType}>
            <QuestionsIcon />
          </span>
          {data.description}
        </TableCol>
        <TableCol>
          {data.company_name}
        </TableCol>
        <TableCol>
          {date}
        </TableCol>
        <TableCol>
          <a
            href={ticketUrl}
            target='_blank'
            rel='noreferrer'
            className={classes.ticketUrl}
          >
            {ticketUrl}
          </a>
        </TableCol>
        <TableCol>
          <StatusLabel
            label={t(status.key)}
            backgroundColor={status.variant}
          />
        </TableCol>
      </TableRow>
    </div>
  )
}

AlertTableRow.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    data: PropTypes.shape({
      alert_status: PropTypes.string.isRequired,
      company_name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      ticket_id: PropTypes.string.isRequired
    })
  }).isRequired
}

export default AlertTableRow;
