import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@mui/styles';
import ComponentLoading from '@h1-card/h1-lib-ui/dist/components/molecules/ComponentLoading';
import {helpers} from '../../../helpers';


const useStyles = makeStyles(() => ({
  pageLoading: {
    position: 'fixed',
    width: 'calc(100% - 225px)',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    '& img': {
      width: 46
    }
  }
}));

const PageLoading = ({image, className, ...rest}) => {
  const classes = useStyles();
  const cName = helpers.getClassName(classes.pageLoading, className);

  return (
    <ComponentLoading className={cName} {...rest} />
  );
};

PageLoading.propTypes = {
  image: PropTypes.string,
};

export default PageLoading;
