import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import NotificationsIcon from '@h1-card/h1-lib-ui/dist/components/icons/NotificationsIcon';
import PageHeader from '../../components/atoms/Header/PageHeader';
import AlertsTable from '../../components/pages/AlertsListPage/AlertsTable';
import {localStorageKeysConstants} from '../../constants';


const AlertsListPage = () => {
  const [t] = useTranslation('main');

  useEffect(() => {
    localStorage.removeItem(localStorageKeysConstants.COMPANY_ID);
  }, []);

  return (
    <div className='content-container'>
      <PageHeader title={t('alerts')} icon={NotificationsIcon} />
      <AlertsTable />
    </div>
  );
}

export default AlertsListPage;
