import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@mui/styles';
import Tab from '@h1-card/h1-lib-ui/dist/components/molecules/Tabs/Tab';
import Tabs from '@h1-card/h1-lib-ui/dist/components/molecules/Tabs/Tabs';
import {helpers} from '../../../helpers';


const useStyles = makeStyles(() => ({
  container: {
    '& > div': {
      fontSize: 14,
      borderBottomColor: '#E9E9E9',
      '&:first-of-type': {
        paddingLeft: 0,
        paddingRight: 0
      }
    },
    '& div[role="tab"]': {
      padding: 0,
      '&:not(:last-of-type)': {
        marginRight: 8,
      },
      '& > div': {
        backgroundColor: '#0D175C'
      }
    }
  },
  count: {
    opacity: 0.6
  }
}));

const TabContainer = ({
  tabs,
  defaultActive,
  renderingMode,
  className,
  onTabChange,
  ...rest
}) => {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    if (defaultActive) setSelectedTab(defaultActive);
  }, [defaultActive]);

  const handleTabChange = (tabKey) => {
    const {name} = tabs[tabKey];
    onTabChange && onTabChange(name || tabKey);
    setSelectedTab(tabKey);
  }

  const cName = helpers.getClassName(classes.container, className);

  return (
    <div className={cName} {...rest}>
      <Tabs
        onTabChange={handleTabChange}
        renderingMode={renderingMode}
        selectedTab={selectedTab}
      >
        {tabs.map((tab, key) => {
          const {count} = tab;
          let {title} = tab;
          if (count) title = <>{title} <span className={classes.count}>{count}</span></>
          return (
            <Tab
              key={key}
              title={title}
              disabled={tab.disabled}
            >
              {tab.component}
            </Tab>
          );
        })}
      </Tabs>
    </div>
  );
}

TabContainer.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    component: PropTypes.object,
    disabled: PropTypes.bool,
    title: PropTypes.string.isRequired,
    count: PropTypes.number,
    name: PropTypes.string
  })).isRequired,
  defaultActive: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  renderingMode: PropTypes.oneOf(['all', 'selected']),
  onTabChange: PropTypes.func
}

Tabs.defaultProps = {
  renderingMode: 'selected'
};

export default TabContainer;
