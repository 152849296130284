import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {makeStyles} from '@mui/styles';

import TableControlled from '../../../molecules/Table/TableControlled';
import UsersTableRow from '../UsersTableRow';

import {helpers} from '../../../../helpers';
import {companiesActions} from '../../../../state/actions';


const useStyles = makeStyles(() => ({
  table: {
    '& .row-container': {
      borderTop: '2px solid #E9E9E9 !important',
      '& > div': {
        '&:nth-child(2)': {
          maxWidth: 200
        },
        '&:nth-child(3), &:nth-child(5)': {
          maxWidth: 120
        }
      }
    },
    '& > div > div > div:first-of-type': {
      '& .row-container': {
        borderTop: 'unset !important',
        '& > div:first-of-type': {
          '& span': {
            marginLeft: 40
          }
        }
      },
    }
  }
}));


const UsersTable = ({
  getCompanyUsers
}) => {
  const classes = useStyles();
  const [t] = useTranslation('main');

  const minWidth = 700;
  const [cols, setCols] = useState([]);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const cols = helpers.getUsersTableCols(t);
    setCols(cols);
  }, [t]);

  useEffect(() => {
    loadData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const loadData = () => {
    if (!loading) setLoading(true);

    getCompanyUsers(
      null,
      (users) => {
        setRows(users);
        setLoading(false);
      },
      () => setLoading(false)
    )
  }

  return (
    <div className='table-container p-0'>
      <div className={classes.table}>
        <TableControlled
          minWidth={minWidth}
          cols={cols}
          rowComponent={UsersTableRow}
          hasSelectAll={false}
          rows={helpers.createRows(rows)}
          loading={loading}
        />
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  getCompanyUsers: companiesActions.getCompanyUsers
}

export default connect(null, mapDispatchToProps)(UsersTable);
