import React, {useState} from 'react';
import {makeStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import Button from '@h1-card/h1-lib-ui/dist/components/atoms/Button';
import FileUploader from '../../../molecules/FileUploader';
import {helpers} from '../../../../helpers';


const useStyles = makeStyles(() => ({
  submissionDocument: {
    '& > *': {
      padding: '22px 0',
      '&:first-of-type': {
        paddingTop: 0
      }
    },
    '& img': {
      maxWidth: '100%',
      maxHeight: 256,
      marginBottom: 24
    },
  },
  buttons: {
    '& > button': {
      height: 40,
      borderColor: '#0D175C',
      color: '#0D175C',
      width: 128,
      padding: '0 12px',
      '&:not(:first-of-type)': {
        marginLeft: 12
      },
      '&:hover, &:active': {
        backgroundColor: '#0D175C',
        borderColor: '#0D175C',
      }
    }
  }
}));

const {getObjProp: gObjProp} = helpers;

const SubmissionDocument = ({
  document,
  handleDownload,
  handleUpdate,
  isHiddenDownload,
  isHiddenReplace,
  isHiddenPreview
}) => {

  const [t] = useTranslation(['kyc', 'main']);
  const [isDisabledDownload, setIsDisabledDownload] = useState(false);
  const classes = useStyles();
  const getDocumentProp = (key) => gObjProp(document, key);

  const url = getDocumentProp('file_url');
  const fileName = getDocumentProp('name');

  const getDocumentPreview = () => {
    const isPdf = fileName.indexOf('.pdf') !== -1;
    return isPdf
      ? <a href={url} target='_blank' rel='noreferrer'>{url}</a>
      : <img src={getDocumentProp('file_url')} alt='document' />;
  }

  const documentPreview = isHiddenPreview ? null : getDocumentPreview();

  const onDownload = () => {
    if (handleDownload) {
      setIsDisabledDownload(true);
      handleDownload(() => setIsDisabledDownload(false));
    }
  }

  return (
    <div className={classes.submissionDocument}>
      <table>
        <tbody>
          <tr>
            <td>{t('main:name')}:</td>
            <td>{fileName}</td>
          </tr>
          <tr>
            <td className='text-uppercase'>url:</td>
            <td>
              <a
                href={url}
                rel='noreferrer'
                target='_blank'
              >
                link
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      {documentPreview}

      <div className={classes.buttons}>
        {!isHiddenDownload && (
          <Button
            variant='outlined'
            onClick={onDownload}
            disabled={isDisabledDownload}
          >
            {t('main:download')}
          </Button>
        )}

        {!isHiddenReplace && (
          <FileUploader
            buttonText={t('replace')}
            handleOnChange={(file) => handleUpdate && handleUpdate(file)}
          />
        )}
      </div>
    </div>
  );
}

SubmissionDocument.propTypes = {
  document: PropTypes.shape({
    file_url: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }),
  handleDownload: PropTypes.func,
  handleUpdate: PropTypes.func,
  isHiddenPreview: PropTypes.bool,
  isHiddenDownload: PropTypes.bool,
  isHiddenReplace: PropTypes.bool
}

SubmissionDocument.defaultProps = {
  isHiddenPreview: false,
  isHiddenDownload: false,
  isHiddenReplace: true
}

export default SubmissionDocument;

