import React from 'react';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import Button from '@h1-card/h1-lib-ui/dist/components/atoms/Button';
import ChevronLeftIcon from '@h1-card/h1-lib-ui/dist/components/icons/ChevronLeftIcon';
import {history} from '../../../state/history';

const useStyles = makeStyles(() => ({
  backButtonContainer: {
    paddingTop: 10,
    '& > button': {
      textTransform: 'unset',
      color: '#191927',
      '& i > *': {
        height: 12,
        width: 12
      },
      '& svg': {
        color: '#191927'
      }
    }
  },
}));

const BackHistoryButton = ({
  name,
  url
}) => {
  const classes = useStyles();
  const [t] = useTranslation('main');

  const handleBack = () => history.push(url);

  return (
    <div className={classes.backButtonContainer}>
      <Button
        size='small'
        variant='subtle'
        icon={ChevronLeftIcon}
        onClick={handleBack}
      >
        {t('backTo', {direction: name})}
      </Button>
    </div>
  );
}

BackHistoryButton.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
}

export default BackHistoryButton;

