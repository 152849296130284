import React, {useEffect, useState} from 'react';
import { CircleFlag } from 'react-circle-flags'
import PropTypes from 'prop-types';
import ContainedSelect from '../ContainedSelect/ContainedSelect';
import {helpers} from '../../../../helpers';

const countries = helpers.getSupportedCountries();


const CountriesSelect = ({activeCountryCode, showOptionCount, valueType, ...rest}) => {
  const [options, setOptions] = useState([]);

  const updateOptions = (activeCountryCode) => {
    if (activeCountryCode) activeCountryCode = activeCountryCode.toLowerCase();
    const countriesList = countries.map(c => {
      const {name, country_code: countryPhoneCode} = c;
      let countryCode = '';

      try {
        countryCode = c['ISO3166-1-Alpha-2'].toLowerCase();
      } catch (e) {
        const alphaThreeCode = c['ISO3166-1-Alpha-3'];
        if (alphaThreeCode) {
          countryCode = alphaThreeCode.toLowerCase();
          console.log("User Alpha-3 code for", name);
        }
        console.log("Can't get country code for", name);
      }

      return {
        isActive: activeCountryCode === countryCode,
        label: c.name,
        value: valueType === 'phone-code' ? countryPhoneCode : countryCode,
        image: <CircleFlag countryCode={countryCode} height='26' />,
        count: showOptionCount ? `+${countryPhoneCode}` : null,
        countClassName: 'flag-count'
      }
    });
    setOptions(countriesList);
  }

  useEffect(() => updateOptions(), []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => updateOptions(activeCountryCode), [activeCountryCode]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearchItemsFilter = (list, value) => {
    value = value.replace(/\+/g, '');
    return list.filter(item => {
      const labelLower = item.label.toLowerCase();
      const valueLower = String(item.value);
      return labelLower.includes(value) || valueLower.includes(value);
    });
  }

  return (
    <ContainedSelect
      options={options}
      showSearch={true}
      handleSearchItemsFilter={handleSearchItemsFilter}
      {...rest}
    />
  );
}

CountriesSelect.propTypes = {
  activeCountryCode: PropTypes.string,
  showOptionCount: PropTypes.bool,
  valueType: PropTypes.oneOf(['country-code', 'phone-code'])
};

CountriesSelect.defaultProps = {
  showOptionCount: true,
  valueType: 'phone-code'
}

export default CountriesSelect;
