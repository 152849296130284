import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';

import ListGroup from '@h1-card/h1-lib-ui/dist/components/atoms/ListGroup';
import Table from '@h1-card/h1-lib-ui/dist/components/molecules/Table/Table';
import {makeStyles} from '@mui/styles';
import {useTranslation} from 'react-i18next';
import ComponentLoading from '@h1-card/h1-lib-ui/dist/components/molecules/ComponentLoading';
import TableHead from '../TableHead';
import TableCol from '../TableCol';
import {helpers} from '../../../../helpers';

const useStyles = makeStyles(() => ({
  tableContainer: {
    position: 'relative',
    '& .row-container': {
      padding: 0,
      '& > div': {
        paddingTop: 0,
        paddingBottom: 0
      }
    },
    '& > div': {
      margin: 0,
      overflow: 'auto',
    },
    '& .empty-message': {
      fontSize: 14,
      textAlign: 'center',
      padding: 20
    }
  },
  tableHeadCol: {
    fontSize: 12,
  }
}));

const TableControlled = ({
  cols,
  rows,
  rowComponent,
  rowHeader,
  minWidth,
  hasSelectAll,
  lastAlignRight,
  onOrderChange,
  emptyMessage,
  disableSelectAll,
  showSelectCounter,
  bulkActions,
  onSelectAllRows,
  onRowClick,
  additionalRowProps,
  onSelect,
  loading,
  className
}) => {
  const classes = useStyles();
  const [t] = useTranslation('main');
  const [tableHead, setTableHead] = useState({cols});
  const [tableRows, setTableRows] = useState(rows);

  const ref = useRef(null);

  useEffect(() => {
    const allRows = [...tableRows];
    setTableHead({cols});

    rows.forEach(item => item.selected = false);
    setTableRows(allRows);
  }, [cols]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTableRows(rows);
  }, [rows]);

  const handleOnOrderChange = colIndex => {
    let orderKey = null;
    let orderValue = 'desc';
    let cols = tableHead.cols.map((col, index) => {
      const hasOrderProperty = col.hasOwnProperty('order');
      if (colIndex === index) {
        let {value, active, name} = col.order;
        if (hasOrderProperty) {
          if (active && value === 'desc') {
            col.order.value = orderValue = 'asc';
            col.order.active = true;
            orderKey = name;
          } else if (active && value === 'asc') {
            col.order.active = false;
            col.order.value = 'desc';
            orderValue = orderKey = null;
          } else {
            col.order.active = true;
            orderKey = name;
          }
        }

        return col;
      }

      if (hasOrderProperty) {
        col.order.active = false;
        col.order.value = 'desc';
      }

      return col;
    });

    if (onOrderChange) {
      onOrderChange(orderKey, orderValue);
    }

    setTableHead({...tableHead, cols});
  }

  const handleRowSelect = index => {
    const selectRows = [...tableRows];
    selectRows[index].selected = !selectRows[index].selected;
    setTableRows(selectRows);
    onSelect && onSelect(selectRows);
  }

  const getSelectedCount = () => rows.filter(item => item.selected).length;

  const tableCheckedStatus = () => {
    const rowsCount = getSelectedCount();

    if (rowsCount === 0) {
      return false;
    }

    if (rowsCount === rows.length) {
      return true;
    }

    return 'semi-checked';
  }

  const handleAllRowSelect = () => {
    const allRows = [...tableRows];
    const allSelected = tableCheckedStatus();

    rows.forEach(item => item.selected = !allSelected);
    setTableRows(allRows);
    onSelectAllRows && onSelectAllRows(rows);
    onSelect && onSelect(rows);
  }

  const getHeadCols = () => {
    return tableHead.cols.map((col, index) => {
      const isLastRight = lastAlignRight && tableHead.cols.length - 1 === index;
      const {className, halign} = col;
      let cName = `${isLastRight ? 'table-head-pr-20' : ''} ${classes.tableHeadCol}`;
      const props = {
        onClick: () => handleOnOrderChange(index),
        key: `col-head-${index}`,
        order: col.order,
        halign: halign || isLastRight ? 'right' : 'left',
        auto: col.auto || false
      };
      if (col.flexBasisWidth) props.flexBasisWidth = col.flexBasisWidth;
      if (className) cName += ` ${className}`;

      return (
        <TableCol {...props}>
          <span className={cName}>{col.name}</span>
        </TableCol>
      )
    });
  }

  const getBulkActions = () => {
    return (
      <ListGroup
        size='small'
        direction='row'
        items={bulkActions}
      />
    );
  }

  const headerCols = getHeadCols();

  return (
    <div className={helpers.getClassName(`${classes.tableContainer} table`, className)} ref={ref}>
      {loading && <ComponentLoading className='flex-center-center' />}
      <Table>
        <div style={{minWidth: minWidth || 'unset'}}>
          <TableHead
            disableSelectAll={disableSelectAll}
            hasSelectAll={hasSelectAll}
            selectedCounter={showSelectCounter ? getSelectedCount() : showSelectCounter}
            onAllCheckboxToggle={() => handleAllRowSelect()}
            allSelected={tableCheckedStatus()}
            cols={headerCols}
            bulkActions={getBulkActions()}
            className='show-bulk'
          />
        </div>
        {rowHeader || ''}
        {tableRows.length === 0 && (
          <div className='empty-message flex-center-center'>{!loading && t(emptyMessage)}</div>
        )}
        {tableRows.map((row, rowIndex) => React.createElement(rowComponent, {
          ...additionalRowProps,
          handleRowSelect,
          handleRowClick: onRowClick,
          row,
          rowIndex,
          key: rowIndex,
          checkboxDisabled: disableSelectAll
        }))}
      </Table>
    </div>

  )
}

TableControlled.propTypes = {
  cols: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  rowComponent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object
  ]).isRequired,
  rowHeader: PropTypes.object,
  lastAlignRight: PropTypes.bool,
  minWidth: PropTypes.number,
  onOrderChange: PropTypes.func,
  emptyMessage: PropTypes.string,
  hasSelectAll: PropTypes.bool,
  disableSelectAll: PropTypes.bool,
  showSelectCounter: PropTypes.bool,
  bulkActions: PropTypes.array,
  onSelectAllRows: PropTypes.func,
  onRowClick: PropTypes.func,
  additionalRowProps: PropTypes.object,
  onSelect: PropTypes.func,
  loading: PropTypes.bool
}

TableControlled.defaultProps = {
  lastAlignRight: false,
  hasSelectAll: true,
  disableSelectAll: false,
  emptyMessage: 'tableEmptyMessage',
  showSelectCounter: false,
  bulkActions: [],
  loading: false
}

export default TableControlled;
