import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@mui/styles';
import Col from '@h1-card/h1-lib-ui/dist/components/atoms/Col';
import Row from '@h1-card/h1-lib-ui/dist/components/atoms/Row';
import Text from '@h1-card/h1-lib-ui/dist/components/atoms/Text';
import IconButton from '@h1-card/h1-lib-ui/dist/components/molecules/IconButton';
import StyledFeedback from '@h1-card/h1-lib-ui/dist/components/molecules/Feedback/StyledFeedback';
import {AlertIcon, DoneIcon, RemoveIcon} from '@h1-card/h1-lib-ui/dist/components/icons';

const useStyles = makeStyles(() => ({
  feedback: {
    '& > div': {
      width: '100%',
      '& > div:nth-of-type(3)': {
        paddingRight: 0
      }
    },
    '& .icon > i': {
      marginTop: 2
    },
    '& .text-content': {
      width: props => `calc(100% - ${props.textContentPadding}px)`
    }
  },
}));

const Feedback = ({ status, message, closable, onFeedbackClose, ...rest }) => {
  const classes = useStyles({
    textContentPadding: closable ? 80 : 48,
  });
  const [isVisible, SetIsVisible] = useState(true);

  useEffect(() => {
    SetIsVisible(true);
  }, [status, message, SetIsVisible]);

  return (
    <StyledFeedback
      status={status}
      message={message}
      isVisible={isVisible}
      {...rest}
    >
      <div className={`${classes.feedback} d-flex align-center`}>
        <Row valign='top'>
          <Col span='auto' className='icon'>
            {status === 'error'
              ? <AlertIcon variant={status} />
              : <DoneIcon variant={status} />
            }
          </Col>
          <Col span='auto' className='text-content'>
            <Text tag={'div'} size='xbody' weight='semi-bold'>
              {message}
            </Text>
          </Col>
          {closable && (
            <Col span='auto'>
              <IconButton
                icon={RemoveIcon}
                size='small'
                variant={status}
                className='feedback-close'
                onClick={(e) => {
                  e.stopPropagation();
                  onFeedbackClose && onFeedbackClose(e);
                  SetIsVisible(false);
                }}
              />
            </Col>
          )}
        </Row>
      </div>
    </StyledFeedback>
  );
};

Feedback.displayName = 'Feedback';

Feedback.propTypes = {
  /** The message to show */
  message: PropTypes.string,
  /** The status of the Feedback */
  status: PropTypes.oneOf(['error', 'success', 'warning']),
  /** Shows Feedback close button if true */
  closable: PropTypes.bool,
  /** On close callback function */
  onFeedbackClose: PropTypes.func,
};

export default Feedback;
