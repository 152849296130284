import React from 'react';
import {alertConstants} from '../constants';
import toast from 'react-hot-toast';
import Snackbar from '../../components/atoms/Snackbar';

const displayAlert = (message, variant) => {
  toast.custom(
    (t) => <Snackbar message={message} variant={variant} onClose={() => toast.dismiss(t.id)} />,
    {duration: 5000}
  );
}

const success = message => displayAlert(message, 'success');

const warning = message => displayAlert(message, 'warning');

const error = message => displayAlert(message, 'error');

const clear = () => {
  return {
    type: alertConstants.CLEAR
  }
}

export const alertActions = {
  success,
  warning,
  error,
  clear
};
