import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import Button from '@h1-card/h1-lib-ui/dist/components/atoms/Button';
import BackHistoryButton from '../../components/atoms/BackHistoryButton';
import PageLoading from '../../components/atoms/PageLoading';
import TabContainer from '../../components/molecules/TabContainer';
import AlertsTable from '../../components/pages/CompanyDetailPage/AlertsTable';
import UsersTable from '../../components/pages/CompanyDetailPage/UsersTable';
import CardsTable from '../../components/pages/CompanyDetailPage/CardsTable';
import TransactionsTable from '../../components/pages/CompanyDetailPage/TransactionsTable';
import TerminateCompanyModal from '../../components/pages/CompaniesListPage/TerminateCompanyModal';
import HeaderDetails from '../../components/pages/KYCRequestDetailPage/HeaderDetails';
import NotFoundPage from '../NotFoundPage';
import {localStorageKeysConstants, H1CompanyStatusesConstants} from '../../constants';
import {alertActions, companiesActions} from '../../state/actions';
import {helpers} from '../../helpers'

const {BLOCKED, ACTIVE, INACTIVE, RESET_KYC} = H1CompanyStatusesConstants;
const {getStatusLabel} = helpers;

const CompanyDetailPage = ({
  match,
  getCompanyDetails,
  getTransactionsList,
  getCompanyWallet,
  changeCompanyStatus,
  deleteCompany,
  successAlert
}) => {
  const [t] = useTranslation(['main', 'kyc']);
  const [loading, setLoading] = useState(true);
  const [companyDetails, setCompanyDetails] = useState({
    balance: 0,
    name: '',
    walletId: '',
    id: null,
    status: null,
    isLoaded: false
  });
  const [transactions, setTransaction] = useState([]);
  const [terminateModalProps, setTerminateModalProps] = useState({
    action: 'terminate',
    loading: false,
    open: false
  });

  useEffect(() => {
    const {id} = match.params;
    if (id) {
      localStorage.setItem(localStorageKeysConstants.COMPANY_ID, id);
      getCompanyDetails(
        (data) => {
          const {company_balance: balance, id, name: companyName, company_status: status} = data;
          const details = {
            ...companyDetails,
            balance,
            id,
            status,
            name: companyName,
            isLoaded: true
          }
          setCompanyDetails(details);
          getCompanyWallet((data) => {
            setCompanyDetails({
              ...details,
              walletId: data?.bank_id || ''
            });
          });
          setLoading(false);
        },
        () => {
          setLoading(false);
          setCompanyDetails({...companyDetails, isLoaded: true});
        }
      );
      if (transactions.length === 0) {
        setLoading(true);
        getTransactionsList(
          (transactions) => {
            setTransaction(transactions);
            setLoading(false)
          },
          () => setLoading(false)
        )
      }
    }
  }, [match.params.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const tabs = [
    {
      title: t('users'),
      component: <UsersTable />
    }, {
      title: t('cards'),
      component: <CardsTable />
    }, {
      title: t('main:transactions'),
      component: <TransactionsTable transactions={transactions} />,
    }, {
      title: t('alerts'),
      component: <AlertsTable />
    }
  ];

  const handleCloseTerminateModal = () => setTerminateModalProps({...terminateModalProps, open: false});

  const handleOpenTerminateModal = (action = 'terminate') =>
    setTerminateModalProps({...terminateModalProps, open: true, action});

  const handleOkTerminateModal = (status) => {
    setTerminateModalProps({...terminateModalProps, loading: true});

    const successFunc = (data) => {
      const {company_balance: balance, company_status: status} = data;
      setCompanyDetails({
        ...companyDetails,
        balance,
        status
      });
      setTerminateModalProps({...terminateModalProps, loading: false, open: false});
    }
    const errorFunc = () => setTerminateModalProps({...terminateModalProps, loading: false});

    if (status === 'deactivate') {
      handleChangeCompanyStatus(
        status,
        successFunc,
        errorFunc
      );
    } else {
      deleteCompany(
        successFunc,
        errorFunc
      );
    }
  }

  const updateCompanyDetails = (data) => {
    const {company_balance: balance, id, name, company_status: status} = data;
    const details = {
      ...companyDetails,
      balance,
      id,
      status,
      name
    };
    setCompanyDetails(details);
  }

  const handleChangeCompanyStatus = (status, success, error) => {
    changeCompanyStatus(
      status,
      (data) => {
        updateCompanyDetails(data);
        success && success(data);
      },
      (data) => error && error(data)
    )
  }

  const handleActivate = () => {
    setLoading(true);
    handleChangeCompanyStatus(
      'activate',
      () => setLoading(false),
      () => setLoading(false)
    );
  }

  const getHeaderButtons = () => {
    let buttons = [];
    const {status} = companyDetails;

    if (status === INACTIVE) {
      buttons.push(
        <Button
          key='activate'
          onClick={() => handleActivate()}>
          {t('activate')}
        </Button>
      );
    }

    if (status === ACTIVE) {
      buttons.push(
        <Button
          key='deactivate'
          variant='primary'
          onClick={() => handleOpenTerminateModal('deactivate')}
        >
          {t('deactivate')}
        </Button>
      );
    }

    if (status !== BLOCKED) {
      buttons.push(
        <Button
          key='terminate'
          variant='danger'
          onClick={() => handleOpenTerminateModal('terminate')}
        >
          {t('terminate')}
        </Button>
      );
    }

    return buttons;
  }

  const getAdditionalLabel = () => {
    const {status} = companyDetails;
    if ([BLOCKED, RESET_KYC].includes(status)) {
      const statuses = {
        [BLOCKED]: {name: 'blocked', className: 'blocked'},
        [RESET_KYC]: {name: 'requested kyc', className: 'warning'}
      }
      const {name, className} = statuses[status];
      return getStatusLabel(className, name);
    } else {
      return null
    }
  }

  const headerDetails = helpers.getCompanyHeaderDetails(companyDetails, helpers.getCompanyStatus);

  return (
    <div className='content-container px-22'>
      <BackHistoryButton
        url='/companies'
        name={t('companies')}
      />
      {companyDetails.isLoaded && (
        <>
          {
            companyDetails.id ?
              (
                <>
                  <HeaderDetails
                    details={headerDetails}
                    buttons={getHeaderButtons()}
                    additionalLabel={getAdditionalLabel()}
                  />
                  <TabContainer
                    tabs={tabs}
                    className='pt-20'
                  />
                </>
              ) :
              <NotFoundPage className='not-found-page' />
          }
        </>
      )}

      <TerminateCompanyModal
        handleClose={handleCloseTerminateModal}
        handleOk={handleOkTerminateModal}
        details={{id: companyDetails.id, name: companyDetails.name}}
        {...terminateModalProps}
      />

      {loading && <PageLoading />}
    </div>
  );
}

const mapDispatchToProps = {
  getCompanyDetails: companiesActions.getCompanyDetails,
  getCompanyWallet: companiesActions.getCompanyWallet,
  getTransactionsList: companiesActions.getTransactionsList,
  changeCompanyStatus: companiesActions.changeCompanyStatus,
  deleteCompany: companiesActions.deleteCompany,
  successAlert: alertActions.success
}

export default connect(null, mapDispatchToProps)(CompanyDetailPage);

