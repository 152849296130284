import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {makeStyles} from '@mui/styles';
import AlertTableRow from '../../AlertsListPage/TableRow';
import TableControlled from '../../../molecules/Table/TableControlled';
import {helpers} from '../../../../helpers';
import {companiesActions} from '../../../../state/actions';


const useStyles = makeStyles(() => ({
  table: {
    '& .row-container': {
      borderTop: '2px solid #E9E9E9 !important',
      '& > div': {
        '&:nth-child(2)': {
          maxWidth: 200
        },
        '&:nth-child(3), &:nth-child(5)': {
          maxWidth: 120
        }
      }
    },
    '& > div > div > div:first-of-type': {
      '& .row-container': {
        borderTop: 'unset !important',
        '& > div:first-of-type': {
          '& span': {
            marginLeft: 40
          }
        }
      },
    }
  }
}));

const minWidth = 700;

const AlertsTable = ({
  getAlertsList
}) => {
  const classes = useStyles();
  const [t] = useTranslation('main');
  const [cols, setCols] = useState([]);
  const [rows, setRows] = useState([]);
  const [query, setQuery] = useState({
    sort_by: 'created_at',
    sort_order: 'asc'
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const cols = helpers.getAlertsTableCols(t);
    setCols(cols);
  }, [t]);

  useEffect(() => {
    loadData(query);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const loadData = (query) => {
    if (!loading) setLoading(true);

    getAlertsList(
      query,
      (alerts) => {
        setRows(alerts);
        setLoading(false);
      },
      () => setLoading(false)
    )
  }

  const onOrderChange = (key, order) => {
    const orderQuery = {
      ...query,
      sort_by: key,
      sort_order: order,
    }
    setQuery(orderQuery);
    loadData(orderQuery);
  }

  return (
    <div className='table-container p-0'>
      <div className={classes.table}>
        <TableControlled
          minWidth={minWidth}
          cols={cols}
          rowComponent={AlertTableRow}
          onOrderChange={onOrderChange}
          hasSelectAll={false}
          rows={helpers.createRows(rows)}
        />
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  getCompanyDetails: companiesActions.getCompanyDetails,
  getCompanyWallet: companiesActions.getCompanyWallet,
  getAlertsList: companiesActions.getAlertsList
}

export default connect(null, mapDispatchToProps)(AlertsTable);

