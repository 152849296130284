import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {makeStyles} from '@mui/styles';
import Search from '@h1-card/h1-lib-ui/dist/components/molecules/Search';
import Pagination from '@h1-card/h1-lib-ui/dist/components/molecules/PaginationSimple';
import RequestTableRow from '../TableRow';
import TableControlled from '../../../molecules/Table/TableControlled';
import {history} from '../../../../state/history';
import {submissionsActions} from '../../../../state/actions';
import {helpers} from '../../../../helpers';
import {H1CompanyStatusesConstants} from '../../../../constants';

const minWidth = 800;

const paginationLimit = 20;

const useStyles = makeStyles(() => ({
  table: {
    '& .row-container': {
      '& > div': {
        '&:nth-of-type(4)': {
          maxWidth: 250
        },
        '&:nth-of-type(5)': {
          maxWidth: 100
        }
      }
    }
  }
}));

const RequestsTable = ({getRequestsList}) => {
  const [t] = useTranslation('main');
  const classes = useStyles();
  const [cols, setCols] = useState([]);
  const [data, setData] = useState({
    rows: [],
    activeRows: [],
    pagination: null,
    page: 1
  });
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState({
    sort_by: 'name',
    sort_order: 'asc',
    limit: paginationLimit,
    company_status: H1CompanyStatusesConstants.MISSING_KYC
  });

  const finishLoading = () => setLoading(false);

  const loadData = (additionalQuery = null, isInitial = false) => {
    if (!loading) setLoading(true);
    const latestQuery = {...query, ...additionalQuery};
    setQuery(latestQuery);

    getRequestsList(
      latestQuery,
      (response) => {
        const {results, pagination} = response;
        let {page} = data;
        if (!isInitial) page++;
        setData({
          ...response,
          pagination,
          rows: [...data.rows, ...results],
          activeRows: results,
          page
        });
        finishLoading();
      },
      finishLoading
    )
  }

  useEffect(() => {
    loadData(null, true);
  }, [getRequestsList]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const companyTrans = t('company');
    setCols([
      {
        name: t('company'),
        order: {
          active: true,
          value: 'asc',
          name: 'name',
        },
      },
      {
        name: `${companyTrans} ID`
      },
      {
        name: t('status'),
        order: {
          active: false,
          value: 'desc',
          name: 'company_kyc_status',
        },
      },
      {name: t('lastUpdate')},
      {name: t('balance')}
    ]);
  }, [t]);

  const onRowClick = (id) => history.push(`/kyc-requests/${id}`);

  const onOrderChange = (key, order) => {
    const orderQuery = {
      ...query,
      sort_by: key,
      sort_order: order,
      cursor: undefined
    };
    setLoading(true);
    setQuery(orderQuery);

    getRequestsList(
      orderQuery,
      (response) => {
        const {results, pagination} = response;
        setData({
          ...response,
          pagination,
          rows: results,
          activeRows: results,
          page: 1
        });
        finishLoading();
      },
      finishLoading
    )
  }

  const onPaginate = (direction) => helpers.handleOnPaginate(direction, data, setData, loadData, paginationLimit);

  const {enableNextPage, enablePrevPage, rows} = helpers.getTableData(data);

  return (
    <div className='table-container'>
      <div className='d-flex justify-end'>
        <div className='search-container'>
          <Search
            delay={500}
            placeholder={t('search')}
            size='small'
            disabled={true}
          />
        </div>
      </div>

      <TableControlled
        minWidth={minWidth}
        cols={cols}
        rowComponent={RequestTableRow}
        hasSelectAll={false}
        onRowClick={onRowClick}
        onOrderChange={onOrderChange}
        rows={rows}
        loading={loading}
        className={classes.table}
      />
      <Pagination
        onPageChange={onPaginate}
        enableNextPage={enableNextPage}
        enablePrevPage={enablePrevPage}
        currentPage={data.page}
      />
    </div>
  );
}

const mapDispatchToProps = {
  getRequestsList: submissionsActions.getRequestsList
}

export default connect(null, mapDispatchToProps)(RequestsTable);
