import React, {useEffect, useState} from 'react';
import {makeStyles} from '@mui/styles';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import mime from 'mime-types';
import ComponentLoading from '@h1-card/h1-lib-ui/dist/components/molecules/ComponentLoading';
import SubmissionDetails from '../SubmissionDetails';
import SubmissionDocument from '../SubmissionDocument';
import {submissionsActions} from '../../../../state/actions';
import {helpers} from '../../../../helpers';
import {H1ReviewStatusesConstants} from '../../../../constants';

const {ACCEPTED, REJECTED} = H1ReviewStatusesConstants;

const useStyles = makeStyles(() => ({
  submissionInformation: {
    '& > div': {
      '&:first-of-type': {
        width: '60%'
      },
      '&:last-of-type': {
        paddingLeft: 24,
        width: 'calc(40% - 24px)',
      }
    },
    '& table': {
      borderSpacing: 0,
      lineHeight: '21px'
    }
  },
  submissionPreview: {
    position: 'relative',
    '&:not(:last-of-type)': {
      marginBottom: 30
    },
  }
}));

const SubmissionPreview = ({
  additionalTitleContent,
  documents,
  submission,
  userType,
  reviewSubmission,
  downloadDocument,
  updateDocument,
  handleUpdateSubmissionDetails,
  isHiddenDownload,
  isEditable,
  ...rest
}) => {
  const [t] = useTranslation(['kyc', 'main']);
  const classes = useStyles();
  const [currentSubmission, setCurrentSubmission] = useState(null);
  const [currentDocuments, setCurrentDocuments] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => setCurrentSubmission(submission), [submission]);

  useEffect(() => setCurrentDocuments(documents), [documents]);

  const getSubmissionProp = (key) => helpers.getObjProp(submission, key);

  const handleReviewSubmission = (reviewType, comment, successFunc, errorFunc) => {
    const {id} = currentSubmission;
    setLoading(true);
    reviewSubmission(
      id,
      reviewType,
      comment,
      (data) => {
        handleUpdateSubmissionDetails(
          {data, reviewType},
          (data) => {
            successFunc && successFunc(data);
            setLoading(false);
          },
          (data) => {
            errorFunc && errorFunc(data);
            setLoading(false);
          }
        )
      },
      (data) => {
        errorFunc && errorFunc(data);
        setLoading(false);
      }
    );
  }

  const handleDownloadDocument = (document, callbackFunc) => {
    const {id: documentId, name: fileName, user_id: userId} = document;
    const fileType = mime.lookup(document.name);
    const callBackFunc = (data) => callbackFunc && callbackFunc(data);
    downloadDocument(
      userId,
      documentId,
      (data) => {
        helpers.saveFile(data, fileName, fileType);
        callBackFunc(data);
      },
      callBackFunc
    )
  }

  const handleUpdateDocument = async (currentDocument, uploadedDocument) => {
    const {id: documentId, document_type_id: documentTypeId, user_id: userId} = currentDocument;
    const data = await helpers.getUploadedFilePayload(uploadedDocument, documentTypeId, userId);

    updateDocument(
      userId,
      documentId,
      data,
      (document) => setCurrentDocuments(documents.map(d => d.id === document.id ? document : d))
    )
  }

  const getRows = () => {
    let rows = [
      {title: t('address'), key: 'address1'},
      {title: t('city'), key: 'city'},
      {title: t('country'), key: 'country'},
      {title: t('postCode'), key: 'postcode'},
      {title: t('phoneNumber'), key: 'phone'},
    ];

    if (userType === 'legal_person') {
      rows = [
        {title: t('email'), key: 'email'},
        {title: t('name'), key: 'legal_name'},
        ...rows,
        {title: t('legalRegNumber'), key: 'legal_registration_number'},
        {title: t('legalShareCapital'), key: 'legal_share_capital'},
        {title: t('businessSector'), key: 'legal_sector'},
        {title: t('companyType'), key: 'legal_form'},
        {title: t('legalAnnual'), key: 'legal_annual_turn_over'},
        {title: t('legalNet'), key: 'legal_net_income_range'},
        {title: t('employeeRange'), key: 'legal_number_of_employee_range'},
      ];
    } else {
      rows = [
        {title: t('title'), key: 'title'},
        {title: t('firstName'), key: 'firstname'},
        {title: t('lastName'), key: 'lastname'},
        {title: t('birthday'), key: 'birthday'},
        ...rows,
        {title: t('nationality'), key: 'nationality'},
        {title: t('revenue'), key: 'income_range'},
        {title: t('personalAssets'), key: 'personal_assets'}
      ];
    }
    return rows;
  }

  const rows = getRows();

  const title = (
    <div className='d-flex align-items-center justify-space-between'>
      {t(userType === 'legal_person' ? 'currentSubmission' : 'personalDetails')}
      {additionalTitleContent}
    </div>
  );

  const submittedDate = helpers.getDateWithMonth(getSubmissionProp('submitted_date'));
  const status = getSubmissionProp('h1_review_status').toUpperCase();
  const isPending = getSubmissionProp('isPending', false);
  const isRejected = status === REJECTED && !isPending;
  const isAccepted = status === ACCEPTED && !isPending;

  const lastUpdateDate = helpers.getDateWithMonth(getSubmissionProp('last_update_date'));
  const reviewer = getSubmissionProp('reviewer');

  return (
    <div className={classes.submissionPreview} {...rest}>
      <div className={`d-flex ${classes.submissionInformation}`}>
        {currentSubmission && (
          <>
            <SubmissionDetails
              submission={currentSubmission}
              title={title}
              rows={rows}
              handleReview={handleReviewSubmission}
              isEditable={isEditable}
            />
            <div>
              <div className='f-20 fw-600 mulish-font mb-10'>
                {t('submittedOn')}: {submittedDate}
              </div>

              <table className='mb-20'>
                <tbody>
                  <tr className='mulish-font'>
                    <td>{t('userId')}:</td>
                    <td>{getSubmissionProp('id')}</td>
                  </tr>
                  {isRejected && (
                    <>
                      <tr>
                        <td>{t('rejectedBy')}:</td>
                        <td>{reviewer}</td>
                      </tr>
                      <tr>
                        <td>{t('rejectedDate')}:</td>
                        <td>{lastUpdateDate}</td>
                      </tr>
                      <tr>
                        <td>{t('comment')}:</td>
                        <td>{getSubmissionProp('comment')}</td>
                      </tr>
                    </>
                  )}

                  {isAccepted && (
                    <>
                      <tr>
                        <td>{t('approvedBy')}:</td>
                        <td>{reviewer}</td>
                      </tr>
                      <tr>
                        <td>{t('approvedDate')}:</td>
                        <td>{lastUpdateDate}</td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>

              <div className='f-20 fw-600 mulish-font mb-10'>Documents:</div>
              {currentDocuments.map((document, index) => (
                <div key={index}>
                  <div className='f-20 fw-600 mulish-font'>
                    #{++index} {helpers.getDocumentTypeLabel(document.document_type_id)}
                  </div>
                  <SubmissionDocument
                    key={document.id}
                    document={document}
                    handleDownload={(callbackFunc) => handleDownloadDocument(document, callbackFunc)}
                    handleUpdate={(uploadedDocument) => handleUpdateDocument(document, uploadedDocument)}
                    isHiddenDownload={isHiddenDownload}
                    isHiddenReplace={!isPending}
                    isHiddenPreview={true}
                  />
                </div>
              ))}

            </div>
          </>
        )}
      </div>

      {loading && <ComponentLoading className='flex-center-center loader' />}

    </div>
  );
}

SubmissionPreview.propTypes = {
  additionalTitleContent: PropTypes.string,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      file_url: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ),
  submission: PropTypes.shape({
    comment: PropTypes.string,
    h1_review_status: PropTypes.string.isRequired,
    last_update_date: PropTypes.string.isRequired,
    submitted_date: PropTypes.string.isRequired,
    reviewer: PropTypes.string
  }).isRequired,
  userType: PropTypes.oneOf(['legal_person', 'legal_representative']).isRequired,
  handleUpdateSubmissionDetails: PropTypes.func,
  isHiddenDownload: PropTypes.bool,
  isEditable: PropTypes.bool
}

SubmissionPreview.defaultProps = {
  documents: [],
  isEditable: true,
  isHiddenDownload: false
}

const mapDispatchToProps = {
  reviewSubmission: submissionsActions.reviewSubmission,
  downloadDocument: submissionsActions.downloadDocument,
  updateDocument: submissionsActions.updateDocument
}

export default connect(null, mapDispatchToProps)(SubmissionPreview);
