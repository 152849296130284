import React from 'react';
import {makeStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import FormItem from '@h1-card/h1-lib-ui/dist/components/molecules/FormItem';
import {helpers} from '../../../helpers';


const useStyles = makeStyles(() => ({
  formItem: {
    '& label': {
      letterSpacing: '0.5px'
    },
    '& div:not(.checkbox) > label': {
      display: 'block',
      marginBottom: 2
    },
    '& div.checkbox > label': {
      fontSize: 12
    },
    '& i': {
      '&:hover': {
        cursor: 'pointer'
      }
    },
    '& input, & div.error': {
      borderWidth: '1px !important'
    }
  },
}));

const FormItemStyled = ({
  children,
  isRequired,
  className,
  label,
  additional,
  ...rest
}) => {
  const classes = useStyles();
  const cName = helpers.getClassName(classes.formItem, className);

  let formLabel = `${label}${isRequired ? '*' : ''}`

  if (additional) formLabel = <div className='justify-space-between flex-center'>{formLabel} {additional}</div>;

  return (
    <FormItem {...rest} label={formLabel} className={cName}>
      {children}
    </FormItem>
  );
}

FormItemStyled.propTypes = {
  className: PropTypes.string,
  isRequired: PropTypes.bool,
  label: PropTypes.string.isRequired,
}

export default FormItemStyled;
