import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@mui/styles';
import parse from 'html-react-parser';

const useStyles = makeStyles(theme => ({
  container: {
    padding: '80px 22px 0 22px',
    maxWidth: 1400,
    margin: '0 calc((100vw - 1400px) / 2) 0 calc((100vw - 1000px) / 2)',
    minHeight: 'calc(100vh - 168px)',
    backgroundImage: (props) => `url(${props.bgImage})`,
    backgroundPosition: 'right 80px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '90%',

    [theme.breakpoints.only('md')]: {
      maxWidth: 'unset',
      backgroundPosition: '80% 80px',
      padding: '70px 22px 0 22px',
      backgroundSize: '85%',
    },
    [theme.breakpoints.down('sm')]: {
      backgroundSize: '75%',
      backgroundPosition: '30% 60px',
      paddingTop: 50
    },
    '@media (max-width: 1044px)': {
      marginLeft: 0,
    },
  },
  textContent: {
    maxWidth: 480,
    width: '45%',
    [theme.breakpoints.down('md')]: {
      maxWidth: 390,
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 350
    },
  },
  title: {
    fontWeight: 600,
    marginTop: 0,
    lineHeight: '5.5rem',
    fontSize: '4rem',
    [theme.breakpoints.only('md')]: {
      lineHeight: '4.5rem',
      fontSize: '3.25rem'
    },
    [theme.breakpoints.down('sm')]: {
      lineHeight: '3.75rem',
      fontSize: '2.75rem',
    },
  },
  description: {
    fontWeight: 500,
    color: '#999',
    lineHeight: '1.75rem',
    fontSize: '1.5rem',
    marginTop: 0,
    marginBottom: '3rem',
    [theme.breakpoints.only('md')]: {
      lineHeight: '1.35rem',
      fontSize: '1.15rem',
      marginBottom: '2rem'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.125rem',
      marginBottom: '1.5rem',
      maxWidth: 300
    },
  }
}));

const PageContainer = ({title, description, content, bgImage, ...rest}) => {
  const classes = useStyles({bgImage});


  return (
    <div className={`d-flex justify-space-between ${classes.container}`} {...rest}>
      <div className={`d-flex flex-column ${classes.textContent}`}>
        {title && <h1 className={classes.title}>{title}</h1>}
        {description && <p className={classes.description}>{parse(description)}</p>}
        {content || ''}
      </div>
    </div>
  );
}

PageContainer.propTypes = {
  bgImage: PropTypes.string.isRequired,
  content: PropTypes.object,
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default PageContainer;
