import React from 'react';
import {makeStyles} from '@mui/styles';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import StatusBadge from '../../../atoms/StatusBadge';

const useStyles = makeStyles(() => ({
  header: {
    padding: '10px 0'
  },
  headerDescription: {
    '& > span:not(:last-of-type)': {
      marginRight: 12,
    },
    '& > span:last-of-type': {
      marginLeft: 12,
    },
    '& > div': {
      fontSize: 12,
      '&:first-of-type': {
        marginRight: 78
      }
    }
  },
  status: {
    marginLeft: 12,
    fontSize: 12,
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    fontWeight: 500,
    '& > div': {
      marginRight: 14
    }
  },
  actionButtons: {
    minHeight: 40,
    '& > *': {
      '&:not(:nth-child(1))': {
        marginLeft: 16
      }
    },
  },
  name: {
    minWidth: 240
  },
  title: {
    fontSize: 28,
    lineHeight: '38px',
    fontWeight: 600
  },
  balance: {
    fontSize: 12,
    fontWeight: 400,
    '& > span': {
      fontWeight: 700,
      fontSize: 28,
      lineHeight: '24px'
    }
  },
  id: {
    marginLeft: 44
  },
  wallet: {
    fontWeight: 300,
    fontSize: 20,
    lineHeight: '16px'
  },
  additionalLabel: {
    fontWeight: 600,
    fontSize: 24,
    lineHeight: '22px',
    letterSpacing: '0.5px',
    marginTop: 12,
    display: 'block',
    '& .submitted': {
      color: '#515fff'
    },
    '& .terminated': {
      color: '#FF3B30'
    },
    '& .warning': {
      color: '#FFCC00'
    },
    '& > span': {
      fontFamily: '\'Mulish\', sans-serif'
    }
  },
  rightSideContent: {
    position: 'relative',
    minWidth: 220,
    '& > div': {
      position: 'absolute',
      right: 0,
      top: -40
    },
    '& button': {
      minWidth: 164,
      height: 40
    }
  }
}));

const HeaderDetails = ({
  buttons,
  details,
  additionalLabel
}) => {
  const [t] = useTranslation(['main', 'kyc']);
  const classes = useStyles();
  const {balance, id, name, walletId, status} = details;

  return (
    <div className={`${classes.header} d-flex align-items-end justify-space-between`}>
      <div className={`${classes.headerDescription} d-flex align-items-end`}>
        <div className='d-flex align-items-start flex-column'>
          <div className='d-flex align-items-center'>
            <span className={`d-flex align-items-center ${classes.status}`}>
              <StatusBadge variant={status} />
            </span>
            <span className={`${classes.name} ${classes.title}`}>
              {name}
            </span>
          </div>
          <span className={classes.id}>
            ID: {id}
          </span>
        </div>

        {walletId && (
          <div className='d-flex align-items-start flex-column'>
            <span className={classes.title}>
              &euro;{balance}
            </span>
            <span>
              {t('kyc:walletBalance')} (ID: {walletId})
            </span>
          </div>
        )}
      </div>

      <div className={`${classes.rightSideContent} d-flex flex-column align-items-end`}>
        <div className='d-flex flex-column align-items-end'>
          <div className={`d-flex align-items-center ${classes.actionButtons}`}>
            {buttons.map((button, index) => button)}
          </div>
          {additionalLabel && <span className={classes.additionalLabel}>{additionalLabel}</span>}
        </div>
      </div>
    </div>
  );
}

HeaderDetails.propTypes = {
  additionalLabel: PropTypes.any,
  buttons: PropTypes.arrayOf(PropTypes.object),
  details: PropTypes.shape({
    balance: PropTypes.number.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    status: PropTypes.string,
    walletId: PropTypes.string.isRequired
  }),
  enableButtons: PropTypes.bool
}

HeaderDetails.defaultProps = {
  buttons: [],
  enableButtons: true
}

export default HeaderDetails;

