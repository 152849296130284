import React, {useState} from 'react';
import {makeStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import ReasonTextArea from '../ReasonTextArea';
import Alert from '../../../atoms/Alert';
import {helpers} from '../../../../helpers';
import {H1ReviewStatusesConstants} from '../../../../constants';

const {REJECTED} = H1ReviewStatusesConstants;

const useStyles = makeStyles(() => ({
  submissionDetails: {
    '& table': {
      '& tr': {
        height: 22
      },
      '& td': {
        fontSize: 16,
        '&:first-of-type': {
          fontWeight: 700,
          paddingRight: 16
        }
      }
    },
    '& > *:not(.feedback)': {
      padding: '22px 0',
      '&:first-of-type': {
        paddingTop: 0
      },
      '&:not(:first-of-type)': {
        borderTop: '1px solid #D8D7EA'
      }
    },
    '& .feedback + div': {
      borderTop: 'unset !important'
    }
  }
}));

const gObjProp = helpers.getObjProp;

const SubmissionDetails = ({
  submission,
  title,
  rows,
  handleReview,
  isEditable
}) => {
  const [t] = useTranslation(['kyc', 'main']);
  const classes = useStyles();
  const [isDisabledInput, setIsDisabledInput] = useState(false);


  const isPending = gObjProp(submission, 'isPending', false);
  const status = gObjProp(submission, 'h1_review_status').toUpperCase();
  const isRejected = (status === REJECTED && !isPending) || !isEditable;

  const handleSubmitReview = (variant, comment) => {
    if (handleReview) {
      setIsDisabledInput(true);
      handleReview(
        variant,
        comment,
        () => setIsDisabledInput(false),
        () => setIsDisabledInput(false)
      );
    }
  }

  const isNotPending = (submission && !isPending) || !isEditable;

  const alertMessage = `${t(`main:${isRejected ? 'rejected' : 'approved'}`)} ${t('submission')}`;

  return (
    <div className={classes.submissionDetails}>
      {(title && !isNotPending) && (
        <div className='f-20 fw-600 mulish-font'>
          {title}
        </div>
      )}

      {isNotPending && (
        <div className='feedback'>
          <Alert
            message={alertMessage}
            backgroundColor={isRejected ? '#FF000040' : '#03AB9340'}
          />
        </div>
      )}

      <div>
        <table>
          <tbody>
            {rows.map((row, index) => (
              <tr className='mulish-font' key={index}>
                <td>{row.title}:</td>
                <td>{gObjProp(submission, row.key)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {isEditable && isPending && (
        <ReasonTextArea
          placeholder={t('addOtherReason')}
          onSubmit={handleSubmitReview}
          disabled={isDisabledInput}
        />
      )}

    </div>
  );
}

SubmissionDetails.propTypes = {
  isEditable: PropTypes.bool,
  handleReview: PropTypes.func,
  submission: PropTypes.object.isRequired,
  title: PropTypes.string,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      key: PropTypes.string
    })
  )
}

SubmissionDetails.defaultProps = {
  isEditable: true,
  rows: []
}

export default SubmissionDetails;

