import { mainConstants } from '../constants';
import {localStorageKeysConstants} from '../../constants';

const mainState = {
  initialLoading: !!localStorage.getItem(localStorageKeysConstants.TOKEN),
  loading: false,
}

const mainReducer = (state = mainState, action) => {
  const {type, loading} = action;
  switch (type) {
    case mainConstants.SET_LOADING:
      return {
        ...state,
        loading
      }
    case mainConstants.SET_INITIAL_LOADING:
      return {
        ...state,
        initialLoading: loading
      }
    default:
      return state
  }
};

export default mainReducer;
