import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Collapse} from '@mui/material';
import PropTypes from 'prop-types';
import {makeStyles} from '@mui/styles';
import {connect} from 'react-redux';
import Button from '@h1-card/h1-lib-ui/dist/components/atoms/Button';
import Form from '../../../organism/Form';
import {submissionsActions} from '../../../../state/actions';
import {userTypesConstants} from '../../../../constants';

const useStyles = makeStyles(() => ({
  formContainer: {
    marginBottom: 20,
    '& button': {
      height: 40,
      marginTop: 22
    },
    '& input': {
      height: 36,
      fontSize: 12
    },
  },
  form: {
    transition: '.2s ease',
    '& > div': {
      maxWidth: 154,
      marginBottom: 8,
      marginRight: 12
    }
  }
}));

const defaultFormState = {
  firstName: '',
  email: '',
  lastName: ''
}

const CreateUboForm = ({
  onSuccess,
  sendJoinInvitation
}) => {
  const classes = useStyles();
  const [t] = useTranslation(['kyc', 'main']);
  const formRef = React.useRef(null);
  const [formState, setFormState] = useState(defaultFormState);
  const [helpMessage, setHelpMessage] = useState({type: 'error', message: ''});
  const [isDisabled, setIsDisabled] = useState(false);

  const requiredRules = [{name: 'required', value: true, message: t('main:validation.fieldIsRequired')}];

  const formItems = [
    {
      defaultValue: formState.firstName,
      label: t('firstName'),
      name: 'firstName',
      rules: requiredRules,
      type: 'input'
    },
    {
      defaultValue: formState.lastName,
      label: t('lastName'),
      name: 'lastName',
      rules: requiredRules,
      type: 'input',
      isRequired: true
    },
    {
      defaultValue: formState.email,
      label: t('email'),
      name: 'email',
      rules: requiredRules,
      type: 'input'
    },
  ];

  const onSubmitClick = () => !isDisabled && formRef.current.click();

  const handleSubmit = (fields) => {
    const data = {
      ...fields,
      email: fields.email,
      first_name: fields.firstName,
      last_name: fields.lastName,
      user_type: userTypesConstants.UBO
    }
    setFormState(fields);
    setIsDisabled(true);
    sendJoinInvitation(
      data,
      (userDetails) => {
        setHelpMessage({
          ...helpMessage,
          type: 'success',
          message: t('main:userWasSuccessfullyCreated')
        });
        setIsDisabled(false);
        setFormState(defaultFormState);
        onSuccess && onSuccess(userDetails);
      },
      (response) => {
        let message;
        if (response.response) {
          const {data} = response.response;
          const {detail} = data;
          message = typeof (detail) === 'object' && detail.length > 0 ? detail[0].msg : detail.toString();
        }
        setHelpMessage({...helpMessage, type: 'error', message});
        setIsDisabled(false);
      }
    );
  }

  return (
    <div className={classes.formContainer}>
      <div className='d-flex'>
        <Form
          className={`${classes.form} d-flex ${isDisabled && 'disabled'}`}
          disabled={isDisabled}
          items={formItems}
          ref={formRef}
          onSubmit={handleSubmit}
        />
        <Button
          disabled={isDisabled}
          onClick={onSubmitClick}
          variant='outlined'
        >
          {t('main:add')} {t('ubo')}
        </Button>
      </div>
      <Collapse in={helpMessage.message !== ''}>
        <span className={`${helpMessage.type}-message pt-0`}>
          {helpMessage.message}
        </span>
      </Collapse>
    </div>
  );
}

CreateUboForm.propTypes = {
  onSuccess: PropTypes.func
}

const mapDispatchToProps = {
  sendJoinInvitation: submissionsActions.sendJoinInvitation
}

export default connect(null, mapDispatchToProps)(CreateUboForm);
