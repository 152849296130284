import qs from 'qs';

export function authHeader(params = {}, skipAuthRefresh = false) {

  return {
    skipAuthRefresh,
    params,
    paramsSerializer: params => {
      let objectParams = {};
      let stringifyString = '';
      const paramsToRemove = [];

      Object.entries(params).forEach(([key, value]) => {
        if (typeof value === 'object') {
          objectParams[key] = value;
          paramsToRemove.push(key);
        }
      });

      paramsToRemove.map(p => {
        Reflect.deleteProperty(params, p);
        return p;
      })

      stringifyString = qs.stringify(params);

      Object.entries(objectParams).forEach(([key, value]) => {
        if (value) {
          value.map(v => {
            let symbol = stringifyString.length === 0 ? '' : '&';
            stringifyString += `${symbol}${key}=${encodeURIComponent(v)}`
            return v;
          })
        }
      });

      return stringifyString;
    }
  }
}
