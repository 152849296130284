import React from 'react';
import {useTranslation} from 'react-i18next';
import QuestionsIcon from '../../components/icons/QuestionsIcon';
import PageHeader from '../../components/atoms/Header/PageHeader';
import RequestsTable from '../../components/pages/KYCRequestListPage/RequestsTable';


const KYCRequestListPage = () => {
  const [t] = useTranslation('kyc');

  return (
    <div className='content-container'>
      <PageHeader title={t('kycRequests')} icon={<QuestionsIcon />} />
      <RequestsTable />
    </div>
  );
}

export default KYCRequestListPage;
