import {mainConstants} from '../constants';

const setLoading = (loading, dispatch) => {
  return dispatch({
    type: mainConstants.SET_LOADING,
    loading
  })
}

const setInitialLoading = (loading, dispatch) => {
  return dispatch({
    type: mainConstants.SET_INITIAL_LOADING,
    loading
  })
}

export const mainActions = {
  setLoading,
  setInitialLoading,
};
