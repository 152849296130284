import React from 'react';
import {makeStyles} from '@mui/styles';
import Select from '../Select/Select';
import {helpers} from '../../../../helpers';

const useStyles = makeStyles(() => ({
  select: {
    width: '100%',
    '& > div:first-of-type': {
      width: 'calc(100% - 48px)',
      fontSize: 14,
    },
    '& > div:nth-of-type(2)': {
      '& > div:nth-of-type(2)': {
        '& > div:nth-of-type(1)': {
          width: 'unset',
          padding: props => props.showSearch ? 16 : 0,
        },
        '& > div:nth-of-type(2)': {
          overflow: props => props.showSearch ? 'auto' : 'unset',
          maxHeight: props => props.showSearch ? 200 : 'unset',
          padding: 0,
        },
      },
    },
    '& input': {
      minHeight: 34,
      '&:focus': {
        borderWidth: '2px !important',
      },
    },
  },
  selectContainer: {
    '& [aria-expanded="true"]': {
      backgroundColor: '#f2f2f2',
      '& > div:nth-of-type(2)': {
        '& > div:nth-of-type(2)': {
          maxHeight: props => props.showSearch ? 280 : 165,
          overflow: props => props.showSearch ? 'hidden' : 'auto',
        },
      },
    },
    '& [aria-expanded="false"]': {
      '&:focus': {
        boxShadow: 'unset',
      },
    },
  },
}));

const ContainedSelect = ({
  className,
  ...rest
}) => {
  const classes = useStyles({showSearch: rest.showSearch});
  const cName = helpers.getClassName(classes.select, className);

  return (
    <div className={`${classes.selectContainer} d-flex`}>
      <Select className={cName} {...rest} />
    </div>
  );
};

export default ContainedSelect;
