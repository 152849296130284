import { combineReducers } from 'redux';
import alertReducer from './alert.reducer';
import authReducer from './auth.reducer';
import mainReducer from './main.reducer';
import userReducer from './user.reducer';

const rootReducer = combineReducers({
  alert: alertReducer,
  auth: authReducer,
  main: mainReducer,
  user: userReducer
});

export default rootReducer;
