import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Redirect, Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {makeStyles} from '@mui/styles';
import Col from '@h1-card/h1-lib-ui/dist/components/atoms/Col';
import Row from '@h1-card/h1-lib-ui/dist/components/atoms/Row';
import NotificationsIcon from '@h1-card/h1-lib-ui/dist/components/icons/NotificationsIcon';
import Navigation from '../Navigation';
import BusinessTradeIcon from '../../icons/BusinessTradeIcon';
import QuestionsIcon from '../../icons/QuestionsIcon';
import RouteWithTitle from '../../molecules/RouteWithTitle';
import NotFoundPage from '../../../pages/NotFoundPage';
import KYCRequestListPage from '../../../pages/KYCRequestListPage';
import KYCRequestDetailPage from '../../../pages/KYCRequestDetailPage';
import CompaniesListPage from '../../../pages/CompaniesListPage';
import AlertsListPage from '../../../pages/AlertsListPage';
import CompanyDetailPage from '../../../pages/CompanyDetailPage';
import {userActions} from '../../../state/actions';

const navWidth = 225;
const contentWidth = `calc(100% - ${navWidth}px)`;

const useStyles = makeStyles(() => ({
  navContainer: {
    width: navWidth,
    maxWidth: navWidth,
    minWidth: navWidth,
    zIndex: 4
  },
  contentContainer: {
    width: contentWidth,
    maxWidth: contentWidth,
    minWidth: contentWidth,
    maxHeight: '100vh',
    overflow: 'auto',
    minHeight: '100vh !important',
    backgroundColor: 'rgba(233, 233, 233, 0.3)',
  },
}));

const AuthPages = ({
  getPageTitle,
  getSuperusersList,
}) => {
  const classes = useStyles();
  const [navItems, setNavItems] = useState([]);

  const [t] = useTranslation('main');

  const getNavTitle = (key) => t(`navigation.${key}`);

  useEffect(() => {
    setNavItems([
      {title: getNavTitle('alerts'), path: '/alerts', icon: NotificationsIcon},
      {title: getNavTitle('kycRequests'), path: '/kyc-requests', icon: QuestionsIcon},
      {title: getNavTitle('companies'), path: '/companies', icon: BusinessTradeIcon}
    ]);
    getSuperusersList();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getTitle = (key) => getPageTitle ? getPageTitle(key) : key;

  return (
    <Row
      gutter='none'
      halign='left'
      valign='top'
    >
      <Col className={classes.navContainer}>
        <Navigation items={navItems} />
      </Col>
      <Col className={classes.contentContainer}>
        <Switch>
          <Route exact path='/' render={() => <Redirect to='/alerts' />} />
          <RouteWithTitle
            exact
            path='/kyc-requests'
            title={getTitle('kycRequestList')}
            component={KYCRequestListPage}
          />
          <RouteWithTitle
            exact
            path='/kyc-requests/:id'
            title={getTitle('kycRequestDetail')}
            component={KYCRequestDetailPage}
          />
          <RouteWithTitle
            exact
            path='/companies'
            title={getTitle('companiesList')}
            component={CompaniesListPage}
          />
          <RouteWithTitle
            exact
            path='/companies/:id'
            title={getTitle('companyDetail')}
            component={CompanyDetailPage}
          />
          <RouteWithTitle
            exact
            path='/alerts'
            title={getTitle('alertsList')}
            component={AlertsListPage}
          />
          <RouteWithTitle
            path='*'
            key='not-found'
            title={getTitle('notFound')}
          >
            <NotFoundPage />
          </RouteWithTitle>
        </Switch>
      </Col>
    </Row>
  )
}

AuthPages.propTypes = {
  getPageTitle: PropTypes.func
}

const mapDispatchToProps = {
  getSuperusersList: userActions.getSuperusersList
}

export default connect(null, mapDispatchToProps)(AuthPages);
