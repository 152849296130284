import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import useGeoLocation from 'react-ipgeolocation';
import {makeStyles} from '@mui/styles';
import {
  isValidPhoneNumber,
  parsePhoneNumber,
  validatePhoneNumberLength
} from 'libphonenumber-js';
import {useOnClickOutside} from '@h1-card/h1-lib-ui/dist/utils/hooks';
import Input from '@h1-card/h1-lib-ui/dist/components/atoms/Input';
import Button from '@h1-card/h1-lib-ui/dist/components/atoms/Button';
import {helpers} from '../../../../helpers';
import CountriesSelect from '../../../atoms/Select/CountriesSelect';
import FormItemStyled from '../../../molecules/FormItemStyled';


const useStyles = makeStyles(theme => ({
  group: {
    marginBottom: '0 !important',
    '& > div': {
      '&:last-of-type': {
        '& > div': {
          display: 'flex',
          '& > div': {
            '&:last-child': {
              '& input': {
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
              },
            },
          },
        },
      },
    },
    '& label': {
      marginBottom: 4,
      display: 'inline-block',
    },
    '& input': {
      height: 52,
      '&:hover, &:focus': {
        borderWidth: 1,
        boxShadow: 'unset',
        borderColor: 'rgb(148,147,166)',
      },

      [theme.breakpoints.down('md')]: {
        height: 44,
      },

      [theme.breakpoints.down('sm')]: {
        height: 40,
      },
    },
  },
  focus: {
    '& > div:nth-of-type(2)': {
      '& > div': {
        '& > div:first-of-type': {
          borderColor: '#03AB93',
        },
      },
    },
  },
  notValid: {
    '& input': {
      borderColor: 'rgb(255,59,48)',
      '&:hover, &:focus': {
        borderColor: 'rgb(255,59,48)',
      },
    },
    '& > div:nth-of-type(2)': {
      '& > div': {
        '& > div:first-of-type': {
          borderColor: 'rgb(255,59,48)',
        },
      },
    },
  },
  next: {
    marginTop: 12,
    '& button': {
      width: 137,
      height: 56,

      [theme.breakpoints.down('md')]: {
        width: 112,
        padding: 0,
        height: 48,
        fontSize: '1rem',
        textAlign: 'center'
      },

      [theme.breakpoints.down('sm')]: {
        height: 44,
        width: 106,
      },
    },
  },
  code: {
    border: '1px solid rgb(148,147,166)',
    borderRightWidth: 0,
    borderBottomLeftRadius: 4,
    borderTopLeftRadius: 4,
    fontSize: '0.875rem',
    width: 58,
    transition: 'all 0.2s ease-in-out 0s',

    [theme.breakpoints.down('md')]: {
      width: 52,
    },

    [theme.breakpoints.down('sm')]: {
      width: 48,
    },
  },
  selector: {
    position: 'relative',
    '& [role="button"]': {
      border: 'none',
      boxShadow: 'unset',
      height: 'unset',
      position: 'absolute',
      '& > div:first-of-type': {
        display: 'none',
      },
      '& [data-test-selector="select"]': {
        maxHeight: 200,
      }
    },
  },
  pin: {
    marginBottom: '0 !important'
  },
  container: {
    width: 330,
    '& input': {
      height: 52,
      boxShadow: 'unset',
      fontSize: 14,
      '&:hover, &:focus': {
        borderColor: '#03AB93',
        boxShadow: 'none'
      }
    },
    '& .error-message': {
      minHeight: 20
    }
  }
}));

const defaultCountry = helpers.getSupportedCountries()[0]['ISO3166-1-Alpha-2']; // first alphabetically country

const defaultValidProp = {valid: true, message: ''};

const countries = helpers.getSupportedCountries();

const PhoneAuth = ({
  onSuccess,
  errors,
  showPasswordField,
  helpText
}) => {
  const classes = useStyles();
  const [t] = useTranslation(['main', 'auth']);
  const [code, setCode] = useState('');
  const [country, setCountry] = useState('');
  const location = useGeoLocation();
  const [number, setNumber] = useState('');
  const [password, setPassword] = useState('');
  const [isLocated, setIsLocated] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [openCountriesList, setOpenCountriesList] = useState(false);
  const [validation, setValidation] = useState({
    password: defaultValidProp,
    phone: defaultValidProp,
    server: defaultValidProp
  });

  const numberInputRef = React.useRef(null);
  const passwordInputRef = React.useRef(null);

  const onCodeChange = (code) => {
    setOpenCountriesList(false);
    const country = countries.find(i => i.country_code === code);
    if (country) {
      setCountry(country['ISO3166-1-Alpha-2'])
    }
    setPlusCode(code);
    numberInputRef.current.focus();
  }

  const setPlusCode = (code) => setCode(`+${code}`);

  const getCountry = (countryCode) => {
    const country = helpers.getCountryByCode(countryCode);
    if (country) {
      setCountry(country['ISO3166-1-Alpha-2']);
      setPlusCode(country.country_code);
    }
  }

  useEffect(() => {
    onNumberChange({target: {value: number}}, true);
  }, [code]); // eslint-disable-line react-hooks/exhaustive-deps

  const onNumberChange = (e, checkLength = false) => {
    let value = e.target.value.replace(/\D/g,'');
    let codeLength = code.length;
    const isValidLength = validatePhoneNumberLength(`${code}${value}`, country) !== 'TOO_LONG';
    let spaceLength = 3;

    if (codeLength > 3) spaceLength = 2;

    if (!validation.phone) {
      setValidation({
        ...validation,
        phone: {
          ...validation.phone,
          valid: true,
        }
      });
    }

    if (value.length > spaceLength) value = helpers.addLetterByIndex(value, ' ', spaceLength);

    if (isValidLength) setNumber(value);

    if (checkLength && !isValidLength) setNumber(value.slice(0, 11));
  }

  const onPasswordChange = (e) => {
    const {value} = e.target;
    const numberValue = e.target.value.replace(/\D/g,'');
    if (numberValue.length <= 6 && value.length <= 6) {
      setPassword(value);
      if (!validation.password || !validation.password.valid) {
        setValidation({
          ...validation,
          password: {
            ...validation.password,
            valid: true
          }
        });
      }
    }
  }

  const focusGroupElement = () => {
    if (!isFocused) setIsFocused(true);
  }

  const getPhoneNumber = () => code + number.replace(' ', '');

  const submitForm = () => {
    if (onSuccess) {
      onSuccess({
        password,
        phone_number: getPhoneNumber()
      });
    }
  }

  const onSubmit = () => {
    let isValidPassword;
    const phoneNumber = getPhoneNumber();
    const isValidNum = isValidPhoneNumber(phoneNumber, country) && parsePhoneNumber(phoneNumber, country).number === phoneNumber;

    if (!isValidNum) {
      setValidation({
        ...validation,
        phone: {
          valid: isValidNum,
          message: t('auth:validation.phoneIsIncorrect')
        }
      });
      return;
    }

    isValidPassword = showPasswordField ? password !== '' : true;

    if (!isValidPassword) {
      setValidation({
        phone: {
          ...validation.phone,
          valid: true
        },
        password: {
          valid: isValidPassword,
          message: t('auth:validation.pinIsEmpty')
        }
      });
      return;
    }

    setValidation({
      ...validation,
      phone: {
        ...validation.phone,
        valid: true
      },
      password: {
        ...validation.password,
        valid: true
      }
    });
    submitForm();
  }

  const onPasswordKeyPress = (e) => {
    if (isFocused) setIsFocused(false);
    if (e.key.toLowerCase() === 'enter') onSubmit()
  }

  const onNumberKeyPress = (e) => {
    if (e.key.toLowerCase() === 'enter') {
      setIsFocused(false);
      if (showPasswordField) {
        passwordInputRef.current.focus();
      } else {
        onSubmit();
      }
    }
  }

  useEffect(() => {
    const {country} = location;
    if (country && !isLocated) {
      getCountry(country);
      setIsLocated(true);
    }
  }, [location, isLocated]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => getCountry(defaultCountry), []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (errors) {
      const {message} = errors;
      if (errors.hasOwnProperty('errors')) {
        let validData = {server: defaultValidProp};
        Object.keys(errors.errors).forEach(value => {
          validData[value] = {
            valid: false,
            message: errors.errors[value]
          };
        });
        setValidation({...validation, ...validData});
      } else {
        setValidation({
          password: defaultValidProp,
          phone: defaultValidProp,
          server: {
            valid: false,
            message
          }
        })
      }
    }
  }, [errors]); // eslint-disable-line react-hooks/exhaustive-deps

  const errorMessage = (propName, serverError = true) => {
    let msg = '';
    if (validation.hasOwnProperty(propName)) {
      const {valid, message} = validation[propName];
      if (!valid) {
        msg = serverError ? t(`auth:errors.${message}`) : message;
      }
    }
    return (
      <div className='error-message error-landing'>
        {msg}
      </div>
    )
  }

  const validationError = !validation.password.valid ? errorMessage('password', false) : errorMessage('server');

  return (
    <>
      <div className={classes.container}>
        <div onClick={() => focusGroupElement()}
             ref={useOnClickOutside(() => setIsFocused(false), isFocused)}>
          <FormItemStyled
            label={t('phoneNumber')}
            className={`${classes.group} ${validation.phone.valid ? isFocused ? classes.focus : '' : classes.notValid}`}
          >
            <div
              className={`d-flex align-items-center justify-center pointer ${classes.code}`}
              onClick={() => setOpenCountriesList(!openCountriesList)}>
              {code}
            </div>
            <Input
              onChange={(e) => onNumberChange(e)}
              readonly={false}
              size='small'
              type='text'
              value={number}
              ref={numberInputRef}
              onKeyPress={(e) => onNumberKeyPress(e)}
            />
          </FormItemStyled>
          <div className={classes.selector}>
            <CountriesSelect
              onItemChange={onCodeChange}
              showSearch={true}
              onBlur={() => setOpenCountriesList(false)}
              openList={openCountriesList}
              activeCountryCode={country}
            />
          </div>
        </div>
        {errorMessage('phone')}
        {showPasswordField && (
          <>
            <FormItemStyled
              label={t('pin')}
              className={`${classes.pin} ${validation.password.valid ? '' : classes.notValid}`}
            >
              <Input
                onChange={(e) => onPasswordChange(e)}
                readonly={false}
                type='password'
                value={password}
                ref={passwordInputRef}
                onKeyPress={(e) => onPasswordKeyPress(e)}
              />
            </FormItemStyled>
          </>
        )}

        {validationError}

        {helpText || ''}
        <div className={classes.next}>
          <Button size='large' onClick={onSubmit}>{t('next')}</Button>
        </div>
      </div>
    </>
  );
}

PhoneAuth.propTypes = {
  onSuccess: PropTypes.func,
  errors: PropTypes.shape({
    message: PropTypes.string,
    errors: PropTypes.object
  }),
  helpText: PropTypes.any,
  showPasswordField: PropTypes.bool
};

PhoneAuth.defaultProps = {
  showPasswordField: true
};

export default PhoneAuth;
