import React from 'react';
import PropTypes from 'prop-types';

const QuestionsIcon = ({fill}) => {
  return (
    <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M17.7288 19.0078H0.819667V13.3485C1.84553 9.92465 5.53789 10.4257 6.40702 10.5991L8.90906 15.5197C8.97843 15.6546 9.11911 15.7445 9.27328 15.7445H9.27392C9.42745 15.7445 9.56813 15.6546 9.63879 15.5197L12.1254 10.6891C14.1515 10.5221 15.6777 10.8625 16.6644 11.704C17.6106 12.507 17.7198 13.4898 17.7288 13.599V19.0078ZM8.55061 11.5948L8.30651 12.5263L7.24467 10.4385C7.51318 10.5413 7.79261 10.6248 8.07975 10.6826C8.06948 10.734 8.06369 10.7918 8.06369 10.8432C8.06369 11.1773 8.26412 11.4663 8.55061 11.5948ZM10.0499 11.5691C10.3075 11.4278 10.4848 11.158 10.4848 10.8432C10.4848 10.7726 10.4726 10.7083 10.4559 10.6377C10.7809 10.5606 11.0944 10.4514 11.3931 10.3165L10.3897 12.2693L10.0499 11.5691ZM8.60971 10.8432C8.60971 10.6955 8.73305 10.567 8.88529 10.567H9.6632C9.8148 10.567 9.93814 10.6955 9.93814 10.8432C9.93814 10.9974 9.8148 11.1195 9.6632 11.1195H8.88529C8.73305 11.1195 8.60971 10.9974 8.60971 10.8432ZM9.09599 11.6655H9.49169L10.0769 12.8731L9.27649 14.4341L8.67909 13.2586L9.09599 11.6655ZM4.55185 5.39592C4.55185 2.84571 6.62671 0.770846 9.17693 0.770846C11.7278 0.770846 13.802 2.84571 13.802 5.39592C13.802 7.93972 11.7393 10.0146 9.19877 10.021H9.15508C6.6145 10.0146 4.55185 7.93972 4.55185 5.39592ZM17.2124 11.0938C16.0811 10.1238 14.3994 9.70624 12.2108 9.86041C13.6356 8.884 14.5735 7.25238 14.5735 5.39592C14.5735 2.42174 12.1524 0 9.17693 0C6.20082 0 3.78036 2.42174 3.78036 5.39592C3.78036 7.15602 4.62701 8.72341 5.93423 9.71266C4.354 9.55207 1.02587 9.6677 0.0154169 13.175C0.00513898 13.2136 0 13.2521 0 13.2907V19.4189C0 19.6437 0.183076 19.83 0.409191 19.83H18.1387C18.3654 19.83 18.5485 19.6437 18.5485 19.4189V13.5797C18.5485 13.5733 18.5485 13.5669 18.5478 13.5605C18.5446 13.5027 18.4553 12.1601 17.2124 11.0938Z" fill={fill}/>
      <path d="M8.4327 6.92374V6.82715C8.43459 6.49571 8.46395 6.23151 8.52077 6.03454C8.57948 5.83757 8.66471 5.67848 8.77645 5.55726C8.88819 5.43605 9.02266 5.3262 9.17986 5.22772C9.29728 5.15196 9.4024 5.07336 9.4952 4.99192C9.588 4.91048 9.66187 4.82052 9.71679 4.72204C9.77172 4.62166 9.79918 4.50992 9.79918 4.38681C9.79918 4.25613 9.76793 4.14154 9.70543 4.04306C9.64293 3.94458 9.55865 3.86882 9.45259 3.81579C9.34842 3.76276 9.23289 3.73624 9.106 3.73624C8.98289 3.73624 8.86641 3.7637 8.75656 3.81863C8.64672 3.87166 8.55675 3.9512 8.48668 4.05726C8.4166 4.16143 8.37872 4.29117 8.37304 4.44647H7.21395C7.22342 4.06768 7.31433 3.75518 7.48668 3.50897C7.65903 3.26086 7.88725 3.0762 8.17134 2.95499C8.45543 2.83189 8.76887 2.77033 9.11168 2.77033C9.48857 2.77033 9.82191 2.83283 10.1117 2.95783C10.4015 3.08094 10.6287 3.25992 10.7935 3.49476C10.9583 3.72961 11.0407 4.01276 11.0407 4.3442C11.0407 4.56579 11.0037 4.76276 10.9299 4.93511C10.8579 5.10556 10.7566 5.25708 10.6259 5.38965C10.4952 5.52033 10.3408 5.6387 10.1628 5.74476C10.0132 5.83378 9.89009 5.92658 9.7935 6.02317C9.6988 6.11976 9.62778 6.23151 9.58043 6.3584C9.53497 6.4853 9.5113 6.64155 9.50941 6.82715V6.92374H8.4327ZM8.9952 8.74192C8.80581 8.74192 8.64387 8.67564 8.50941 8.54306C8.37683 8.40859 8.31149 8.24761 8.31338 8.06011C8.31149 7.8745 8.37683 7.71541 8.50941 7.58283C8.64387 7.45026 8.80581 7.38397 8.9952 7.38397C9.17512 7.38397 9.33327 7.45026 9.46963 7.58283C9.606 7.71541 9.67512 7.8745 9.67702 8.06011C9.67512 8.18511 9.64198 8.29969 9.57759 8.40386C9.51509 8.50613 9.4327 8.58851 9.33043 8.65101C9.22815 8.71162 9.11641 8.74192 8.9952 8.74192Z" fill={fill} />
    </svg>
  )
}

QuestionsIcon.propTypes = {
  fill: PropTypes.string,
}

QuestionsIcon.defaultProps = {
  fill: '#191927',
}

export default QuestionsIcon;
