import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@mui/styles';


const useStyles = makeStyles(() => ({
  badge: {
    display: 'inline-block',
    width: 18,
    height: 18,
    minWidth: 18,
    borderRadius: '50%',
    '&.warning': {
      backgroundColor: '#ffcc00'
    },
    '&.error': {
      backgroundColor: '#ea7369'
    },
    '&.success': {
      backgroundColor: '#009a7f'
    },
    '&.submitted': {
      backgroundColor: '#515fff'
    }
  },
}));

const StatusBadge = ({variant}) => {
  const classes = useStyles();

  return (
    <div className={`${classes.badge} ${variant}`} />
  );
}

StatusBadge.propTypes = {
  variant: PropTypes.oneOf(['warning', 'error', 'success', 'submitted'])
}

StatusBadge.defaultProps = {
  variant: 'warning'
}

export default StatusBadge;
