import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@mui/styles';
import TableCol from '@h1-card/h1-lib-ui/dist/components/molecules/Table/components/TableCol';
import TableRow from '@h1-card/h1-lib-ui/dist/components/molecules/Table/components/TableRow';
import StatusBadge from '../../../atoms/StatusBadge';
import {helpers} from '../../../../helpers';


const useStyles = makeStyles(() => ({
  tableRow: {
    transition: '0.3s ease',
    minWidth: 700,
    '& .row-container > div': {
      pointerEvents: 'unset',
      fontSize: 14,
      paddingTop: 12,
      paddingBottom: 12,
      fontWeight: 700,
      color: '#191927',
      '&:nth-of-type(1),&:nth-of-type(2)': {
        color: '#0A145A'
      }
    },
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#F0F8FF',
    },
  },
  status: {
    textTransform: 'uppercase',
    '& > div': {
      marginRight: 8
    }
  }
}));

const CompanyTableRow = ({
  row,
  handleRowClick
}) => {
  const classes = useStyles();
  const [t] = useTranslation('main');

  const {id, data} = row;

  const onRowClick = (e) => {
    if (e.target.tagName.toLowerCase() !== 'button' && handleRowClick) handleRowClick(id);
  }

  const status = helpers.getCompanyStatus(data.company_status);

  return (
    <div
      data-testid='row'
      className={classes.tableRow}
      onClick={(e) => onRowClick(e)}>
      <TableRow key={id}>
        <TableCol>
          {data.name}
        </TableCol>
        <TableCol>
          <span className='fw-400'>{data.id}</span>
        </TableCol>
        <TableCol>
          <div className={`${classes.status} d-flex align-items-center`}>
            <StatusBadge variant={status.variant} />
            {t(status.key)}
          </div>
        </TableCol>
        <TableCol>
          &euro;{data.company_balance}
        </TableCol>
      </TableRow>
    </div>
  )
}

CompanyTableRow.propTypes = {
  row: PropTypes.object.isRequired,
}

export default CompanyTableRow;
