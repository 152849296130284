import React from 'react';
import PropTypes from 'prop-types';
import StyledCounterLabel
  from '@h1-card/h1-lib-ui/dist/components/molecules/Table/components/TableHead/StyledCounterLabel';
import TableCol from '@h1-card/h1-lib-ui/dist/components/molecules/Table/components/TableCol';
import TableRow from '@h1-card/h1-lib-ui/dist/components/molecules/Table/components/TableRow';

const TableHead = ({
  cols,
  bulkActions,
  allSelected,
  selectedCounter,
}) => {
  return (
    <TableRow isHead actions={bulkActions && []}>
      {allSelected ? (
        <TableCol auto>
          {selectedCounter && (
            <StyledCounterLabel>
              {selectedCounter} {selectedCounter > 1 ? 'items' : 'item'} selected
            </StyledCounterLabel>
          )}
          {bulkActions && bulkActions}
        </TableCol>
      ) : (
        cols
      )}
    </TableRow>
  );
};

TableHead.propTypes = {
  /** An array onc TableCol elements */
  cols: PropTypes.arrayOf(
    PropTypes.shape({
      type: TableCol,
    })
  ),
  /** The element to show in the Action area */
  bulkActions: PropTypes.element,
  /** If true all rows will be selected */
  allSelected: PropTypes.oneOf([true, false, 'semi-checked']),
  /** Shows the number of items selected */
  selectedCounter: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool
  ]),
};

TableHead.displayName = 'TableHead';

export default TableHead;
