import React, {useState, useEffect} from 'react';
import {makeStyles} from '@mui/styles';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {
  TextAreaContainer,
  StyledTextArea,
} from '@h1-card/h1-lib-ui/dist/components/atoms/TextArea/StyledTextArea';
import ButtonWithLoader from '@h1-card/h1-lib-ui/dist/components/molecules/ButtonWithLoader';

const useStyles = makeStyles(() => ({
  reasonContainer: {
    '& textarea': {
      width: 'calc(100% - (16px * 2))',
      minHeight: props => `calc(${props.minHeight}px - (16px * 2))`
    }
  },
  buttons: {
    textAlign: 'right',
    '& > button': {
      height: 40
    }
  }
}));

const FeedbackTextArea = ({
  initialValue,
  placeholder,
  onSubmit,
  disabled,
  maxLength,
  minHeight,
  disabledButton,
  isButtonLoading
}) => {
  const [t] = useTranslation('main');
  const classes = useStyles({minHeight});
  const [inputValue, setInputValue] = useState(initialValue);

  useEffect(() => setInputValue(initialValue), [initialValue]);

  const onChange = (e) => {
    const {value} = e.target;

    if (maxLength && value.length > maxLength) return;
    setInputValue(e.target.value);
  }

  const handleSubmit = () => {
    if (inputValue.length > 0 && onSubmit) onSubmit(inputValue);
  }

  const isDisabledButton = disabledButton || inputValue.length === 0;

  return (
    <div className={classes.reasonContainer}>
      <TextAreaContainer>
        <StyledTextArea
          value={inputValue}
          onChange={onChange}
          placeholder={placeholder}
          disabled={disabled}
        />
      </TextAreaContainer>
      <div className={`${classes.buttons}`}>
        <ButtonWithLoader
          onClick={handleSubmit}
          variant='danger'
          disabled={isDisabledButton || isButtonLoading}
          loading={isButtonLoading}
        >
          {t('sendFeedback')}
        </ButtonWithLoader>
      </div>
    </div>
  );
}

FeedbackTextArea.propTypes = {
  disabled: PropTypes.bool,
  initialValue: PropTypes.string,
  onSubmit: PropTypes.func,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  minHeight: PropTypes.number,
  disabledButton: PropTypes.bool,
  isButtonLoading: PropTypes.bool
}

FeedbackTextArea.defaultProps = {
  disabled: false,
  disabledButton: false,
  initialValue: '',
  minHeight: 100,
  isButtonLoading: false
}

export default FeedbackTextArea;

