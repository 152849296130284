import {
  StyledModalContainer,
  StyledModalContent, StyledModalFooter, StyledModalHeader
} from '@h1-card/h1-lib-ui/dist/components/molecules/StaticModal/StyledStaticModal';
import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@h1-card/h1-lib-ui/dist/components/molecules/IconButton';
import Text from '@h1-card/h1-lib-ui/dist/components/atoms/Text';
import {makeStyles} from '@mui/styles';
import {ArrowLeft2Icon, RemoveIcon} from "@h1-card/h1-lib-ui/dist/components/icons";

const useStyles = makeStyles(() => ({
  prevIcon: {
    width: 24,
    '& button': {
      padding: 2
    },
    '& i > *': {
      width: 20,
      height: 20
    }
  },
  title: {
    paddingLeft: props => props.showPrevIcon ? 0 : props.rightSidePosition ? 24 : 0
  }
}));

const H1Modal = ({
  title,
  footer,
  children,
  position,
  variant,
  handleClose,
  onFormSubmit,
  showHeader,
  hideCloseIcon,
  showPrevIcon,
  handleOnPrevClick,
  rightSidePosition,
  ...rest
}) => {
  const classes = useStyles({showPrevIcon, rightSidePosition});
  const content = (
    <React.Fragment>
      {showHeader && (
        <StyledModalHeader variant={variant}>
          {
            <React.Fragment>
              {showPrevIcon && (
                <div className={classes.prevIcon}>
                  <IconButton
                    size='small'
                    icon={ArrowLeft2Icon}
                    onClick={handleOnPrevClick}
                  />
                </div>
              )}

              <Text tag='span' size='xheadline' weight='semi-bold' className={classes.title}>
                {title}
              </Text>

              {!hideCloseIcon && (
                <IconButton
                  variant={variant === 'standard' ? 'dark' : 'error'}
                  size='small'
                  icon={RemoveIcon}
                  onClick={handleClose}
                />
              )}

            </React.Fragment>
          }
        </StyledModalHeader>
      )}
      <StyledModalContent data-test='modal-content' className='modal-body'>{children}</StyledModalContent>
      {footer && <StyledModalFooter>{footer}</StyledModalFooter>}
    </React.Fragment>
  );

  if (onFormSubmit) {
    return (
      <StyledModalContainer position={position} {...rest}>
        <form onSubmit={onFormSubmit}>{content}</form>
      </StyledModalContainer>
    );
  }

  return (
    <StyledModalContainer position={position} {...rest}>
      {content}
    </StyledModalContainer>
  );
};

H1Modal.propTypes = {
  /** The title of the Modal */
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),

  /** The footer accepts an element */
  footer: PropTypes.element,

  /** Defines the style of the modal Header */
  variant: PropTypes.oneOf(['standard', 'danger']),

  /** Callback function for closing the Modal */
  handleClose: PropTypes.func,

  /** If passed the modal footer will be wrapper into a form so that footer buttons can work with submit type. On form submit the function will be executed */
  onFormSubmit: PropTypes.func,

  /** Show header prop */
  showHeader: PropTypes.bool,

  /** Hide close icon button if true */
  hideCloseIcon: PropTypes.bool,

  /** Show prev icon button if true */
  showPrevIcon: PropTypes.bool,

  /** Callback function for clicking of previous button */
  handleOnPrevClick: PropTypes.func,

  rightSidePosition: PropTypes.bool
};
H1Modal.defaultProps = {
  variant: 'standard',
  showHeader: true,
  hideCloseIcon: false,
  showPrevIcon: false,
  rightSidePosition: false
};
export default H1Modal;
