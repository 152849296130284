import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@mui/styles';

import TableControlled from '../../../molecules/Table/TableControlled';
import UsersTableRow from '../../CompanyDetailPage/UsersTableRow';

import {helpers} from '../../../../helpers';


const useStyles = makeStyles(() => ({
  table: {
    '& .row-container': {
      borderTop: '2px solid #E9E9E9 !important',
      '& > div': {
        '&:nth-child(2)': {
          maxWidth: 200
        },
        '&:nth-child(3), &:nth-child(5)': {
          maxWidth: 120
        }
      }
    },
    '& > div > div > div:first-of-type': {
      '& .row-container': {
        borderTop: 'unset !important',
        '& > div:first-of-type': {
          '& span': {
            marginLeft: 40
          }
        }
      },
    }
  }
}));


const UsersTable = ({
  users
}) => {
  const classes = useStyles();
  const [t] = useTranslation('main');

  const minWidth = 700;
  const [cols, setCols] = useState([]);

  useEffect(() => {
    const cols = helpers.getUsersTableCols(t);
    setCols(cols);
  }, [t]);

  return (
    <div className='table-container p-0'>
      <div className={classes.table}>
        <TableControlled
          minWidth={minWidth}
          cols={cols}
          rowComponent={UsersTableRow}
          hasSelectAll={false}
          rows={helpers.createRows(users)}
        />
      </div>
    </div>
  );
}

export default UsersTable;
