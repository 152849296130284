import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import mime from 'mime-types';
import {makeStyles} from '@mui/styles';
import {useTranslation} from 'react-i18next';
import TableCol from '@h1-card/h1-lib-ui/dist/components/molecules/Table/components/TableCol';
import TableRow from '@h1-card/h1-lib-ui/dist/components/molecules/Table/components/TableRow';
import FileUploader from '../../../molecules/FileUploader';
import DocumentDetailsTableRow from '../DocumentDetailsTableRow';
import {documentTypeStatusesConstants, userTypesConstants, userTypesLabels} from '../../../../constants';
import {submissionsActions} from '../../../../state/actions';
import {helpers} from '../../../../helpers';

const {LEGAL_PERSON} = userTypesConstants;


const useStyles = makeStyles(() => ({
  tableRow: {
    transition: '0.3s ease',
    minWidth: 700,
    '& .row-container': {
      borderTop: 'unset',
      pointerEvents: 'unset',
      '& > div': {
        pointerEvents: 'unset',
        fontSize: 14,
        paddingTop: 12,
        paddingBottom: 12,
        color: '#191927',
        borderTop: '2px solid #E9E9E9 !important',
      },
      '&:hover': {
        backgroundColor: '#F0F8FF',
        cursor: 'pointer',
      },
    },
    '& .document-row': {
      '& .row-container': {
        borderTop: 'unset',
        '& > div:first-of-type': {
          borderTop: 'unset !important',
        }
      }
    }
  }
}));

const DocumentsTableRow = ({
  row,
  handleUpdateDocuments,
  createDocument,
  downloadDocument,
  updateDocument
}) => {
  const [t] = useTranslation(['main', 'kyc']);
  const classes = useStyles();
  const [isDisabledAddButton, setIsDisabledAddButton] = useState(false);
  const {data} = row;
  const {documents, user} = data;
  const {id: userId, user_type: userType} = user;

  const user_name = userType === LEGAL_PERSON ? user.legal_name : `${user.firstname} ${user.lastname}`;
  const user_type = userTypesLabels[userType] || 'unknown';

  const handleDownload = (document, successCallback, errorCallback) => {
    const {id: documentId, name: fileName, user_id: userId} = document;
    const fileType = mime.lookup(fileName);
    downloadDocument(
      userId,
      documentId,
      (data) => {
        helpers.saveFile(data, fileName, fileType);
        successCallback && successCallback(data);
      },
      (resp) => errorCallback && errorCallback(resp)
    )
  }

  const handleUpdateDocument = async (currentDocument, uploadedDocument, successCallback, errorCallback) => {
    const {id: documentId, document_type_id: documentTypeId, user_id: userId} = currentDocument;

    const data = await helpers.getUploadedFilePayload(uploadedDocument, documentTypeId, userId);

    updateDocument(
      userId,
      documentId,
      data,
      (document) => {
        successCallback && successCallback(document);
        handleUpdateDocuments && handleUpdateDocuments('update', userId, document);
      },
      (resp) => errorCallback && errorCallback(resp)
    )
  }

  const handleCreateDocument = async (document) => {
    const documentTypeId = userType === LEGAL_PERSON
      ? documentTypeStatusesConstants.COMPANY_REGISTRATION
      : documentTypeStatusesConstants.IDENTITY_CARD;
    const data = await helpers.getUploadedFilePayload(document, documentTypeId, userId);
    setIsDisabledAddButton(true);
    createDocument(
      data,
      (document) => {
        setIsDisabledAddButton(false);
        handleUpdateDocuments && handleUpdateDocuments('add', userId, document);
      },
      () => setIsDisabledAddButton(false)
    )
  }

  return (
    <div
      data-testid='row'
      className={classes.tableRow}
    >
      <TableRow>
        <TableCol>{user_name}</TableCol>
        <TableCol>{user_type}</TableCol>
        <TableCol/>
        <TableCol/>
        <TableCol/>
        <TableCol>
          <FileUploader
            alertType='alert'
            buttonText={`${t('add')} ${t('document')}`}
            disabled={isDisabledAddButton}
            handleOnChange={handleCreateDocument}
          />
        </TableCol>
      </TableRow>
      {documents.map((document, index) => (
        <DocumentDetailsTableRow
          className='document-row'
          document={document}
          key={index}
          index={index}
          handleUpdateDocument={handleUpdateDocument}
          handleDownload={handleDownload}
        />
      ))}
    </div>
  )
}

DocumentsTableRow.propTypes = {
  handleUpdateDocuments: PropTypes.func,
  row: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    data: PropTypes.shape({
      documents: PropTypes.arrayOf(
        PropTypes.shape({
          document_type_id: PropTypes.number.isRequired,
          file_url: PropTypes.string.isRequired,
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired
        })
      ),
      user: PropTypes.shape({
        firstname: PropTypes.string,
        id: PropTypes.string.isRequired,
        lastname: PropTypes.string,
        user_type: PropTypes.string
      }).isRequired
    })
  }).isRequired
}

const mapDispatchToProps = {
  createDocument: submissionsActions.createDocument,
  downloadDocument: submissionsActions.downloadDocument,
  updateDocument: submissionsActions.updateDocument
}

export default connect(null, mapDispatchToProps)(DocumentsTableRow);
