import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Box from '@h1-card/h1-lib-ui/dist/components/atoms/Box';
import Dropdown from '@h1-card/h1-lib-ui/dist/components/atoms/Dropdown';
import {SearchContainer} from '@h1-card/h1-lib-ui/dist/components/molecules/Select/components/MultipleSelection/StyledMultipleSelection';
import SearchIcon from '@h1-card/h1-lib-ui/dist/components/icons/SearchIcon';
import EmptyState from '@h1-card/h1-lib-ui/dist/components/molecules/EmptyState';
import Search from '@h1-card/h1-lib-ui/dist/components/molecules/Search';
import ListItem from '../ListItem/ListItem';
import SelectHeader from '../SelectHeader/SelectHeader';
import {helpers} from '../../../../helpers';
const Select = ({
  options,
  onItemChange,
  error,
  size,
  disabled,
  dropdownPosition,
  setLargeMinWidth,
  setMaxContentMinWidth,
  showAllOption,
  placeholder,
  onBlur,
  onFocus,
  tabIndex,
  setMaxHeight,
  openList,
  showCounter,
  showSearch,
  showImage,
  countType,
  handleSearchItemsFilter,
  ...rest
}) => {
  const [isSelectOpen, setSelectOpen] = useState();
  const [labelsSelected, setLabelsSelected] = useState([]);
  const [itemsSelected, setItemsSelected] = useState([]);
  const [filteredOptions, setFilteredOption] = useState(options || []);
  const [selectedOption, setSelectedOption] = useState();

  useEffect(() => {
    if (openList) setSelectOpen(openList);
  }, [openList]);

  const checkItemChange = value => {
    const selectedItemLabel = options.filter(item => item.value === value).map(selected => selected.label);
    setLabelsSelected(selectedItemLabel);
    setItemsSelected(options.filter(item => item.value === value));
    setSelectedOption(value);
    onItemChange && onItemChange(value);
  };

  const listItems = filteredOptions.map((item, key) => {
    const {isDisabled} = item;
    let cName = `hx-test-${key} item-custom-select`;
    if (isDisabled) cName += ' disabled';
    return (
      <ListItem
        key={key}
        className={cName}
        iconLeft={item.iconLeft}
        text={item.label}
        hasArrow={item.hasArrow}
        isActive={item.value === selectedOption}
        isDisabled={isDisabled}
        count={helpers.isNaV(item.count) ? null : item.count}
        countType={countType}
        image={item.image || null}
        countColor={item.countColor || null}
        countClassName={item.countClassName || ''}
        onClick={() => {
          checkItemChange(item.value);
          setSelectOpen(false);
        }}
      />
    )
  });

  useEffect(() => {
    const initialItem = options.filter(item => item.isActive).map(selected => selected.label);
    const checkItemSelected = options.filter(item => item.isActive);
    const selectedItemValue = checkItemSelected.length > 0 ? checkItemSelected[0].value : [];
    setSelectedOption(selectedItemValue);
    setLabelsSelected(initialItem);
    setItemsSelected(options.filter(item => item.isActive));
    setFilteredOption(options);
  }, [options]);

  const onSearchItems = (list, value) => {
    const valueLower = value.toLowerCase();
    const filteredItems = handleSearchItemsFilter ? handleSearchItemsFilter(list, valueLower) : list.filter(item => {
      const labelLower = item.label.toLowerCase();
      return labelLower.includes(valueLower);
    });

    setFilteredOption(filteredItems);
  };

  return (
    <SelectHeader
      labelsSelected={labelsSelected}
      itemsSelected={itemsSelected}
      placeholder={placeholder}
      onHeaderClick={(item) => {
        if (item) setSelectOpen(item);
        // SetSelectOpen(item)
      }}
      error={error}
      disabled={disabled}
      size={size}
      onFocus={onFocus}
      onBlur={onBlur}
      tabIndex={tabIndex}
      isSelectOpen={isSelectOpen}
      options={options}
      showCounter={showCounter}
      showImage={showImage}
      {...rest}
    >
      <Dropdown
        isOpen={isSelectOpen}
        position={dropdownPosition}
        fullWidth={true}
        setLargeMinWidth={setLargeMinWidth}
        setMaxContentMinWidth={setMaxContentMinWidth}
        setMaxHeight={setMaxHeight}
        content={
          <React.Fragment>
            {showSearch && (
              <SearchContainer>
                <Search
                  size='small'
                  delay={500}
                  className='select-search'
                  onSearchChange={value => onSearchItems(options, value)}
                />
              </SearchContainer>
            )}

            <Box pY='small' pX='small' className='box-selector'>
              <div data-test-selector='select'>
                {showAllOption && (
                  <ListItem
                    key='all'
                    text='All'
                    isActive={
                      selectedOption === 'all' || labelsSelected.length === 0
                    }
                    onClick={() => {
                      onItemChange('ALL');
                      setSelectedOption();
                      setLabelsSelected([]);
                      setSelectOpen(false);
                    }}
                  />
                )}
                {listItems.length > 0 ? listItems : (options.length > 0 ? (
                  <EmptyState
                    icon={SearchIcon}
                    mainText='No items match with your research'
                    size='small'
                  />
                ) : null)}
              </div>
            </Box>
          </React.Fragment>
        }
        onClickOutside={() => {
          onBlur && onBlur();
          setSelectOpen(false);
        }}
      />
    </SelectHeader>
  );
};

Select.propTypes = {
  /* select set min-width of dropDown */
  setLargeMinWidth: PropTypes.bool,

  /* select set max content width of dropDown */
  setMaxContentMinWidth: PropTypes.bool,

  /* select dropdown position */
  dropdownPosition: PropTypes.oneOf(['left', 'right']),

  /* select error */
  error: PropTypes.bool,

  /* select disabled */
  disabled: PropTypes.bool,

  /* select option required */
  options: PropTypes.arrayOf(PropTypes.shape({
    /* select single item icon */
    iconLeft: PropTypes.string,

    /* select single item label */
    label: PropTypes.string.isRequired,

    /* select single item value */
    value: PropTypes.any.isRequired,

    /* select single item arrow */
    hasArrow: PropTypes.bool,

    /* select single item isActive */
    isActive: PropTypes.bool,

    /* select single item isDisabled */
    isDisabled: PropTypes.bool,

    /* select single item click */
    onClick: PropTypes.func,

    /* count of available items */
    count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

    /* color of count label */
    countColor: PropTypes.string,
  }).isRequired),

  /* select single item change */
  onItemChange: PropTypes.func.isRequired,

  /* if false wont' show option ALL as first */
  showAllOption: PropTypes.bool,

  /* if passed replaces ALL label with it's value */
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(['standard', 'small', 'thin']),
  tabIndex: PropTypes.number,
  showCounter: PropTypes.bool,
  showSearch: PropTypes.bool,
  showImage: PropTypes.bool,
  countType: PropTypes.oneOf(['text', 'label']),
  handleSearchItemsFilter: PropTypes.func
};
Select.defaultProps = {
  error: false,
  disabled: false,
  dropdownPosition: 'left',
  size: 'small',
  showAllOption: false,
  tabIndex: 0,
  showCounter: true,
  showSearch: false,
  showImage: false,
  countType: 'label'
};
export default Select;
