import React, {useState, useEffect} from 'react';
import {makeStyles} from '@mui/styles';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import Button from '@h1-card/h1-lib-ui/dist/components/atoms/Button';
import {
  TextAreaContainer,
  StyledTextArea,
} from '@h1-card/h1-lib-ui/dist/components/atoms/TextArea/StyledTextArea';

const useStyles = makeStyles(() => ({
  reasonContainer: {
    '& textarea': {
      width: 'calc(100% - (16px * 2))'
    }
  },
  buttons: {
    textAlign: 'right',
    '& > button': {
      height: 40,
      width: 128,
      '&:not(:last-of-type)': {
        marginRight: 12
      }
    }
  }
}));

const ReasonTextArea = ({
  initialValue,
  placeholder,
  onSubmit,
  disabled,
  maxLength
}) => {
  const [t] = useTranslation('main');
  const classes = useStyles();
  const [inputValue, setInputValue] = useState(initialValue);

  useEffect(() => setInputValue(initialValue), [initialValue]);

  const onChange = (e) => {
    const {value} = e.target;

    if (maxLength && value.length > maxLength) return;
    setInputValue(e.target.value);
  }

  const handleSubmit = (variant) => {
    if (inputValue.length > 0 && onSubmit) onSubmit(variant, inputValue);
  }

  return (
    <div className={classes.reasonContainer}>
      <TextAreaContainer>
        <StyledTextArea
          value={inputValue}
          onChange={onChange}
          placeholder={placeholder}
          disabled={disabled}
        />
      </TextAreaContainer>
      <div className={`${classes.buttons}`}>
        <Button
          onClick={() => handleSubmit('reject')}
          variant='danger'
          disabled={disabled}
        >
          {t('reject')}
        </Button>
        <Button
          onClick={() => handleSubmit('accept')}
          disabled={disabled}
        >
          {t('accept')}
        </Button>
      </div>
    </div>
  );
}

ReasonTextArea.propTypes = {
  disabled: PropTypes.bool,
  initialValue: PropTypes.string,
  onSubmit: PropTypes.func,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number
}

ReasonTextArea.defaultProps = {
  disabled: false,
  initialValue: ''
}

export default ReasonTextArea;

