import React from 'react';
import {useTranslation} from 'react-i18next';
import BusinessTradeIcon from '../../components/icons/BusinessTradeIcon';
import PageHeader from '../../components/atoms/Header/PageHeader';
import CompaniesTable from '../../components/pages/CompaniesListPage/CompaniesTable';


const CompaniesListPage = () => {
  const [t] = useTranslation('main');

  return (
    <div className='content-container'>
      <PageHeader title={t('companies')} icon={<BusinessTradeIcon />} />
      <CompaniesTable />
    </div>
  );
}

export default CompaniesListPage;
