import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {makeStyles} from '@mui/styles';
import Feedback from '@h1-card/h1-lib-ui/dist/components/molecules/Feedback';
import Modal from '../../../molecules/Modal';

const useStyles = makeStyles(() => ({
  content: {
    fontSize: 12,
    letterSpacing: '0.5px',
    '& .company-name': {
      fontSize: 14,
      fontWeight: 700
    },
    '& > div': {
      '&:last-of-type': {
        marginTop: 100
      }
    }
  }
}));

const TerminateCompanyModal = ({
  open,
  handleClose,
  handleOk,
  loading,
  details,
  action
}) => {
  const classes = useStyles();
  const [trans] = useTranslation(['kyc', 'main']);

  const isTerminateAction = action === 'terminate';

  const t = (key) => trans(`modal.${action}.${key}`);

  const onClose = () => handleClose && handleClose();

  const onOk = () => handleOk && handleOk(action);

  const {name, id} = details;

  const okButtonText = trans(`main:${isTerminateAction ? 'block' : 'deactivate'}`);
  const okButtonVariant = isTerminateAction ? 'danger' : 'primary';

  return (
    <Modal
      open={open}
      title={t('title')}
      handleClose={onClose}
      okButtonText={okButtonText}
      handleOk={onOk}
      handleCancel={onClose}
      loading={loading}
      okButtonVariant={okButtonVariant}
      content={
        <div className={classes.content}>
          <span className='company-name'>
            {name}
          </span>
          <br/>
          Company ID - {id}
          <Feedback
            message={t('warningMessage')}
            status='warning'
          />
        </div>
      }
    />
  );
}

TerminateCompanyModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  handleOk: PropTypes.func,
  loading: PropTypes.bool,
  details: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  }).isRequired,
  action: PropTypes.oneOf(['terminate', 'deactivate'])
}

TerminateCompanyModal.defaultProps = {
  action: 'terminate',
  loading: false
}

export default TerminateCompanyModal;
