import React from 'react';
import moment from 'moment';
import FileSaver from 'file-saver';
import supportedCountries from './i18n/supported-countries.json';
import {
  alertStatusesConstants,
  H1KYCStatusesConstants,
  H1CompanyStatusesConstants,
  documentTypesLabels
} from './constants';


const defaultStatus = {
  variant: 'warning',
  key: 'undefined'
};

const kycRequestStatuses = {
  [H1KYCStatusesConstants.ACCEPTED]: {
    key: 'kyc:statuses.approved',
    variant: 'success'
  },
  [H1KYCStatusesConstants.SUBMITTED]: {
    key: 'kyc:statuses.submitted',
    variant: 'warning'
  },
  [H1KYCStatusesConstants.PENDING]: {
    key: 'kyc:statuses.pending',
    variant: 'warning'
  },
  [H1KYCStatusesConstants.REJECTED]: {
    key: 'kyc:statuses.rejected',
    variant: 'error'
  },
  [H1KYCStatusesConstants.TREEZOR_SUBMITTED]: {
    key: 'kyc:statuses.submittedTreezor',
    variant: 'submitted'
  },
  [H1KYCStatusesConstants.TREEZOR_REVIEWED]: {
    key: 'kyc:statuses.reviewedTreezor',
    variant: 'submitted'
  }
};

const companyStatuses = {
  [H1CompanyStatusesConstants.ACTIVE]: {
    key: 'main:statusesList.company.active',
    variant: 'success'
  },
  [H1CompanyStatusesConstants.BLOCKED]: {
    key: 'main:statusesList.company.blocked',
    variant: 'error'
  },
  [H1CompanyStatusesConstants.INACTIVE]: {
    key: 'main:statusesList.company.inactive',
    variant: 'warning'
  },
  [H1CompanyStatusesConstants.MISSING_KYC]: {
    key: 'main:statusesList.company.missingKYC',
    variant: 'warning'
  },
  [H1CompanyStatusesConstants.RESET_KYC]: {
    key: 'main:statusesList.company.resetKYC',
    variant: 'warning'
  }
};

const alertStatuses = {
  [alertStatusesConstants.OPEN]: {
    key: 'main:statusesList.alert.open',
    variant: '#00CD7340'
  },
  [alertStatusesConstants.PENDING]: {
    key: 'main:statusesList.alert.pending',
    variant: '#EABD3B40'
  },
  [alertStatusesConstants.RESOLVED]: {
    key: 'main:statusesList.alert.resolved',
    variant: '#C0232340'
  },
};

export const helpers = {
  addLetterByIndex: (value, letter, index) => `${value.substr(0, index)}${letter}${value.substr(index)}`,
  createRows: (rows) => {
    return rows.map((data, key) => {
      return {
        id: data.hasOwnProperty('id') ? data.id : key,
        selected: false,
        disabled: false,
        actionOpen: false,
        data: data
      }
    });
  },
  findStringDifference: (str1, str2) => str2[[...str1].findIndex((el, index) => el !== str2[index])],
  getBase64: (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  }),
  getClassName: (cName, className) => {
    if (className) cName += ` ${className}`;
    return cName;
  },
  getCountryByCode: (code, useDefault = false, defaultKey = 'it') => {
    let country = null;
    let activeCountry = supportedCountries.filter(c => c['ISO3166-1-Alpha-2'].toLowerCase() === code.toLowerCase());
    if (activeCountry.length > 0) {
      country = activeCountry[0]
    } else {
      if (useDefault) {
        let activeCountry = supportedCountries.filter(c => c['ISO3166-1-Alpha-2'].toLowerCase() === defaultKey.toLowerCase());
        if (activeCountry.length > 0) country = activeCountry[0];
      }
    }
    return country;
  },
  getDate: (date, format) => moment(date, 'YYYY-MM-DD').format(format),
  getDateWithMonth: (date) => moment(date, 'YYYY-MM-DD').format('DD MMM YYYY'),
  getInitials: (string, useOneInitialLetter = false) => {
    if (string === null || string === undefined) {
      return '';
    }
    let names = string.split(' '),
      initials = names[0].substring(0, 1).toUpperCase(),
      namesLength = names.length;

    if (namesLength > 1) {
      initials += names[namesLength - 1].substring(0, 1).toUpperCase();
    } else if (namesLength === 1 && !useOneInitialLetter) {
      initials += string.slice(1, string.length - 1).substring(0, 1).toUpperCase()
    }
    return initials;
  },
  getObjProp: (obj, propName, defaultValue = '') => {
    if (obj && obj.hasOwnProperty(propName)) {
      return obj[propName]
    } else {
      return defaultValue
    }
  },
  getSecondsTime: (seconds) => {
    const date = new Date(0);
    date.setSeconds(seconds);
    return moment(date).format('mm:ss');
  },
  getStatus: (statusKey, statusType) => statusType.hasOwnProperty(statusKey) ? statusType[statusKey] : defaultStatus,
  getAlertStatus: (statusKey) => helpers.getStatus(statusKey, alertStatuses),
  getCompanyStatus: (statusKey) => helpers.getStatus(statusKey, companyStatuses),
  getKYCRequestStatus: (statusKey) => helpers.getStatus(statusKey, kycRequestStatuses),
  getCompanyHeaderDetails: (details, getStatusFunc) => ({
    id: details.id,
    name: details.name,
    walletId: details.walletId,
    balance: details.balance,
    status: getStatusFunc(details.status).variant
  }),
  getSumInList: (items, propName) => {
    if (items.length > 1) {
      return items.map(item => item[propName]).reduce((a, b) => Number(a) + Number(b))
    } else if (items.length === 1) {
      return Number(items[0][propName])
    } else {
      return 0
    }
  },
  getSupportedCountries: () => supportedCountries.sort((a, b) => a.name.localeCompare(b.name)),
  getTableData: (data) => {
    const {page, pagination} = data;
    const existNextCursor = pagination && helpers.getObjProp(pagination, 'next_cursor', '') !== '';

    const enableNextPage = page * 10 > data.rows.length ? existNextCursor : true;
    const enablePrevPage = page > 1;

    const rows = data.activeRows.map((data) => ({ id: data.id, data }));
    return {
      enableNextPage,
      enablePrevPage,
      rows
    }
  },
  formatBytes: (kbytes, decimals = 2) => {
    if (!+kbytes) return '0 KB'
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(kbytes) / Math.log(k));

    return `${parseFloat((kbytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  },
  hasExtensions: (filename, extensions) => {
    return (new RegExp('(' + extensions.join('|').replace(/\./g, '\\.') + ')$')).test(filename);
  },
  handleOnPaginate: (direction, data, setData, loadData, perPage = 10) => {
    const {page} = data;
    let nextPage, startIndex;

    const updateActiveRows = (page, startIndex) => {
      const rows = [...data.rows];
      const activeRows = rows.splice(startIndex, perPage);
      setData({
        ...data,
        page,
        activeRows
      });
    }

    if (direction === 'next') {
      nextPage = page + 1;
      startIndex = (nextPage - 1) * perPage;
      if (startIndex >= data.rows.length) {
        if (data.pagination && data.pagination.next_cursor) {
          loadData({cursor: data.pagination.next_cursor});
        }
      } else {
        updateActiveRows(nextPage, startIndex);
      }
    } else if (direction === 'prev') {
      nextPage = page - 1;
      startIndex = nextPage === 1 ? 0 : (nextPage * perPage) - perPage;
      if (page > 1) updateActiveRows(nextPage, startIndex);
    }
  },
  hideCardNumber: (cardNumber) => {
    const len = cardNumber.length;
    const hiddenNum = cardNumber ? cardNumber.substring(len - 4, len) : '';
    return `****${hiddenNum}`
  },
  isNaV: (value) => [null, undefined].includes(value),
  saveFile: async (data, filename, optionsType) => {
    const r = await fetch(`data:${optionsType};base64,${data}`);
    const file = await r.blob();
    FileSaver.saveAs(file, filename);
  },
  getAlertsTableCols: (t) => ([
    {name: t('alert')},
    {
      name: t('company'),
      order: {
        active: false,
        value: 'desc',
        name: 'company_name',
      },
    },
    {
      name: t('date'),
      order: {
        active: true,
        value: 'desc',
        name: 'created_at',
      },
    },
    {name: t('ticket')},
    {
      name: t('status'),
      order: {
        active: false,
        value: 'asc',
        name: 'alert_status',
      },
    },
  ]),
  getUsersTableCols: (t) => ([
    {name: t('user')},
    {name: t('phoneNumber')},
    {name: t('city')},
    {name: t('email')},
    {name: t('type')},
    {name: t('status')},
    {name: 'kyc_level'},
    {name: 'kyc_review'},
    {name: 'kyc_review_comment'},
  ]),
  getCardsTableCols: (t) => ([
    {name: 'masked_pan'},
    {name: 'embossed_name'},
    {name: 'public_token'},
  ]),
  getStatusLabel: (className, name) => (
    <span className={className}>
      {name.toUpperCase()}
    </span>
  ),
  getUploadedFilePayload: async (document, documentTypeId, userId) => {
    const fileContentBase64 = await helpers.getBase64(document);
    let data = {
      document_type_id: documentTypeId,
      access_user_ip: '102.129.65.0',
      name: document.name,
      file_content_base64: fileContentBase64
    }
    if (userId) data = {...data, user_id: userId};
    return data;
  },
  getDocumentTypeLabel: (documentType) => documentTypesLabels[documentType] || 'unknown',
  getKYCInvitePageUrl: (key) => `${process.env.REACT_APP_DESKTOP_LINK}/user-invite/?key=${key}`,
  getUserTypeLabel: (userType, t) => t(`kyc:${userType === 'legal_person' ? 'currentSubmission' : 'personalDetails'}`)
}
