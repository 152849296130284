import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {makeStyles} from '@mui/styles';
import Search from '@h1-card/h1-lib-ui/dist/components/molecules/Search';
import AlertTableRow from '../TableRow';
import TableControlled from '../../../molecules/Table/TableControlled';
import Pagination from '@h1-card/h1-lib-ui/dist/components/molecules/PaginationSimple';
import TabContainer from '../../../molecules/TabContainer';
import {companiesActions} from '../../../../state/actions';
import {helpers} from '../../../../helpers';


const useStyles = makeStyles(() => ({
  table: {
    '& .row-container': {
      borderTop: '2px solid #E9E9E9 !important',
      '& > div': {
        '&:nth-child(2)': {
          maxWidth: 200
        },
        '&:nth-child(3), &:nth-child(5)': {
          maxWidth: 110
        }
      }
    },
    '& > div > div > div:first-of-type': {
      '& .row-container': {
        borderTop: 'unset !important',
        '& > div:first-of-type': {
          '& span': {
            marginLeft: 40
          }
        }
      },
    }
  },
  search: {
    position: 'absolute',
    right: 0,
    top: 6,
    marginBottom: 0
  },
  tabContainer: {
    '& > div': {
      '&:nth-of-type(2)': {
        padding: 0
      }
    }
  }
}));

const minWidth = 700;
const paginationLimit = 20;

const AlertsTable = ({
  getAlertsList
}) => {
  const classes = useStyles();
  const [t] = useTranslation('main');
  const [cols, setCols] = useState([]);
  const [data, setData] = useState({
    rows: [],
    activeRows: [],
    pagination: null,
    page: 1
  });
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState({
    sort_by: 'created_at',
    sort_order: 'desc',
    limit: paginationLimit
  });

  useEffect(() => {
    const cols = helpers.getAlertsTableCols(t);
    setCols(cols);
  }, [t]);

  const finishLoading = () => setLoading(false);

  const loadData = (additionalQuery = null, isInitial = false) => {
    if (!loading) setLoading(true);
    const latestQuery = {...query, ...additionalQuery};
    setQuery(latestQuery);

    getAlertsList(
      latestQuery,
      (response) => {
        const {results, pagination} = response;
        let {page} = data;
        if (!isInitial) page++;
        setData({
          ...response,
          pagination,
          rows: [...data.rows, ...results],
          activeRows: results,
          page
        });
        finishLoading();
      },
      finishLoading
    )
  }

  useEffect(() => {
    loadData(null, true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onOrderChange = (key, order) => {
    const orderQuery = {
      ...query,
      sort_by: key,
      sort_order: order,
      cursor: undefined
    };
    setLoading(true);
    setQuery(orderQuery);

    getAlertsList(
      orderQuery,
      (response) => {
        const {results, pagination} = response;
        setData({
          ...response,
          pagination,
          rows: results,
          activeRows: results,
          page: 1
        });
        finishLoading();
      },
      finishLoading
    )
  }

  const onPaginate = (direction) => helpers.handleOnPaginate(direction, data, setData, loadData, paginationLimit);

  const {enableNextPage, enablePrevPage, rows} = helpers.getTableData(data);

  const tabs = [
    {title: t('all'), component: (
      <div className={classes.table}>
        <TableControlled
          minWidth={minWidth}
          cols={cols}
          rowComponent={AlertTableRow}
          onOrderChange={onOrderChange}
          hasSelectAll={false}
          rows={rows}
          loading={loading}
        />
        <Pagination
          onPageChange={onPaginate}
          enableNextPage={enableNextPage}
          enablePrevPage={enablePrevPage}
          currentPage={data.page}
        />
      </div>
    )},
  ];

  return (
    <div className='table-container'>
      <div className='d-flex justify-end p-relative'>
        <div className={`${classes.search} search-container`}>
          <Search
            delay={500}
            disabled={true}
            placeholder={t('search')}
            size='small'
          />
        </div>
      </div>

      <TabContainer
        tabs={tabs}
        className={classes.tabContainer}
      />

    </div>
  );
}

const mapDispatchToProps = {
  getAlertsList: companiesActions.getAlertsList
}

export default connect(null, mapDispatchToProps)(AlertsTable);
