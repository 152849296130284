const H1CompanyStatusesConstants = Object.freeze({
  // using cards
  ACTIVE: "ACTIVE",
  // deleted
  BLOCKED: "BLOCKED",
  // done something not good and been blocked
  INACTIVE: "INACTIVE",
  // not yet done or finished KYC
  MISSING_KYC: "MISSING_KYC",
  // an admin has reset the KYC (strange / rare)
  RESET_KYC: "RESET_KYC",
});

const H1KYCStatusesConstants = Object.freeze({
  ACCEPTED: 'ACCEPTED',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  SUBMITTED: 'SUBMITTED',
  TREEZOR_REVIEWED: 'TREEZOR_REVIEWED',
  TREEZOR_SUBMITTED: 'TREEZOR_SUBMITTED'
});

const H1ReviewStatusesConstants = Object.freeze({
  ACCEPTED: 'ACCEPTED',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  SUBMITTED: 'SUBMITTED'
});

const TZKYCLevelLabels = Object.freeze({
  0: 'NONE',
  1: 'LIGHT',
  2: 'REGULAR',
  3: 'STRONG',
  4: 'REFUSED',
});

const TZKYCReviewStatusesConstants = Object.freeze({
  NONE: 0,
  PENDING: 1,
  VALIDATED: 2,
  REFUSED: 3,
  INVESTIGATING: 4,
  CLOSED: 5
});

const userTypesConstants = Object.freeze({
  LEGAL_PERSON: 'legal_person',
  LEGAL_REPRESENTATIVE: 'legal_representative',
  UBO: 'ultimate_beneficiary_owner',
  EMPLOYEE: 'employee',
});

const userTypesLabels = Object.freeze({
  'legal_person': 'Company',
  'legal_representative': 'LR',
  'ultimate_beneficiary_owner': 'UBO',
  'employee': 'employee',
});

const documentTypeStatusesConstants = Object.freeze({
  POLICE_RECORD: 2,
  COMPANY_REGISTRATION: 4,
  CV: 6,
  SWORN_STATEMENT: 7,
  TURNOVER: 8,
  IDENTITY_CARD: 9,
  BANK_IDENTITY_STATEMENT: 11,
  PROOF_OF_ADDRESS: 12,
  MOBILE_PHONE_INVOICE: 13,
  INVOICE: 14,
  RESIDENCE_PERMIT: 15,
  DRIVING_LICENCE: 16,
  PASSPORT: 17,
  PROXY_GRANTING_EMPLOYEE: 18,
  COMPANY_REGISTRATION_OFFICIAL_PAPER: 19,
  OFFICIAL_TAX_CERTIFICATE: 20,
  EMPLOYEE_EMPLOYMENT_NOTICE: 21,
  USER_BANK_STATEMENT: 22,
  BUSINESS_LEGAL_STATUS: 23,
  TAX_STATEMENT: 24,
  EXEMPTION_STATEMENT: 25,
  LIVENESS_RESULTS: 26,
  HEALTH_INSURANCE_CARD: 27
});

const documentTypesLabels = Object.freeze({
  [documentTypeStatusesConstants.POLICE_RECORD]: 'Police Record',
  [documentTypeStatusesConstants.COMPANY_REGISTRATION]: 'Company Registration',
  [documentTypeStatusesConstants.CV]: 'CV',
  [documentTypeStatusesConstants.SWORN_STATEMENT]: 'Sworn Statement',
  [documentTypeStatusesConstants.TURNOVER]: 'Turnover',
  [documentTypeStatusesConstants.IDENTITY_CARD]: 'Identity Card',
  [documentTypeStatusesConstants.BANK_IDENTITY_STATEMENT]: 'Company Registration',
  [documentTypeStatusesConstants.PROOF_OF_ADDRESS]: 'Proof Of Address',
  [documentTypeStatusesConstants.MOBILE_PHONE_INVOICE]: 'Mobile Phone Invoice',
  [documentTypeStatusesConstants.INVOICE]: 'Invoice',
  [documentTypeStatusesConstants.RESIDENCE_PERMIT]: 'Residence Permit',
  [documentTypeStatusesConstants.DRIVING_LICENCE]: 'Driving Licence',
  [documentTypeStatusesConstants.PASSPORT]: 'Passport',
  [documentTypeStatusesConstants.PROXY_GRANTING_EMPLOYEE]: 'Proxy Granting Employee',
  [documentTypeStatusesConstants.COMPANY_REGISTRATION_OFFICIAL_PAPER]: 'Company Registration Official Paper',
  [documentTypeStatusesConstants.OFFICIAL_TAX_CERTIFICATE]: 'Official Tax Certificate',
  [documentTypeStatusesConstants.EMPLOYEE_EMPLOYMENT_NOTICE]: 'Employee Employment Notice',
  [documentTypeStatusesConstants.USER_BANK_STATEMENT]: 'User Bank Statement',
  [documentTypeStatusesConstants.BUSINESS_LEGAL_STATUS]: 'Business Legal Status',
  [documentTypeStatusesConstants.TAX_STATEMENT]: 'Tax Statement',
  [documentTypeStatusesConstants.EXEMPTION_STATEMENT]: 'Exemption Statement',
  [documentTypeStatusesConstants.LIVENESS_RESULTS]: 'Liveness Results',
  [documentTypeStatusesConstants.HEALTH_INSURANCE_CARD]: 'Health Insurance Card',
});

export {
  H1CompanyStatusesConstants,
  H1KYCStatusesConstants,
  TZKYCLevelLabels,
  TZKYCReviewStatusesConstants,
  H1ReviewStatusesConstants,
  userTypesConstants,
  userTypesLabels,
  documentTypeStatusesConstants,
  documentTypesLabels
};
