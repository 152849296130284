import {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Router, Switch} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {ThemeProvider} from 'styled-components';
import {makeStyles} from '@mui/styles';
import {Toaster} from 'react-hot-toast';
import SpinnerImage from '@h1-card/h1-lib-ui/dist/components/atoms/SpinnerImage';
import './App.css';
import theme from '../theme';
import {history} from '../state/history';
import RouteWithTitle from '../components/molecules/RouteWithTitle';
import LoginPage from '../pages/LoginPage';
import {userActions} from '../state/actions';
import {localStorageKeysConstants} from '../constants';
import AuthPages from '../components/organism/AuthPages';
import NotFoundPage from '../pages/NotFoundPage';

const useStyles = makeStyles(() => ({
  loader: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    '& > div': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    zIndex: 1000,
  },
  initialLoader: {
    backgroundColor: '#fff',
    zIndex: 1001,
    opacity: '1',
  },
  hideLoader: {
    display: 'none',
  },
  hideInitialLoader: {
    zIndex: -1,
    transition: '.3s ease',
    opacity: '0'
  },
}));

const App = ({
  initialLoading,
  isAuthenticated,
  loading,
  getUserProfile,
  markedCheckedAuth
}) => {
  const classes = useStyles();
  const [isAuth, setIsAuth] = useState(false);
  const [t] = useTranslation('main');

  useEffect(() => {
    setIsAuth(isAuthenticated)
  }, [isAuthenticated]);

  useEffect(() => {
    if (localStorage.getItem(localStorageKeysConstants.TOKEN)) {
      getUserProfile();
    } else {
      markedCheckedAuth();
    }
  }, [getUserProfile, markedCheckedAuth]); // eslint-disable-line react-hooks/exhaustive-deps

  const getPageTitle = (key) => {
    const pageTitle = t(`pageTitles.${key}`);
    const defaultTitle = t('pageTitles.defaultTitle');
    const title = `${pageTitle} | ${defaultTitle}`;
    if (initialLoading) {
      return defaultTitle;
    } else {
      return isAuthenticated ? title : defaultTitle;
    }
  };

  const hideMainLoaded = isAuthenticated || !initialLoading;

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Router history={history}>
          {!isAuth && (
            <Switch>
              <RouteWithTitle exact path='/' component={LoginPage} title={getPageTitle('login')} />
              <RouteWithTitle
                path='*'
                key='not-found'
                title={getPageTitle('notFound')}
              >
                <NotFoundPage />
              </RouteWithTitle>
            </Switch>
          )}

          {isAuth && <AuthPages getPageTitle={getPageTitle} />}

          <Toaster position='bottom-right' />
        </Router>

        <div className={`${classes.loader} ${!loading && classes.hideLoader}`}>
          <SpinnerImage />
        </div>

        <div className={`${classes.loader} ${classes.initialLoader} ${hideMainLoaded && classes.hideInitialLoader}`}>
          <SpinnerImage />
        </div>

      </ThemeProvider>
    </div>
  )
}

const mapStateToProps = state => {
  const {main, user} = state;
  const {initialLoading, loading} = main;
  const {isAuth} = user;
  return {
    initialLoading,
    loading,
    isAuthenticated: isAuth
  }
}

const mapDispatchToProps = {
  getUserProfile: userActions.getUserProfile,
  markedCheckedAuth: userActions.markedCheckedAuth,
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
