import {bankingRequest, getMethodTemplate, putMethodTemplate, deleteMethodTemplate} from '../services/request';


const getRequest = (url, query, success, error, props) => getMethodTemplate(bankingRequest, url, query, success, error, props);

const putRequest = (url, data, success, error) => putMethodTemplate(bankingRequest, url, data, success, error);

const deleteRequest = (url, success, error) => deleteMethodTemplate(bankingRequest, url, success, error);

const getCompaniesList = (query = null, success, error) => getCompanyTemplate(null, query, success, error);

const getSubmissionsList = (query = null, success, error) => getCompanyTemplate('submissions', query, success, error);

const getCompanyDetails = (success, error) => getCompanyTemplate('current', null, success, error, {disableAlert: true});

const getCompanyWallet = (success, error) => getCompanyTemplate('wallet', null, success, error, {disableAlert: true});

const getTransactionsList = (success, error) => getRequest('transactions', null, success, error);

const getCompanyCards = (success, error) => getRequest('cards', null, success, error);

const getAlertsList = (query, success, error) => getRequest('alerts', query, success, error);

const getCompanyUsers = (query, success, error) => getRequest('users', query, success, error);

const getCompanyTemplate = (url, query, success, error, props) => getRequest(getUrl(url), query, success, error, props);

const changeCompanyStatus = (status, success, error) => putRequest(getUrl(status), null, success, error);

const deleteCompany = (success, error) => deleteRequest(getUrl('delete'), success, error);

const reviewCompanyKYC = (reviewType, success, error) => {
  if (!['approve', 'reject', 'request'].includes(reviewType)) return ;
  return putRequest(getUrl(`${reviewType}-kyc`), null, success, error);
}

const getUrl = (url) => url ? `companies/${url}` : 'companies';

export const companiesActions = {
  getCompanyDetails,
  getSubmissionsList,
  getCompanyWallet,
  getCompanyCards,
  getTransactionsList,
  getCompaniesList,
  getCompanyUsers,
  getAlertsList,
  changeCompanyStatus,
  reviewCompanyKYC,
  deleteCompany
};
