import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {makeStyles} from '@mui/styles';
import {history} from '../../../state/history';
import Logo from '../../../static/images/H1_LogoDarkblue.png';
import theme from '../../../theme';

const {secondary_text: textColor} = theme.colors;

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: 'rgba(233, 233, 233, 0.3)',
    minHeight: '100vh',
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflowY: 'hidden',
  },
  header: {
    padding: '20px 26px 24px 16px',
    color: '#000',
    fontSize: 14,
    lineHeight: '12px',
    fontWeight: 500,
    '& a': {
      marginRight: 12
    }
  },
  list: {
    padding: '0 16px 10px 16px',
    '& .list-item': {
      display: 'flex',
      alignItems: 'center',
      color: '#000',
      textDecoration: 'none',
      padding: '14px 16px',
      borderRadius: 4,
      transition: '0.3s ease',
      marginBottom: 4,
      fontSize: 14,
      fontWeight: 600,
      position: 'relative'
    },
    '& .list-item:hover': {
      backgroundColor: 'rgba(10, 20, 90, 0.1)',
      cursor: 'pointer'
    },
    '& .list-item.active': {
      backgroundColor: 'rgba(10, 20, 90, 0.1)',
    },
    '& svg': {
      display: 'flex',
      marginRight: 12,
    }
  },
  linkIcon: {
    '& svg': {
      width: 20,
      height: 20,
    },
  },
  logo: {
    height: 31,
    '& img': {
      height: 31
    }
  }
}));

const Navigation = ({
  items
}) => {
  const [t] = useTranslation('main');
  const {pathname} = history.location;
  const [activeKey, setActiveKey] = useState(0);
  const classes = useStyles();

  useEffect(() => {
    if (pathname !== '/') {
      setActiveKey(items.findIndex(el => el.path === pathname));
    } else {
      setActiveKey(0);
    }
  }, [pathname, items]);

  useEffect(() => {
    if (history.action.toLowerCase() === 'replace') {
      setActiveKey(items.findIndex(el => el.path === pathname));
    }
  }, [setActiveKey, pathname, items]);

  return (
    <div className={classes.container}>
      <div>
        <div className={`${classes.header} d-flex align-items-end`}>
          <Link to='/' className={classes.logo}>
            <img src={Logo} alt='H1 Card logo' />
          </Link>
          {t('internal')}
        </div>
        <div className={classes.list}>
          {items.map((item, key) => {
            const {icon, title, path} = item;
            const iconElem = React.createElement(icon, {color: textColor});
            return (
              <Link
                key={key}
                to={path}
                onClick={() => setActiveKey(key)}
                className={`list-item ${key === activeKey ? 'active' : ''}`}>
                  <span className={classes.linkIcon}>
                    {iconElem}
                  </span>
                  {title}
              </Link>
            )
          })}
        </div>
      </div>
    </div>
  );
}

Navigation.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string || null,
      icon: PropTypes.func.isRequired
    })
  ).isRequired
}

export default Navigation;
