import styled from 'styled-components';
import {getValueFromThemeByProp, pL, pY} from '@h1-card/h1-lib-ui/dist/utils';

const getFlex = ({
  auto,
  span,
  flexBasisWidth
}) => {
  const isWidth = flexBasisWidth !== null && flexBasisWidth !== undefined;
  let flexBasis = 0;
  if (isWidth) flexBasis = typeof (flexBasisWidth) === "string" ? flexBasisWidth : `${flexBasisWidth}px`;

  if (auto) {
    return `
        flex: 0 1 auto;
        white-space:nowrap;
    `;
  }

  if (span) {
    return `
      flex-basis: 0;
      flex-grow: ${span};
    `;
  }

  return `
    flex-grow: 1;
    flex-basis: ${flexBasis};
  `;
};

const getColumnContentAlign = ({
  halign
}) => {
  const alings = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end'
  };
  return `
    justify-content: ${alings[halign]};
  `;
};

const StyledTableCol = styled.div`
  display: flex;
  flex-flow: row nowrap;

  word-break: break-word;
  text-overflow: ellipsis;
  min-width: 0px;
  align-items: center;

  ${props => pL(getValueFromThemeByProp({
  theme: props.theme,
  prop: 'spacing',
  value: 'large'
}))}
    ${props => pY(getValueFromThemeByProp({
  theme: props.theme,
  prop: 'spacing',
  value: 'large'
}))}
  ${props => getFlex(props)}

  ${props => getColumnContentAlign(props)}

`;
export default StyledTableCol;
