import React from 'react';
import {makeStyles} from '@mui/styles';
import PropTypes from 'prop-types';


const useStyles = makeStyles(() => ({
  alert: {
    padding: 22,
    color: props => props.textColor,
    backgroundColor: props => props.backgroundColor,
    fontWeight: 600,
    lineHeight: '28px',
    fontSize: 20
  }
}));

const Alert = ({
  backgroundColor,
  textColor,
  message
}) => {
  const classes = useStyles({backgroundColor, textColor});

  return (
    <div className={`${classes.alert} mulish-font`}>
      {message}
    </div>
  );
}

Alert.propTypes = {
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  message: PropTypes.string,
}

Alert.defaultProps = {
  backgroundColor: '#03AB9340',
  textColor: '#191927',
  message: '',
}

export default Alert;

