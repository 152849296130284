import React from 'react';
import {makeStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import Feedback from '../Feedback';

const useStyles = makeStyles(() => ({
  snackbar: {
    maxWidth: 448,
    minWidth: 350
  },
}));

const Snackbar = ({ message, variant, onClose }) => {
  const classes = useStyles();

  return (
    <div className={classes.snackbar}>
      <Feedback
        message={message || ''}
        status={variant}
        closable={true}
        onFeedbackClose={() => onClose && onClose()}
      />
    </div>
  )
}

Snackbar.propTypes = {
  /** The message to show */
  message: PropTypes.string,
  /** The variant of the Feedback */
  variant: PropTypes.oneOf(['error', 'success', 'warning']),
}

export default Snackbar;
