import React from 'react';
import PropTypes from 'prop-types';
import {Modal as MaterialModal} from '@mui/material';
import {makeStyles} from '@mui/styles';
import Button from '@h1-card/h1-lib-ui/dist/components/atoms/Button';
import ListGroup from '@h1-card/h1-lib-ui/dist/components/atoms/ListGroup';
import SpinnerImage from '@h1-card/h1-lib-ui/dist/components/atoms/SpinnerImage';
import H1Modal from '../H1Modal';
import useOnClickOutside from '../../../hooks/useOnClickOutside';

const maxHeight = 'calc(100vh - 190px)';

const useStyles = makeStyles(() => ({
  container: {
    minHeight: '100vh',
    minWidth: '100%',
    display: 'flex',
    outline: 'none'
  },
  content: {
    margin: 'auto',
    width: props => props.width,
    position: 'relative',
    outline: 'none',
    '& > div': {
      borderRadius: 4,
      outline: 'none',
    },
    '& .modal-body': {
      padding: props => props.contentPadding,
      minHeight: props => {
        const mHeight = props.maxHeight;
        return mHeight > 220 || mHeight === props.maxComponentHeight ? 220 : mHeight;
      },
      height: props => props.height,
      maxHeight: props => props.maxHeight,
      width: 'auto',
      overflow: props => props.overflow,
    },
    '& header': {
      backgroundColor: props => props.headerBgColor,
    },
    '& footer': {
      width: 'unset',
      '& button': {
        minWidth: 160,
        height: 40,
      },
    },
  },
  rightSidePosition: {
    margin: 'unset',
    marginLeft: 'auto',
    '& > div': {
      borderRadius: 0,
    },
    '& .modal-body': {
      boxShadow: 'unset',
    },
    '& header': {
      flex: '0 0 55px',
      borderBottom: '2px solid #E9E9E9',
      '& span': {
        margin: 'auto',
      }
    },
  },
  modalLoader: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    '& > div': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100% !important',
      maxHeight: 'unset !important'
    },
  },
  hideLoader: {
    display: 'none',
  },
  materialModal: {
    '& div[aria-hidden="true"]': {
      backgroundColor: 'rgba(0, 0, 0, 0.4) !important',
    }
  }
}));

const Modal = ({
  open,
  handleClose,
  handleOk,
  handleCancel,
  loading,
  okButtonText,
  okButtonVariant,
  cancelButtonText,
  title,
  rightSidePosition,
  hideOkButton,
  hideCancelButton,
  content,
  maxHeight,
  contentPadding,
  headerBgColor,
  width,
  height,
  hideCloseIcon,
  blurBackground,
  hideFooter,
  overflow,
  disableOkButton,
  presentationClass,
  additionalButtons,
  enableClickOutside,
  showPrevIcon,
  handleOnPrevClick,
  ...rest
}) => {
  const classes = useStyles({
    maxHeight,
    contentPadding,
    headerBgColor,
    width,
    height,
    overflow,
    maxComponentHeight: `calc(100vh - ${rightSidePosition ? 220 : 190}px)`,
    showPrevIcon
  });

  const onOkClick = () => {
    if (handleOk) handleOk();
  }

  const onCancelClick = () => {
    if (handleCancel) handleCancel();
  }

  const footer = () => {
    const items = [];
    if (!hideOkButton) {
      items.unshift(
        <Button
          disabled={disableOkButton || loading}
          size='small'
          variant={okButtonVariant}
          data-testid='ok'
          onClick={() => onOkClick()}>
          {okButtonText}
        </Button>
      )
    }

    if (additionalButtons) {
      additionalButtons.forEach(button => items.unshift(button));
    }

    if (!hideCancelButton) {
      items.unshift(
        <Button
          className='outlined-button'
          disabled={loading}
          size='small'
          onClick={() => onCancelClick()}
          data-testid='cancel'
          variant='outlined'>
          {cancelButtonText}
        </Button>
      )
    }
    return (
      <ListGroup
        direction='row'
        halign='left'
        items={items}
        reverse={false}
        size='small' />
    )
  }

  let className = classes.materialModal;
  if (presentationClass) className += ` ${presentationClass}`;

  const onClose = (e) => {
    e.stopPropagation();
    handleClose && handleClose();
  }

  const ref = useOnClickOutside(onClose, open, '.modal-parent')

  return (
    <MaterialModal
      open={open}
      onClose={handleClose}
      className={className}
      disableEscapeKeyDown={true}
    >
      <div className={`modal-content ${classes.container} ${blurBackground ? 'blur-bg' : ''}`}>
        <div className={`modal-parent ${classes.content} ${rightSidePosition ? `${classes.rightSidePosition}` : ''}`}
             ref={enableClickOutside ? ref : null}
             data-test='container'>
          <H1Modal
            footer={hideFooter ? null : footer()}
            handleClose={onClose}
            title={title}
            variant='standard'
            hideCloseIcon={hideCloseIcon}
            showPrevIcon={showPrevIcon}
            handleOnPrevClick={() => handleOnPrevClick && handleOnPrevClick()}
            rightSidePosition={rightSidePosition}
            {...rest}
          >
            {content || null}
          </H1Modal>
          <div className={`modal-spinner ${classes.modalLoader} ${!loading ? classes.hideLoader : ''}`}>
            <SpinnerImage size='small' />
          </div>
        </div>
      </div>
     </MaterialModal>
  );
}

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleCancel: PropTypes.func,
  handleOk: PropTypes.func,
  cancelButtonText: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  content: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  loading: PropTypes.bool,
  okButtonText: PropTypes.string,
  okButtonVariant: PropTypes.oneOf(['primary', 'standard', 'outlined', 'subtle', 'danger']),
  hideOkButton: PropTypes.bool,
  hideCancelButton: PropTypes.bool,
  disableOkButton: PropTypes.bool,
  maxHeight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  contentPadding: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  headerBgColor: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  hideCloseIcon: PropTypes.bool,
  blurBackground: PropTypes.bool,
  hideFooter: PropTypes.bool,
  overflow: PropTypes.string,
  presentationClass: PropTypes.string,
  additionalButtons: PropTypes.array,
  enableClickOutside: PropTypes.bool,
  showPrevIcon: PropTypes.bool,
  handleOnPrevClick: PropTypes.func
}

Modal.defaultProps = {
  disableOkButton: false,
  hideOkButton: false,
  hideCancelButton: false,
  loading: false,
  okButtonText: 'OK',
  okButtonVariant: 'standard',
  cancelButtonText: 'Cancel',
  title: '',
  rightSidePosition: false,
  maxHeight: maxHeight,
  contentPadding: '32px',
  headerBgColor: 'transparent',
  width: 512,
  height: 'unset',
  hideCloseIcon: false,
  blurBackground: false,
  hideFooter: false,
  overflow: 'hidden auto',
  enableClickOutside: true
}
export default Modal;
