import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@mui/styles';
import parse from 'html-react-parser';
import Checkbox from '@h1-card/h1-lib-ui/dist/components/atoms/Checkbox';
import Input from '@h1-card/h1-lib-ui/dist/components/atoms/Input';
import TextArea from '@h1-card/h1-lib-ui/dist/components/atoms/TextArea';
import FormItem from '@h1-card/h1-lib-ui/dist/components/molecules/FormItem';
import {helpers} from '../../../helpers';
import theme from '../../../theme';

const {secondary} = theme.colors;

const useStyles = makeStyles(() => ({
  form: {
    '& input': {
      borderWidth: 1,
      minHeight: 37
    },
    '& textarea': {
      width: 'calc(100% - 32px)',
      paddingTop: 12,
      borderWidth: 1,
      minHeight: 'calc(98px - 12px - 16px)',
      transition: 'all 0.2s ease-in-out 0s',
      '&:focus': {
        borderColor: secondary,
        boxShadow: '0 2px 6px 0 rgb(25 25 39 / 25%)'
      }
    },
    '& .MuiTextField-root': {
      maxHeight: 44,
      width: '100%',
      '& > div': {
        maxHeight: 44
      }
    },
    '& .error-message': {
      paddingTop: 2,
      lineHeight: '16px'
    },
    '& .has-error': {
      marginBottom: 2
    },
    '& label': {
      color: '#191927'
    },
  },
}));

const defaultValid = {isValid: true, message: null};

const getFloatValue = (value) => {
  let floatValue = parseFloat(value);
  let rounded = parseFloat(value).toFixed(2);
  value = rounded.length < value.length && String(floatValue) !== rounded ? rounded : value;
  return value;
}

const FormField = ({
  item,
  handleUpdateFormState,
  errors,
  defaultValue,
  fieldsValidation,
  error,
  validationRules
}) => {
  const [t] = useTranslation('main');
  let inputField;
  let {className} = item;
  const {type, name, fieldType, onChange, additionalProps, label, preFieldContent} = item;
  const checkValidation = helpers.getObjProp(fieldsValidation, name, {});
  let hasError = !helpers.getObjProp(checkValidation, 'isValid', true) || error !== null;
  let infoMessage = checkValidation.message;
  const [value, setValue] = useState(defaultValue);

  useEffect(() => setValue(defaultValue), [defaultValue]);

  if (errors) {
    infoMessage = helpers.getObjProp(errors, name, '');
    hasError = infoMessage !== '';
  }

  if (hasError) className += ` has-error`;


  const handleOnChange = (e) => {
    let value;
    if (typeof (e) !== 'string' && e.hasOwnProperty('target')) {
      value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    } else {
      value = e;
    }

    // find max length rule & update state if value is less than max length
    if (validationRules) {
      let maxLengthRule = validationRules.find(e => e.name === 'maxLength');
      if (maxLengthRule && maxLengthRule.value < value.length) {
        return;
      }
    }

    setValue(value);
    handleUpdateFormState(e, value, name, onChange, fieldType);
  }

  const onFloatKeyDown = (e) => {
    const {keyCode, key, ctrlKey} = e;
    if (![8, 9].includes(keyCode) && key.match(/[^$,.\d]/) && !ctrlKey) {
      e.preventDefault();
    }
  }

  const onFloatPaste = (e) => {
    e.currentTarget.value = e.clipboardData.getData('Text').replace(/[^\d.-]/g, '');
    e.preventDefault();
  }

  switch (type) {
    case 'textarea':
      inputField = (
        <TextArea
          onChange={handleOnChange}
          size='small'
          initialValue={value}
          name={name}
          error={hasError}
          {...additionalProps}
        />
      );
      break;
    case 'checkbox':
      inputField = (
        <div className={`checkbox ${value ? 'checked' : ''}`}>
          <Checkbox
            onChange={handleOnChange}
            name={name}
            checked={value}
            {...additionalProps}
          >
            {label}
          </Checkbox>
        </div>
      );
      break;
    default:
      const isFloat = fieldType === 'float';
      inputField = (
        <Input
          onChange={handleOnChange}
          size='small'
          type={fieldType ? isFloat ? 'number' : fieldType : 'text'}
          value={value}
          name={name}
          error={hasError}
          onKeyDown={isFloat ? onFloatKeyDown : null}
          onPaste={isFloat ? onFloatPaste : null}
          {...additionalProps}
          autoComplete='new-password'
        />
      );
  }

  let preFieldContentComponent = null, preFieldContentProps = {};
  if (preFieldContent && typeof preFieldContent === 'object') {
    if (type === 'checkbox') {
      preFieldContentProps = {
        ...preFieldContentProps,
        onClick: () => handleOnChange({target: {type: 'checkbox', checked: !value}})
      }
    }
    preFieldContentComponent = React.cloneElement(preFieldContent, preFieldContentProps);
  }

  return (
    <>
      {preFieldContentComponent}
      <FormItem
        key={name}
        label={item.type !== 'checkbox' ? item.label : ''}
        variant='dark'
        className={className}
      >
        {inputField}
        {infoMessage && <div className='error-message'>{parse(infoMessage)}</div>}
        {error && <span className='error-message'>{t(`validation.${error}`)}</span>}
      </FormItem>
    </>
  )
};

let fieldsCopy = {};

const Form = React.forwardRef(({
  items,
  onSubmit,
  onError,
  onFormItemChange,
  error,
  formErrors,
  usePrevValidState,
  clearUpdatedFormValidation,
  className,
  id,
  ...rest
}, ref) => {
  const classes = useStyles();
  const [isValid, setIsValid] = useState(true);
  const [fields, setFields] = useState({});
  const [fieldsValidation, setFieldsValidation] = useState({});
  const [fieldsValidationRules, setFieldsValidationRules] = useState({});
  const [savedFormErrors, setSavedFormErrors] = useState(null);

  let cName = helpers.getClassName(classes.form, className);

  useEffect(() => {
    if (!isValid) validation();
  }, [fields]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSavedFormErrors(formErrors);
  }, [formErrors]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const valid = validation();
    if (!valid) {
      onError && onError();
      return;
    }
    if (onSubmit) onSubmit(fields);
  }

  const validation = () => {
    const fieldsValidationCurrent = {};
    let isValidCurrent = true;
    if (savedFormErrors !== null && !helpers.isEmptyObject(savedFormErrors)) {
      const fieldsNames = Object.keys(fields);
      const errorsFieldsName = Object.keys(savedFormErrors);
      isValidCurrent = !errorsFieldsName.some(i => fieldsNames.includes(i));
    }
    items.forEach(i => {
      const {name} = i;
      let validField = checkFieldValidation(name);
      fieldsValidationCurrent[name] = validField;
      if (!validField.isValid && isValidCurrent) isValidCurrent = false;
    });

    if (JSON.stringify(fieldsValidationCurrent) !== JSON.stringify(fieldsValidation)) {
      setFieldsValidation(fieldsValidationCurrent);
    }
    if (isValid !== isValidCurrent) setIsValid(isValidCurrent);

    return isValidCurrent;
  }

  useEffect(() => {
    const itemsFields = {};
    const fValidation = {};
    const fieldRules = {};

    // set default fields value, field rules & valid field props
    items.forEach(i => {
      const {rules, name, type, defaultValue} = i;
      const isValid = type === 'select' ? defaultValue === null : usePrevValidState && defaultValue !== null && defaultValue.length === 0;
      itemsFields[name] = defaultValue;
      fValidation[name] = isValid ? helpers.getObjProp(fieldsValidation, name, defaultValid) : defaultValid;
      if (rules) fieldRules[name] = rules;
    });
    setFields(itemsFields);
    if (id) {
      fieldsCopy[id] = itemsFields
    } else {
      fieldsCopy = itemsFields;
    }
    setFieldsValidationRules(fieldRules);
    if (clearUpdatedFormValidation) setFieldsValidation(fValidation);
  }, [items, usePrevValidState]); // eslint-disable-line react-hooks/exhaustive-deps

  const updateFormStateValue = (e, value, propName, onChange, fieldType) => {
    const isFloat = fieldType === 'float';
    if (isFloat) value = getFloatValue(value);

    onChange && onChange(isFloat ? value : e);
    if (id) {
      const fields = {
        ...fieldsCopy[id],
        [propName]: value
      };
      fieldsCopy = {
        ...fieldsCopy,
        [id]: fields
      }
      setFields(fields);
      onFormItemChange && onFormItemChange(value, propName, fields);
    } else {
      fieldsCopy = {
        ...fieldsCopy,
        [propName]: value
      }
      setFields(fieldsCopy);
      onFormItemChange && onFormItemChange(value, propName, fieldsCopy);
    }

    if (savedFormErrors && savedFormErrors.hasOwnProperty(propName)) {
      const cloneErrors = {...savedFormErrors};
      delete cloneErrors[propName];
      setSavedFormErrors(cloneErrors);
    }
  };

  // check validation rules
  const checkFieldValidation = (name) => {
    const rules = fieldsValidationRules[name] || false;
    let isValid = true;
    let message = '';
    if (rules) {
      let shouldSkip = false;
      rules.forEach(rule => {
        if (shouldSkip) return;
        const ruleValue = rule.value;
        const fieldValue = fields[name];
        const propValue = helpers.isNaV(fieldValue) ? '' : String(fieldValue);
        const valueLength = propValue.length;
        switch (rule.name) {
          case 'required':
            isValid = valueLength > 0;
            break;
          case 'minLength':
            isValid = valueLength >= ruleValue;
            break;
          case 'maxLength':
            isValid = valueLength <= ruleValue;
            break;
          case 'regex':
            isValid = ruleValue.test(propValue);
            break;
          case 'custom':
            isValid = ruleValue(propValue);
            break;
          default:
            break;
        }

        // if rule is not valid break from loop
        if (!isValid) {
          message = rule.message;
          shouldSkip = true;
        }
      })
    }
    return {isValid, message};
  }

  const formFields = useMemo(() => items.map((item, i) => {
    const {name} = item;
    return (
      <FormField
        key={i}
        item={item}
        handleUpdateFormState={(e, value, propName, onChange, fieldType) => updateFormStateValue(e, value, propName, onChange, fieldType)}
        errors={savedFormErrors}
        defaultValue={fields[name]}
        fieldsValidation={fieldsValidation}
        error={error}
        validationRules={fieldsValidationRules[name]}
      />
    )
  }), [items, fieldsValidation, savedFormErrors, error]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <form
      onSubmit={(e) => handleSubmit(e)}
      className={cName}
      {...rest}>
      {formFields}
      {/*{Object.keys(fields).length > 0 ? formFields : null}*/}
      <input type='submit' className='d-none' ref={ref} />
    </form>
  )
});

Form.propTypes = {
  /* onSubmit event */
  onSubmit: PropTypes.func,

  /* Form error handle */
  onError: PropTypes.func,

  /* onFormItemChange event */
  onFormItemChange: PropTypes.func,

  /* use previous validation state */
  usePrevValidState: PropTypes.bool,

  id: PropTypes.string,

  /* form fields list */
  items: PropTypes.arrayOf(PropTypes.shape({
    /* type of item component */
    type: PropTypes.oneOf(['input', 'textarea', 'checkbox']).isRequired,

    /* component name */
    name: PropTypes.string.isRequired,

    /* list of option items for select or multiple select */
    options: PropTypes.array,

    /* validation rules */
    rules: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.any.isRequired,
        message: PropTypes.string
      })
    ),

    /* field type name */
    fieldType: PropTypes.oneOf(['text', 'email', 'hidden', 'password', 'number', 'float']),

    /* placeholder */
    placeholder: PropTypes.string,

    /* field label */
    label: PropTypes.string.isRequired,

    /* default value */
    defaultValue: PropTypes.any,

    /* validation is required if true */
    isRequired: PropTypes.bool,

    /* for multiple select, additional button on the bottom */
    additionalButton: PropTypes.shape({
      /* button text */
      title: PropTypes.string,

      /* button icon */
      icon: PropTypes.string,

      /* onClick action */
      onClick: PropTypes.func,
    }),

    /* onChange action */
    onChange: PropTypes.func,

    /* Error message */
    error: PropTypes.string,
    clearUpdatedFormValidation: PropTypes.bool,
    preFieldContent: PropTypes.any
  })),
};

Form.defaultProps = {
  error: null,
  usePrevValidState: false,
  clearUpdatedFormValidation: true,
  formErrors: null
}

export default Form;
