import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@mui/styles';
import Input from '@h1-card/h1-lib-ui/dist/components/atoms/Input';
import {helpers} from '../../../helpers';


const useStyles = makeStyles(theme => ({
  inputs: {
    marginBottom: '0 !important',
    '& > div': {
      flex: 'unset',
      '&:not(:last-of-type)': {
        marginRight: 16,
        [theme.breakpoints.down('sm')]: {
          marginRight: 12,
        },
      },
    },

    '& input': {
      height: 48,
      width: 16,
      padding: '0 16px',
      fontSize: '1.75rem',
      fontWeight: 400,
      border: '2px solid #808080',
      textAlign: 'center',
      '&:hover, &:focus': {
        boxShadow: 'unset',
        borderColor: '#808080',
        borderWidth: '2px !important',
      },

      '&.active': {
        borderColor: '#03AB93',
        '&:hover, &:focus': {
          borderColor: '#03AB93',
        }
      },

      '&.not-valid': {
        borderColor: 'rgb(255,59,48)',
        '&:hover, &:focus': {
          borderColor: 'rgb(255,59,48)',
        }
      },

      [theme.breakpoints.down('sm')]: {
        height: 40,
        fontSize: '1.5rem',
        padding: '0 12px',
        width: 16,
      },
    },
  },
  focus: {
    '& input': {
      borderColor: '#03AB93',
      boxShadow: '0 2px 6px 0 rgb(25 25 39 / 25%) !important',
      '&:hover, &:focus': {
        borderColor: '#03AB93',
      }
    }
  },
  reload: {
    color: '#343536',
    fontWeight: 500,
    paddingTop: '1rem'
  },
  link: {
    marginRight: '1rem',
    textDecoration: 'underline',
  },
}));


let interval = null;

const getNumObject = (count, value) => {
  let obj = {};
  [...Array(count).keys()].forEach(i => {
    obj[i] = value;
  });
  return obj;
}

const CodeInputField = ({
  onResend,
  onSuccess,
  errors,
  showResend,
  resendClassName,
  inputsClassName,
  numberCount,
  initialSeconds,
  canStartCount,
  onKeyChange
}) => {
  const classes = useStyles();
  const [t] = useTranslation('auth');
  const [seconds, setSeconds] = useState(initialSeconds);
  const [validation, setValidation] = useState({isValid: true, message: ''});
  const [code, setCode] = useState(getNumObject(numberCount, ''));

  const refs = {
    0: React.useRef(null),
    1: React.useRef(null),
    2: React.useRef(null),
    3: React.useRef(null),
    4: React.useRef(null),
    5: React.useRef(null)
  };

  const startTimer = () => {
    interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        clearInterval(interval)
      }
    }, 1000);
  };

  useEffect(() => {
    if (showResend && canStartCount) {
      startTimer();
      return () => {
        if (interval) clearInterval(interval);
      }
    }
  }, [seconds, canStartCount]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let codeWord = '';
    Object.entries(code).forEach(c => codeWord += c[1]);
    if (codeWord.length === numberCount && validation.isValid) {
      Object.keys(refs).forEach(key => refs[key].current.blur());
      onSuccess && onSuccess({
        code: codeWord
      });
    }
  }, [code, validation.isValid]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (errors) {
      let {message} = errors;
      if (errors.hasOwnProperty('errors')) {
        let errorsData = errors.errors;
        if (errorsData.hasOwnProperty('code')) message = errorsData.code;
      }
      setValidation({isValid: false, message});
    } else {
      setValidation({...validation, isValid: true});
    }
  }, [errors]); // eslint-disable-line react-hooks/exhaustive-deps

  const onCodeChange = (e, key) => {
    const {value} = e.target;
    const valueLength = value.length;
    const maxKeyIndex = numberCount - 1;
    let keyIndex = key;
    let isPaste = false;

    if (!validation.isValid) setValidation({...validation, isValid: true});

    // update current code value
    if (valueLength < 2) {
      setCode({...code, [key]: value});
    } else if (valueLength === 2) {
      const diff = helpers.findStringDifference(code[key], value);
      setCode({...code, [key]: diff ? diff : value[1]});
    } else {
      let array = {};
      value.split('').forEach(item => {
        if (keyIndex <= maxKeyIndex) array[keyIndex] = item;
        if (keyIndex < maxKeyIndex) keyIndex += 1;
      });
      isPaste = true;
      setCode({...code, ...array});
    }

    onKeyChange && onKeyChange(value);

    // focus next or prev input field
    if (keyIndex >= 0 && keyIndex <= maxKeyIndex && isPaste) {
      refs[keyIndex].current.focus();
    } else if (valueLength === 0 && key > 0) {
      refs[key - 1].current.focus();
    } else if (valueLength >= 1 && key < maxKeyIndex) {
      refs[key + 1].current.focus();
    } else if (key === 3) {
      refs[key].current.blur();
    }
  };

  const onKeyDown = (e, key) => {
    const {value} = e.target;
    const {keyCode} = e;
    if (value === '' && key > 0 && keyCode === 8) {
      refs[key - 1].current.focus();
    }
  }

  const reloadCode = () => {
    setCode(getNumObject(numberCount, ''));
    onResend && onResend();
    setSeconds(initialSeconds);
    startTimer();
    refs[0].current.focus();
  };

  const {isValid} = validation;

  return (
    <>
      <div className='d-flex flex-column'>
        <div className={`d-flex inputs ${classes.inputs} ${inputsClassName}`}>
          {[...Array(numberCount).keys()].map(key => {
            return (
              <Input
                key={key}
                autocomplete={false}
                onChange={(e) => onCodeChange(e, key)}
                onKeyDown={(e) => onKeyDown(e, key)}
                readonly={false}
                size='small'
                name={`code-${key}`}
                type='number'
                value={code[key]}
                className={isValid ? code[key] !== '' ? 'active': '' : 'not-valid'}
                ref={refs[key]}
              />
            )
          })}
        </div>

        {!isValid && (
          <div className='error-message error-landing'>
            {t(`errors.${validation.message}`)}
          </div>
        )}
      </div>

      {showResend && (
        <div className={`d-flex ${classes.reload} ${!isValid ? 'pt-0' : ''} ${resendClassName}`}>
          <div
            onClick={() => reloadCode()}
            className={`pointer ${classes.link} ${seconds > 0 ? 'disabled' : ''}`}>
            {t('resendCode')}
          </div>
          <span>{helpers.getSecondsTime(seconds)}</span>
        </div>
      )}

    </>
  );
}

CodeInputField.propTypes = {
  onResend: PropTypes.func,
  onSuccess: PropTypes.func,
  showResend: PropTypes.bool,
  resendClassName: PropTypes.string,
  inputsClassName: PropTypes.string,
  errors: PropTypes.any,
  numberCount: PropTypes.oneOf([2, 3, 4, 5, 6]),
  initialSeconds: PropTypes.number,
  canStartCount: PropTypes.bool,
  onKeyChange: PropTypes.bool
}

CodeInputField.defaultProps = {
  showResend: true,
  resendClassName: '',
  inputsClassName: '',
  numberCount: 6,
  initialSeconds: 59,
  canStartCount: true
}

export default CodeInputField;
