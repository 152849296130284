import * as h1Theme from '@h1-card/h1-lib-ui/dist/theme/theme.json';

const styles = {
  colors: {
    ...h1Theme.default.colors,
    secondary: '#0A145A',
    secondary_hover: '#122292',
    primary: '#FFCC00',
    primary_hover: 'F2C307',
    light_darker: '#e9e9e9',
    violet: '#0A145A',
    modal_header_bg: 'rgba(18, 34, 146, 0.2)'
  }
};

const theme = Object.assign(
  {},
  h1Theme.default,
  styles
)

export default theme;
