import { authConstants } from '../constants';


const authState = {
  phoneNumber: null,
  session: null,
  showWelcome: false,
  showRegisterModal: true
};


const authReducer = (state = authState, action) => {
  const {type, data} = action;
  switch (type) {
    case authConstants.SUCCESS_LOGIN:
      return {
        ...state,
        phoneNumber: action.phoneNumber,
        session: data
      };
    case authConstants.LOGOUT:
      return {
        ...state,
        showWelcome: false,
        companyInviteToken: null
      }
    default:
      return state
  }
};

export default authReducer;
