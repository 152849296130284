import {userConstants} from '../constants';
import {request} from '../services/request';
import {authActions} from './auth.actions';
import {mainActions} from './main.actions';
import {localStorageKeysConstants} from '../../constants';

const {TOKEN} = localStorageKeysConstants;

const authUser = (isAuth) => {
  return {
    type: userConstants.SET_AUTH,
    isAuth
  }
}

const getUserProfile = (hideLoader = true, showLoading = true) => {
  return (dispatch) => {
    const loading = (status = false) => mainActions.setInitialLoading(status, dispatch);
    const token = JSON.parse(localStorage.getItem(TOKEN));

    if (token && token.accessToken) {
      showLoading && loading(true);
      request.get(dispatch, 'admin/users/current', {},
      (data) => {
        dispatch({
          type: userConstants.GET_USER_PROFILE,
          user: data,
        });
        hideLoader && loading();
      },
      () => {
        dispatch(userActions.markedCheckedAuth());
        loading();
      },
    false);
    } else {
      authActions.logout();
      dispatch(userActions.markedCheckedAuth());
      loading();
    }
  }
}

const markedCheckedAuth = () => {
  return (dispatch) => {
    dispatch({type: userConstants.SET_CHECKED_AUTH});
  }
}

const getSuperusersList = (success, error) => {
  return (dispatch) => {
    request.get(dispatch, 'admin/superusers', {},
    (data) => {
      dispatch({
        type: userConstants.GET_USERS_LIST,
        users: data,
      });
      success && success(data);
    },
    (resp) => {
      dispatch(userActions.markedCheckedAuth());
      error && error(resp);
    })
  }
}

export const userActions = {
  authUser,
  getUserProfile,
  markedCheckedAuth,
  getSuperusersList
};
