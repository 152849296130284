import {bankingRequest, getMethodTemplate, postMethodTemplate, putMethodTemplate} from '../services/request';

const getRequest = (url, query, success, error, props) => getMethodTemplate(bankingRequest, url, query, success, error, props);

const postRequest = (url, data, success, error, props) => postMethodTemplate(bankingRequest, url, data, success, error, props);

const putRequest = (url, data, success, error) => putMethodTemplate(bankingRequest, url, data, success, error);

const getRequestsList = (query = null, success, error) => getRequest('companies', query, success, error);

const reviewSubmission = (userId, reviewType, comment, success, error) => {
  if (!['reject', 'accept'].includes(reviewType)) return ;
  return postRequest(`users/${userId}/${reviewType}-h1-review`, {comment}, success, error);
}

const downloadDocument = (userId, documentId, success, error) =>
  getRequest(`users/${userId}/documents/${documentId}/download`, null, success, error);

const updateDocument = (userId, documentId, data, success, error) =>
  putRequest(`users/${userId}/documents/${documentId}/replace-submitted`, data, success, error);

const getUserDocuments = (userId, success, error) => getRequest(`users/${userId}/documents`, null, success, error);

const createDocument = (data, success, error) => postRequest('documents', data, success, error);

const getUserRejectedSubmissions = (id, success, error) =>
  getRequest(`users/${id}/submissions/rejected`, null, success, error);

const getUserLatestSubmission = (id, success, error) =>
  getRequest(`users/${id}/submissions/latest`, null, success, error, {disableAlert: true});

const initiateReviewSubmission = (success, error) => postRequest('users/initiate-treezor-review', null, success, error);

const sendJoinInvitation = (data, success, error) => postRequest('users/send-join-invitation', data, success, error, {disableAlert: true});

const getInvitationDetailsPromise = (userId) => bankingRequest.getWithPromise(`users/${userId}/invitation`);

export const submissionsActions = {
  getRequestsList,
  reviewSubmission,
  downloadDocument,
  createDocument,
  getUserDocuments,
  updateDocument,
  getUserRejectedSubmissions,
  getUserLatestSubmission,
  initiateReviewSubmission,
  sendJoinInvitation,
  getInvitationDetailsPromise
};
