import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import TabContainer from '../../../molecules/TabContainer';
import SubmissionPreview from '../SubmissionPreview';
import CreateUboForm from '../CreateUboForm';
import EmptySubmissionPreview from '../EmptySubmissionPreview';
import {userTypesConstants} from '../../../../constants';
import {helpers} from '../../../../helpers';

const {LEGAL_REPRESENTATIVE} = userTypesConstants;

const useStyles = makeStyles(() => ({
  ubosTabContainer: {
    '& div[role="tab"]': {
      backgroundColor: '#E9E9E9',
      borderRadius: 20,
      '&[aria-selected="true"]': {
        backgroundColor: '#808080',
        '& > div': {
          display: 'none'
        },
        '& p': {
          color: '#fff'
        }
      },
      '&:not(:last-of-type)': {
        marginRight: 12
      },
      '& p': {
        color: '#000',
        fontWeight: 700,
        margin: '0.5rem 0',
        transition: '.2s'
      }
    },
    '& > div:first-of-type': {
      borderBottom: 'unset'
    }
  },
  emptyMessage: {
    fontSize: 12,
    marginBottom: 40
  }
}));

const UBOSPreview = ({
  handleUpdateSubmissionDetails,
  handleSuccessUserCreation,
  invitationFormUrls,
  isEditable,
  submissions,
  users
}) => {
  const classes = useStyles();
  const [t] = useTranslation('kyc');
  const [tabs, setTabs] = useState([]);

  const title = t('ubo');

  const emptyUbos = (
    <>
      <p className={classes.emptyMessage}>{t('noCreatedUbos')}</p>
      <div className='fw-600 mulish-font f-20'>
        {t('currentSubmission')}
      </div>
      <p className='fw-500'>
        {t('legalRepresentativeEmpty')}
      </p>
    </>
  );

  const getLinkToForm = (url) => (
    <a
      className='f-12 text-uppercase'
      href={url}
      target='_blank'
      rel='noreferrer'
    >
      {t('main:linkToForm')}
    </a>
  );

  useEffect(() => {
    let tabs = [];
    if (users.length > 0) {
      tabs = users.map((user, key) => {
        let tabContent;
        const submission = submissions.find(s => s.submission.user.id === user.id);
        const invitationDetail = invitationFormUrls.find(i => i.user_id === user.id);
        const linkToForm = getLinkToForm(helpers.getObjProp(invitationDetail, 'url'));

        if (submission) {
          const {submission: currentSubmission, rejected: rejectedSubmissionsList} = submission;
          const {user, documents} = currentSubmission;
          tabContent = (
            <div key={key}>
              <SubmissionPreview
                additionalTitleContent={linkToForm}
                submission={user}
                documents={documents}
                handleUpdateSubmissionDetails={handleUpdateSubmissionDetails}
                userType={LEGAL_REPRESENTATIVE}
                isEditable={isEditable}
              />
              {rejectedSubmissionsList.map((s, index) => (
                <SubmissionPreview
                  key={`${s.id}-${index}`}
                  submission={s.user}
                  documents={s.documents}
                  userType={LEGAL_REPRESENTATIVE}
                  isHiddenDownload={true}
                />
              ))}
            </div>
          );
        } else {
          tabContent = (
            <EmptySubmissionPreview
              additionalTitleContent={linkToForm}
              user={user}
              userType={user.user_type}
            />
          );
        }

        return {
          title: `${title} ${key + 1}`,
          component: tabContent
        }
      })
    }
    setTabs(tabs);
  }, [submissions, users]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <CreateUboForm onSuccess={handleSuccessUserCreation} />
      {users.length === 0 ? (
        <>
          {emptyUbos}
        </>
      ) : (
        <TabContainer
          className={classes.ubosTabContainer}
          tabs={tabs}
        />
      )}
    </div>
  );
}

UBOSPreview.propTypes = {
  handleUpdateSubmissionDetails: PropTypes.func,
  handleSuccessUserCreation: PropTypes.func,
  invitationFormUrls: PropTypes.arrayOf(
    PropTypes.shape({
      user_id: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    })
  ),
  isEditable: PropTypes.bool,
  submissions: PropTypes.arrayOf(
    PropTypes.shape({
      submission: PropTypes.object,
      rejected: PropTypes.array
    })
  ),
  users: PropTypes.array
}

UBOSPreview.defaultProps = {
  invitationFormUrls: [],
  isEditable: true,
  isEmpty: false,
  submissions: [],
  users: []
}

export default UBOSPreview;
