import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

import Button from '@h1-card/h1-lib-ui/dist/components/atoms/Button';
import TableCol from '@h1-card/h1-lib-ui/dist/components/molecules/Table/components/TableCol';
import TableRow from '@h1-card/h1-lib-ui/dist/components/molecules/Table/components/TableRow';
import FileUploader from '../../../molecules/FileUploader';
import {helpers} from '../../../../helpers';

const DocumentDetailsTableRow = ({
  document,
  index,
  handleDownload,
  handleUpdateDocument,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'kyc']);
  const [isDisabledButton, setIsDisabledButton] = useState({
    download: false,
    replace: false
  });

  const onDownload = () => {
    if (handleDownload && !isDisabledButton.download) {
      setIsDisabledButton({...isDisabledButton, download: true});
      handleDownload(
        document,
        () => setIsDisabledButton({...isDisabledButton, download: false}),
        () => setIsDisabledButton({...isDisabledButton, download: false})
      )
    }
  }

  const onReplace = async (uploadedDocument) => {
    if (handleUpdateDocument && !isDisabledButton.replace) {
      setIsDisabledButton({...isDisabledButton, replace: true});
      handleUpdateDocument(
        document,
        uploadedDocument,
        () => setIsDisabledButton({...isDisabledButton, replace: false}),
        () => setIsDisabledButton({...isDisabledButton, replace: false})
      )
    }
  }

  const documentType = helpers.getDocumentTypeLabel(document.document_type_id);

  return (
    <div key={`document-${index}`} {...rest}>
      <TableRow>
        <TableCol/>
        <TableCol>#{++index}</TableCol>
        <TableCol>{documentType}</TableCol>
        <TableCol>{document.name}</TableCol>
        <TableCol>
          <a
            href={document.file_url}
            rel='noreferrer'
            target='_blank'
          >
            {t('link')}
          </a>
        </TableCol>
        <TableCol>
          <Button
            disabled={isDisabledButton.download}
            onClick={onDownload}
            variant='outlined'
          >
            {t('download')}
          </Button>
          <FileUploader
            alertType='alert'
            buttonText={t('replace')}
            disabled={isDisabledButton.replace}
            handleOnChange={onReplace}
          />
        </TableCol>
      </TableRow>
    </div>
  )
}

DocumentDetailsTableRow.propTypes = {
  document: PropTypes.shape({
    document_type_id: PropTypes.number.isRequired,
    file_url: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  index: PropTypes.number.isRequired,
  handleDownload: PropTypes.func,
  handleUpdateDocument: PropTypes.func
}

export default DocumentDetailsTableRow;
