import i18next from 'i18next';
import authenticationEn from './en/authentication.json';
import kycEn from './en/kyc.json';
import mainEn from './en/main.json';

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'en',
  resources: {
    en: {
      auth: authenticationEn,
      kyc: kycEn,
      main: mainEn,
    }
  }
});

export default i18next;
