import React from 'react';
import PropTypes from 'prop-types';
import {ArrowDownIcon, ArrowUpIcon} from '@h1-card/h1-lib-ui/dist/components/icons';
import {
  StyledTableOrderedColIcon,
  StyledTableOrderedColButton,
} from '@h1-card/h1-lib-ui/dist/components/molecules/Table/components/TableCol/StyledTableCol';
import StyledTableCol from './StyledTableCol';

const TableCol = ({ auto, order, halign, span, flexBasisWidth, children, onClick }) => {
  const getOrderIcon = order => {
    if (!order) {
      return null;
    }
    const props = {
      variant: order.active ? 'dark' : 'light_darker_1',
      size: 'xsmall'
    }
    return order.value === 'desc' ? <ArrowDownIcon {...props} /> : <ArrowUpIcon {...props} />;
  };

  return (
    <StyledTableCol
      auto={auto}
      halign={halign}
      span={span}
      flexBasisWidth={flexBasisWidth}>
      {!order && children}
      {order && (
        <StyledTableOrderedColButton onClick={onClick}>
          {children}
          <StyledTableOrderedColIcon>
            {getOrderIcon(order)}
          </StyledTableOrderedColIcon>
        </StyledTableOrderedColButton>
      )}
    </StyledTableCol>
  );
};

TableCol.propTypes = {
  /** If true the column width will adapt to it's content  */
  auto: PropTypes.bool,
  /** If it has a value will show arrows down/up based on 'desc'|'asc', active prop defines if the order is active/inactive */
  order: PropTypes.shape({
    active: PropTypes.bool,
    value: PropTypes.oneOf(['desc', 'asc']),
  }),
  /** Sets the horizontal alignment of the column content */
  halign: PropTypes.oneOf(['left', 'center', 'right']),
  span: PropTypes.number,
  flexBasisWidth: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
};

TableCol.defaultProps = {
  halign: 'left',
};

export default TableCol;
