import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@mui/styles';
import TableControlled from '../../../molecules/Table/TableControlled';

import DocumentsTableRow from '../DocumentsTableRow';
import {helpers} from '../../../../helpers';


const useStyles = makeStyles(() => ({
  table: {
    '& .row-container': {
      '& > div': {
        '&:nth-child(1)': {
          maxWidth: 200
        },
        '&:nth-child(2)': {
          maxWidth: 160
        },
        '&:nth-child(5)': {
          maxWidth: 60
        },
        '&:nth-child(6)': {
          minWidth: 300,
          '& button': {
            height: 40,
            '&:not(:last-of-type)': {
              marginRight: 8
            },
            '&:first-of-type': {
              marginLeft: 'auto'
            }
          }
        }
      }
    },
    '& > div > div > div:first-of-type': {
      '& .row-container': {
        borderTop: 'unset !important'
      }
    }
  }
}));


const DocumentsTable = ({
  documents,
  handleUpdateDocuments,
  isLoaded
}) => {
  const classes = useStyles();
  const [t] = useTranslation(['main', 'kyc']);

  const minWidth = 700;
  const [cols, setCols] = useState([]);

  useEffect(() => {
    setCols([
      {name: t('user')},
      {name: `${t('user')} ${t('type')}`},
      {name: `${t('document')} ${t('type')}`},
      {name: t('name')},
      {name: 'URL'},
      {name: t('action')},
    ]);
  }, [t]);

  const handleUpdateDocumentsList = (variant, userId, document) => {
    if (handleUpdateDocuments) {
      let updatedDocuments;
      if (variant === 'update') {
        updatedDocuments = documents.map(item => {
          if (item.user.id === userId) {
            return {
              user: item.user,
              documents: item.documents.map(itemDoc => itemDoc.id === document.id ? document : itemDoc)
            }
          } else {
            return item;
          }
        })
      } else {
        updatedDocuments = documents.map(item => {
          if (item.user.id === userId) {
            return {
              user: item.user,
              documents: [...item.documents, document]
            }
          } else {
            return item
          }
        });
      }
      handleUpdateDocuments(updatedDocuments);
    }

  }

  return (
    <div className='table-container p-0'>
      <div className={classes.table}>
        <TableControlled
          additionalRowProps={{handleUpdateDocuments: handleUpdateDocumentsList}}
          cols={cols}
          hasSelectAll={false}
          lastAlignRight={true}
          emptyMessage={isLoaded ? undefined : `${t('loading')}...`}
          minWidth={minWidth}
          rowComponent={DocumentsTableRow}
          rows={helpers.createRows(documents)}
        />
      </div>
    </div>
  );
}

DocumentsTable.propTypes = {
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      documents: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired
        })
      ),
      user: PropTypes.shape({
        firstname: PropTypes.string,
        id: PropTypes.string.isRequired,
        lastname: PropTypes.string
      }).isRequired
    })
  ),
  isLoaded: PropTypes.bool
}

DocumentsTable.defaultProps = {
  documents: [],
  isLoaded: true
}

export default DocumentsTable;
