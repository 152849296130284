import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@mui/styles';
import Button from '@h1-card/h1-lib-ui/dist/components/atoms/Button';
import Feedback from '@h1-card/h1-lib-ui/dist/components/molecules/Feedback';
import {helpers} from '../../../helpers';
import {alertActions} from '../../../state/actions';


const useStyles = makeStyles(theme => ({
  feedback: {
    marginTop: 16,
    // maxWidth: 360
  }
}));

const availableExtensions = ['.jpg', '.jpeg', '.png'];

const FileUploader = ({
  additionalExtensions,
  alertType,
  buttonText,
  disabled,
  handleOnChange,
  maxFileSize
}) => {
  const classes = useStyles();
  const [message, setMessage] = useState({
    status: 'success',
    show: false,
    message: ''
  });
  const [t] = useTranslation('main');
  const hiddenFileInputRef = React.useRef(null);

  const displayAlertMessage = (message) => {
    if (alertType === 'message') {
      setMessage({
        status: 'error',
        show: true,
        message
      });
    } else {
      alertActions.error(message)
    }
  }

  const hideAlertMessage = () => {
    if (alertType === 'message') {
      setMessage({
        ...message,
        show: false
      });
    }
  }

  const onButtonClick = () => hiddenFileInputRef.current.click();

  const onInputChange = (files) => {
    if (files.length > 0) {
      const file = files[0];
      const filename = file.name;
      let fSize = file.size;

      if (!helpers.hasExtensions(filename, [...availableExtensions, ...additionalExtensions])) {
        displayAlertMessage(t('validation.wrongFileFormat'));
        return;
      }

      fSize = Math.round((fSize / 1024));
      if (fSize > maxFileSize) {
        displayAlertMessage(t('validation.fileTooBig', {size: helpers.formatBytes(maxFileSize)}));
        return;
      } else {
        hideAlertMessage();
      }
      if (file) {
        handleOnChange && handleOnChange(file);
      }
    }
  };

  return (
    <>
      <Button
        variant='outlined'
        onClick={onButtonClick}
        disabled={disabled}
      >
        {buttonText}
      </Button>

      <input
        className='d-none'
        ref={hiddenFileInputRef}
        type='file'
        onChange={(e) => onInputChange(e.target.files)}
      />

      {message.show && (
        <Feedback
          message={message.message}
          status={message.status}
          closable={false}
          className={classes.feedback}
        />
      )}
    </>
  );
}

FileUploader.propTypes = {
  additionalExtensions: PropTypes.arrayOf(PropTypes.string),
  alertType: PropTypes.oneOf(['alert', 'message']),
  buttonText: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  maxFileSize: PropTypes.number,
  handleOnChange: PropTypes.func
}

FileUploader.defaultProps = {
  additionalExtensions: [],
  alertType: 'message',
  disabled: false,
  maxFileSize: 2048
}

export default FileUploader;
