import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@mui/styles';

import TableCol from '@h1-card/h1-lib-ui/dist/components/molecules/Table/components/TableCol';
import TableRow from '@h1-card/h1-lib-ui/dist/components/molecules/Table/components/TableRow';


const useStyles = makeStyles(() => ({
  tableRow: {
    transition: '0.3s ease',
    minWidth: 700,
    '& .row-container > div': {
      pointerEvents: 'unset',
      fontSize: 14,
      paddingTop: 12,
      paddingBottom: 12,
      color: '#191927',
    },
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#F0F8FF',
    },
  }
}));

const CardsTableRow = ({
  row
}) => {
  const classes = useStyles();
  const {id, data} = row;

  return (
  <div
    data-testid='row'
    className={classes.tableRow}>
    <TableRow key={id}>
      <TableCol>
        {data.masked_pan}
      </TableCol>
      <TableCol>
        {data.embossed_name}
      </TableCol>
      <TableCol>
        {data.public_token}
      </TableCol>
    </TableRow>
  </div>)
}

CardsTableRow.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    data: PropTypes.shape({
      id: PropTypes.string.isRequired,
      masked_pan: PropTypes.string.isRequired,
      embossed_name: PropTypes.string.isRequired,
      public_token: PropTypes.string.isRequired,
    })
  }).isRequired
}

export default CardsTableRow;
