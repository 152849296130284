import {request} from '../services/request';
import {authConstants} from '../constants';
import {mainActions} from './main.actions';
import {history} from '../history';
import {helpers} from '../services/helpers';

const login = (data, success, error) => {
  return (dispatch) => {
    const loading = (loadingStatus) => mainActions.setLoading(loadingStatus, dispatch);
    loading(true);
    request.post(dispatch, 'admin/auth/login', data,
      (response) => {
        if (success) success(response.session);
        loading(false);
      },
      (err) => {
        let data = {message: 'Something went wrong, try one more time'};
        if (err && err.hasOwnProperty('response') && err.response !== undefined) {
          data = err.response.data;
          if (error) error(data);
        }
        loading(false);
      },
      false,
      {},
      true);
  }
}

const codeVerification = (data, success, error) => {
  return (dispatch) => {
    const loading = (loadingStatus) => mainActions.setLoading(loadingStatus, dispatch);
    loading(true);
    request.post(dispatch, 'auth/login/code/verification', data,
      (data) => {
      helpers.saveJWT(data);

      if (success) success();
      loading(false);
    },
    (err) => {
      const {data} = err.response;
      if (error) error(data);
      loading(false);
    },
    false);
  }
}

const logout = () => {
  helpers.clearUserStoredData();
  history.push('/');
  return (dispatch) => {
    dispatch({ type: authConstants.LOGOUT });
  }
}

export const authActions = {
  login,
  logout,
  codeVerification,
};
