import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@mui/styles';
import {connect} from 'react-redux';
import Button from '@h1-card/h1-lib-ui/dist/components/atoms/Button';

import PageLoading from '../../components/atoms/PageLoading';
import BackHistoryButton from '../../components/atoms/BackHistoryButton';
import TabContainer from '../../components/molecules/TabContainer';
import HeaderDetails from '../../components/pages/KYCRequestDetailPage/HeaderDetails';
import SubmissionPreview from '../../components/pages/KYCRequestDetailPage/SubmissionPreview';
import UBOSPreview from '../../components/pages/KYCRequestDetailPage/UBOSPreview';
import UsersTable from '../../components/pages/KYCRequestDetailPage/UsersTable/UsersTable';
import DocumentsTable from '../../components/pages/KYCRequestDetailPage/DocumentsTable/DocumentsTable';
import BankingProviderResponse from '../../components/pages/KYCRequestDetailPage/BankingProviderResponse';

import TransactionsTable from '../../components/pages/CompanyDetailPage/TransactionsTable';

import TerminateCompanyModal from '../../components/pages/CompaniesListPage/TerminateCompanyModal';

import EmptySubmissionPreview from '../../components/pages/KYCRequestDetailPage/EmptySubmissionPreview';

import NotFoundPage from '../NotFoundPage';

import {
  H1KYCStatusesConstants,
  H1CompanyStatusesConstants,
  localStorageKeysConstants,
  H1ReviewStatusesConstants,
  userTypesConstants
} from '../../constants';
import {companiesActions, submissionsActions} from '../../state/actions';
import {helpers} from '../../helpers';

const useStyles = makeStyles(() => ({
  bankingResponse: {
    width: '60%',
    paddingBottom: 20
  },
  tabContainer: {
    paddingTop: 20,
    minHeight: 612
  }
}));

const defaultSubmissionDetails = {document: null, user: null};

const defaultSubmissions = {
  legalPerson: defaultSubmissionDetails,
  legalRepresentative: defaultSubmissionDetails,
  ubos: []
}

const defaultRejectedSubmissions = {
  legalPerson: [],
  legalRepresentative: [],
  ubos: []
}

const {
  // ACCEPTED: REVIEW_ACCEPTED,
  REJECTED: REVIEW_REJECTED,
} = H1ReviewStatusesConstants;

const {
  LEGAL_PERSON,
  LEGAL_REPRESENTATIVE,
  UBO
} = userTypesConstants;

const {
  ACCEPTED: KYC_ACCEPTED,
  SUBMITTED: KYC_SUBMITTED,
  REJECTED: KYC_REJECTED,
  PENDING: KYC_PENDING,
  TREEZOR_REVIEWED: KYC_TREEZOR_REVIEWED,
  TREEZOR_SUBMITTED: KYC_TREEZOR_SUBMITTED,
} = H1KYCStatusesConstants;

const {getStatusLabel} = helpers;

const KYCRequestDetailPage = ({
  match,
  getCompanyDetails,
  getTransactionsList,
  getUserRejectedSubmissions,
  getUserLatestSubmission,
  getCompanyWallet,
  getCompanyUsers,
  initiateReviewSubmission,
  reviewCompanyKYC,
  deleteCompany,
  getUserDocuments,
  superusers
}) => {
  const [t] = useTranslation(['kyc', 'main']);
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [companyDetails, setCompanyDetails] = useState({
    balance: 0,
    id: null,
    name: '',
    walletId: '',
    status: null,
    isLoaded: false
  });
  const [users, setUsers] = useState([]);
  const [transactions, setTransaction] = useState([]);
  const [documents, setDocuments] = useState({isLoaded: false, data: []});

  const [submissions, setSubmissions] = useState(defaultSubmissions);
  const [rejectedSubmissions, setRejectedSubmissions] = useState(defaultRejectedSubmissions);

  const [terminateModalProps, setTerminateModalProps] = useState({open: false, loading: false});
  const [ubosCount, setUbosCount] = useState({isNeedUpdate: false, count: 0});
  const [isDisabledSubmit, setIsDisabledSubmit] = useState(true);
  const [isDisabledApprove, setIsDisabledApprove] = useState(true);
  const [invitationFormUrls, setInvitationFormUrls] = useState([]);

  const {status} = companyDetails;

  const isCompanyBlocked = (companyStatus) => companyStatus === H1CompanyStatusesConstants.BLOCKED || companyStatus === H1CompanyStatusesConstants.INACTIVE;

  const isReviewRejectedStatus = status === REVIEW_REJECTED;

  // bank response is enabled if we have received a review from treezor, or if the company has submitted a review
  const isEnabledBankResponse = [
    KYC_SUBMITTED,
    KYC_TREEZOR_REVIEWED,
    KYC_REJECTED
  ].includes(status);

  const headerDetails = helpers.getCompanyHeaderDetails(companyDetails, helpers.getKYCRequestStatus);

  const isNotGoodForReview = (company_kyc_status) => {
    // TODO: review this
    // used to be this, which might be correct in the rare event of a rejected kyc from treezor
    // find if some special user has not accepted status
    // users.filter(u => u.user_type !== 'employee').some(u => u.h1_review_status !== ACCEPTED);

    // if company kyc status is either accepted or submitted to treezor, return true
    return [KYC_ACCEPTED, KYC_PENDING, KYC_REJECTED, KYC_TREEZOR_SUBMITTED].includes(company_kyc_status);
  }

  useEffect(() => {
    const {isNeedUpdate, count} = ubosCount;
    if (isNeedUpdate && count >= 0) {
      const uboUsers = findUsersByType(users, UBO);
      const index = uboUsers.length - count;
      if (uboUsers[index]) {
        getUserSubmissions(uboUsers[index], true);
      } else {
        setUbosCount({...ubosCount, isNeedUpdate: false});
      }
    } else if (isNeedUpdate && count < 0) {
      setUbosCount({...ubosCount, isNeedUpdate: false});
    }
  }, [ubosCount]); // eslint-disable-line react-hooks/exhaustive-deps

  const getUserDetails = (submission, isRejected = false, superusers) => {
    if (submission.data) {
      const user = helpers.getObjProp(submission.data, 'user', null);
      const documents = helpers.getObjProp(submission.data, 'documents', []);
      if (user) {
        const {created_at: createdAt, comment, updated_at: updatedAt, pending: isPending, reviewer_id: reviewerId} = submission;
        const reviewer = superusers.find(s => s.id === reviewerId);
        return {
          user: {
            ...user,
            comment,
            isPending: isRejected ? false : isPending,
            last_update_date: updatedAt,
            submitted_date: createdAt,
            reviewer: helpers.getObjProp(reviewer, 'name', reviewerId)
          },
          documents
        };
      } else {
        return null;
      }
    }
  }

  const getRejectedSubmissions = (user, isNeedRemoveLast = false) => {
    const {id: userId, user_type: userType} = user;
    getUserRejectedSubmissions(
      userId,
      (submissions) => {
        let updatedRejectedSubmissions;
        const propName = getPropName(userType);
        if (isNeedRemoveLast) submissions.pop();
        const regSubmissionsList = submissions
          .sort(
            (objA, objB) => Number(new Date(objB.created_at)) - Number(new Date(objA.created_at)),
          )
          .map(submission => {
            const data = getUserDetails(submission, true, superusers);
            return {
              ...data,
              user: {
                ...data.user,
                h1_review_status: REVIEW_REJECTED
              }
            }
          });

        if (propName === 'ubos') {
          updatedRejectedSubmissions = {
            ...rejectedSubmissions,
            [propName]: [
              ...rejectedSubmissions[propName],
              ...regSubmissionsList
            ]
          }
        } else {
          updatedRejectedSubmissions = {
            ...rejectedSubmissions,
            [propName]: regSubmissionsList
          }
        }

        setRejectedSubmissions(updatedRejectedSubmissions);
      }
    )
  }

  const getUserSubmissions = (user, descreaseUbo = false) => {
    const {id, h1_review_status} = user;
    const isRejected = (h1_review_status === REVIEW_REJECTED);
    getRejectedSubmissions(
      user,
      isRejected
    );
    setLoading(true);
    getUserLatestSubmission(
      id,
      (submission) => {
        submission = {
          ...submission,
          data: {
            ...submission.data,
            user
          }
        }
        updateSubmissionsState(submission, descreaseUbo, false);
        setLoading(false);
      },
      () => setLoading(false)
    );
  }

  const findUserByType = (users, userType) => users.find(u => u.user_type === userType);

  const findUsersByType = (users, userType) => users.filter(u => u.user_type === userType);

  useEffect(() => {
    const {id} = match.params;
    if (id) {
      localStorage.setItem(localStorageKeysConstants.COMPANY_ID, id);
      getCompanyDetails(
        (data) => {
          const {
            company_balance: balance,
            id,
            name,
            company_kyc_status,
            company_status
          } = data;

          const details = {
            ...companyDetails,
            balance,
            id,
            status: isCompanyBlocked(company_status) ? REVIEW_REJECTED : company_kyc_status,
            name,
            isLoaded: true
          }
          setCompanyDetails(details);
          setIsDisabledSubmit(isNotGoodForReview(company_kyc_status))
          setIsDisabledApprove(!(company_kyc_status === KYC_ACCEPTED));
          getCompanyUsers(
            null,
            (users) => {
              // load legal person information
              const legalPerson = findUserByType(users, LEGAL_PERSON);
              if (legalPerson) getUserSubmissions(legalPerson);
              setUsers(users);
            }
          );
          getCompanyWallet((data) => {
            setCompanyDetails({
              ...details,
              walletId: data?.bank_id || ''
            });
          });
        },
        () => {
          setLoading(false);
          setCompanyDetails({...companyDetails, isLoaded: true});
        }
      );
      if (transactions.length === 0) {
        setLoading(true);
        getTransactionsList(
          (transactions) => {
            setTransaction(transactions);
            setLoading(false)
          },
          () => setLoading(false)
        )
      }
    }
  }, [match.params.id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // load ubo form invitation keys if kyc statys pending/submitted
    if ([KYC_SUBMITTED, KYC_PENDING].includes(companyDetails.status)) loadUboInvitationKeys(users);
  }, [users, companyDetails.status]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadUboInvitationKeys = (users) => {
    const promises = findUsersByType(users, UBO)
      .filter(u => u.h1_review_status === H1ReviewStatusesConstants.PENDING && invitationFormUrls.find(i => i.user_id === u.id) === undefined)
      .map(user => submissionsActions.getInvitationDetailsPromise(user.id));

    Promise
      .all(promises)
      .then(results => {
        const invitationDetails = [];
        results.forEach(result => {
          if (result.data && result.status === 200) {
            invitationDetails.push({
              user_id: result.data.id,
              url: helpers.getKYCInvitePageUrl(result.data.key)
            });
          }
        });
        setInvitationFormUrls([
          ...invitationFormUrls,
          ...invitationDetails
        ]);
      })
  }

  const getUserDocumentsList = () => {
    let usersDocuments = [];
    users.forEach(user => {
      getUserDocuments(
        user.id,
        (documents) => {
          usersDocuments = [...usersDocuments, {user, documents}];
          setDocuments({isLoaded: true, data: usersDocuments});
        },
        () => setDocuments({isLoaded: false, data: usersDocuments})
      )
    })
  }

  const handleTabChange = (tabKey) => {
    const tabActions = {
      'documents': () => !documents.isLoaded && getUserDocumentsList(),
      'legalRepresentative': () => {
        const user = findUserByType(users, LEGAL_REPRESENTATIVE);
        if (user && submissions.legalRepresentative.user === null) {
          getUserSubmissions(user);
        }
      },
      'ubos': () => {
        const uboUsers = findUsersByType(users, UBO);
        if (uboUsers.length > 0 && submissions.ubos.length === 0) {
          setUbosCount({...ubosCount, isNeedUpdate: true, count: uboUsers.length});
        }
      }
    }
    tabActions.hasOwnProperty(tabKey) && tabActions[tabKey]();
  }

  const getPropName = (userType) => {
    let propName;
    if (userType === LEGAL_PERSON) {
      propName = 'legalPerson'
    } else if (userType === LEGAL_REPRESENTATIVE) {
      propName = 'legalRepresentative'
    } else {
      propName = 'ubos'
    }
    return propName;
  }

  const updateSubmissionsState = (submission, descreaseUbo = false, needUpdateUsers) => {
    const userDetails = getUserDetails(submission, false, superusers);
    const {id: userId, user_type: userType} = userDetails.user;
    const propName = getPropName(userType);
    let updatedSubmissions;

    if (propName === 'ubos') {
      let submissionsList = submissions[propName] || [];
      if (submissionsList.length > 0) {
        const index = submissionsList.findIndex(s => s.user.id === userId);
        if (index >= 0) {
          submissionsList[index] = userDetails;
        } else {
          submissionsList = [...submissionsList, userDetails];
        }
      } else {
        submissionsList = [...submissionsList, userDetails];
      }

      updatedSubmissions = {
        ...submissions,
        [propName]: submissionsList
      }
    } else {
      updatedSubmissions = {
        ...submissions,
        [propName]: userDetails
      }
    }

    setSubmissions(updatedSubmissions);
    if (needUpdateUsers) updateUsersList(userDetails.user);
    if (descreaseUbo) setUbosCount({...ubosCount, count: ubosCount.count - 1});
  }

  const updateUsersList = (user) => {
    let usersList = [...users];
    const {id: userId} = user;
    if (users.length > 0) {
      const index = users.findIndex(s => s.id === userId);
      if (index >= 0) {
        usersList[index] = user;
      } else {
        usersList = [...usersList, user];
      }
    } else {
      usersList = [...usersList, user];
    }
    setUsers(usersList);
  }

  const handleUpdateSubmissionDetails = (details, success, error) => {
    const {data, reviewType} = details;
    let submission;
    const updatedSubmission = reviewType === 'reject' ? data.rejected_submission : data.submission;
    submission = {
      ...updatedSubmission,
      data: {
        ...updatedSubmission.data,
        user: data.user
      }
    };

    submission = {...submission, pending: false};

    updateSubmissionsState(submission, false, true);
    success && success(submission);
  }

  const handleSuccessUserCreation = () => {
    getCompanyUsers(
      null,
      (users) => setUsers(users)
    );
  };

  const getSubmissionPreviewDetails = (key) => {
    const submission = submissions[key];
    if (submission && submission.user) {
      return {
        submission: submission.user,
        documents: submission.documents,
      }
    } else {
      return null;
    }
  }

  const getSubmissionTabDetails = (title, userType, name) => {
    const submission = getSubmissionPreviewDetails(name);
    const rejectedSubmissionsList = rejectedSubmissions[name] || [];
    return {
      title,
      component: (
        <>
          {submission ? (
            <SubmissionPreview
              userType={userType}
              handleUpdateSubmissionDetails={handleUpdateSubmissionDetails}
              isEditable={!isReviewRejectedStatus}
              {...submission}
            />
          ) : <EmptySubmissionPreview userType={userType} />}
          {rejectedSubmissionsList.map((s, index) => (
            <SubmissionPreview
              key={index}
              submission={s.user}
              userType={userType}
              documents={s.documents}
              isHiddenDownload={true}
            />
          ))}
        </>
      ),
      name
    }
  }

  const handleCloseTerminateModal = () => setTerminateModalProps({...terminateModalProps, open: false});

  const handleOpenTerminateModal = () => setTerminateModalProps({...terminateModalProps, open: true});

  const handleOkTerminateModal = () => {
    setTerminateModalProps({...terminateModalProps, loading: true});

    deleteCompany(
      (data) => {
        const {company_balance: balance, company_kyc_status, company_status} = data;
        setCompanyDetails({
          ...companyDetails,
          balance,
          status: isCompanyBlocked(company_status) ? REVIEW_REJECTED : company_kyc_status
        });
        setTerminateModalProps({...terminateModalProps, loading: true, open: false});
      },
      () => setTerminateModalProps({...terminateModalProps, loading: false})
    );
  }

  const handleSubmitTZReview = () => {
    setLoading(true);
    initiateReviewSubmission(
      () => {
        setCompanyDetails({
          ...companyDetails,
          status: KYC_TREEZOR_SUBMITTED
        });
        setLoading(false);
      },
      () => setLoading(false)
    );
  }

  const handleApproveCompany = () => {
    setLoading(true);
    reviewCompanyKYC(
      'approve',
      () => {
        setCompanyDetails({
          ...companyDetails,
          status: KYC_ACCEPTED
        });
        setLoading(false);
      },
      () => setLoading(false)
    )
  };

  const handleUpdateDocuments = (updatedDocuments) => {
    setDocuments({
      ...documents,
      data: updatedDocuments
    });
  }

  const getUbosSubmissions = () => {
    const ubos = [];
    submissions.ubos.forEach(submission => {
      const {user_id: userId} = submission;
      ubos.push({
        submission: submission,
        rejected: rejectedSubmissions.ubos.filter(s => s.user_id === userId)
      });
    });
    return ubos;
  }

  const getTabs = () => {
    const uboSubmissions = getUbosSubmissions();

    const tabs = [
      getSubmissionTabDetails(t('main:company'), LEGAL_PERSON, 'legalPerson'),
      getSubmissionTabDetails(t('legalRep'), LEGAL_REPRESENTATIVE, 'legalRepresentative'),
      {
        title: t('ubos'),
        component: (
          <UBOSPreview
            handleSuccessUserCreation={handleSuccessUserCreation}
            handleUpdateSubmissionDetails={handleUpdateSubmissionDetails}
            invitationFormUrls={invitationFormUrls}
            isEditable={!isReviewRejectedStatus}
            submissions={uboSubmissions}
            users={findUsersByType(users, UBO)}
          />
        ),
        name: 'ubos'
      }, {
        title: t('allUsers'),
        component: (
          <UsersTable
            users={users}
          />
        ),
        name: 'users'
      }, {
        title: t('main:documents'),
        component: (
          <DocumentsTable
            documents={documents.data}
            isLoaded={documents.isLoaded}
            handleUpdateDocuments={handleUpdateDocuments}
          />
        ),
        name: 'documents'
      }, {
        title: t('main:transactions'),
        component: <TransactionsTable transactions={transactions} />,
      }
    ];

    if (isEnabledBankResponse) {
      tabs.unshift({
        title: t('review'),
        component: (
          <div className={classes.bankingResponse}>
            <BankingProviderResponse
              status={status}
              users={users}
            />
          </div>
        ),
        name: 'reviews'
      });
    }

    return tabs;
  }

  const tabs = getTabs();

  const getHeaderButtons = () => {
    let buttons = [];
    // TODO: this logic might be too restrictive, needs validation on use cases, enabling actions by default
    // const isEnableHeaderActions = [KYC_PENDING, KYC_SUBMITTED].includes(status) && !isReviewRejectedStatus;
    const isEnableHeaderActions = true;

    if (isEnableHeaderActions) {
      buttons = [
        ...buttons,
        <Button
          key="approve"
          size='small'
          disabled={isDisabledApprove}
          onClick={handleApproveCompany}
        >
          ENABLE CARDS
        </Button>,
        <Button
          key="submit"
          size='small'
          disabled={isDisabledSubmit}
          onClick={handleSubmitTZReview}
        >
          SUBMIT TO TREEZOR
        </Button>,
        <Button
          key="block"
          size='small'
          variant='danger'
          onClick={handleOpenTerminateModal}
        >
          {t('block')}
        </Button>
      ]
    }
    return buttons;
  }

  const getAdditionalLabel = () => {
    let statusName, statusClassName;
    if (isReviewRejectedStatus) {
      statusClassName = 'terminated';
      statusName = 'terminated';
      return getStatusLabel(statusClassName, statusName);
    }
    if ([KYC_TREEZOR_REVIEWED, KYC_TREEZOR_SUBMITTED, KYC_REJECTED].includes(status)) {
      const statuses = {
        [KYC_TREEZOR_REVIEWED]: 'reviewed by TZ',
        [KYC_REJECTED]: 'rejected by TZ',
        [KYC_TREEZOR_SUBMITTED]: 'submitted to TZ',
      }
      statusClassName = KYC_REJECTED === status ? 'terminated' : 'submitted';
      statusName = statuses[status];
      return getStatusLabel(statusClassName, statusName);
    } else {
      return status
    }
  }

  return (
    <div className='content-container px-22'>
      <BackHistoryButton
        url='/kyc-requests'
        name={t('kycRequests')}
      />
      {companyDetails.isLoaded && (
        <>
          {
            companyDetails.id ?
              (
                <>
                  <HeaderDetails
                    details={headerDetails}
                    buttons={getHeaderButtons()}
                    additionalLabel={getAdditionalLabel()}
                  />
                  <TabContainer
                    tabs={tabs}
                    className={classes.tabContainer}
                    onTabChange={handleTabChange}
                  />
                </>
              ) :
              <NotFoundPage className='not-found-page' />
          }
        </>
      )}

      <TerminateCompanyModal
        handleClose={handleCloseTerminateModal}
        handleOk={handleOkTerminateModal}
        open={terminateModalProps.open}
        loading={terminateModalProps.loading}
        details={{id: companyDetails.id, name: companyDetails.name}}
      />

      {loading && <PageLoading />}
    </div>
  );
}

const mapStateToProps = state => {
  const {superusers, user} = state.user;
  const {email: userEmail} = user;
  const users = superusers.map(s => {
    const {id, email} = s;
    let name = `${s.first_name} ${s.last_name}`
    if (email === userEmail) name = `${name} (you)`;
    return { id, name }
  });
  return {
    superusers: users
  }
}

const mapDispatchToProps = {
  getCompanyDetails: companiesActions.getCompanyDetails,
  getTransactionsList: companiesActions.getTransactionsList,
  getCompanyWallet: companiesActions.getCompanyWallet,
  getCompanyUsers: companiesActions.getCompanyUsers,
  getUserDocuments: submissionsActions.getUserDocuments,
  getUserRejectedSubmissions: submissionsActions.getUserRejectedSubmissions,
  getUserLatestSubmission: submissionsActions.getUserLatestSubmission,
  initiateReviewSubmission: submissionsActions.initiateReviewSubmission,
  reviewCompanyKYC: companiesActions.reviewCompanyKYC,
  deleteCompany: companiesActions.deleteCompany
}

export default connect(mapStateToProps, mapDispatchToProps)(KYCRequestDetailPage);

