import React, {useEffect, useState} from 'react';
import {makeStyles} from '@mui/styles';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import TableControlled from '../../../molecules/Table/TableControlled';
import TransactionsTableRow from '../TransactionsTableRow';
import {helpers} from '../../../../helpers';

const minWidth = 900;

const useStyles = makeStyles(() => ({
  balance: {
    marginTop: 24,
    fontSize: 28,
    fontWeight: 700,
    letterSpacing: '0.25px',
    color: '#0D175C',
    '& h3': {
      margin: 0
    },
    '& .balance-price': {
      color: '#191927'
    }
  },
  table: {
    marginRight: 22,
    paddingBottom: 70,
    '& .row-container': {
      backgroundColor: 'unset',
      '& > div': {
        '&:last-of-type': {
          maxWidth: 90,
          paddingLeft: 0
        }
      }
    }
  }
}));

const TransactionsTable = ({
  transactions
}) => {
  const [t] = useTranslation(['main', 'kyc']);
  const classes = useStyles();
  const [data, setData] = useState([]);

  const getGroupedTransactions = (transactions) => {
    const daysList = {};
    const groupedTransactions = [];
    let lastAdded;

    // define transactions months
    transactions.forEach(transaction => {
      const date = transaction.execution_date.slice(0, 7);
      const {amount, is_incoming: isIncoming} = transaction;
      const income = isIncoming ? amount : 0;
      const outcome = isIncoming ? 0 : amount;
      if (daysList.hasOwnProperty(date)) {
        daysList[date] = {
          income: daysList[date].income + income,
          outcome: daysList[date].outcome + outcome
        }
      } else {
        daysList[date] = {
          income,
          outcome
        }
      }
    });

    // add grouping rows to transactions list
    transactions.forEach(t => {
      const date = t.execution_date.slice(0, 7);
      if (lastAdded !== date && daysList.hasOwnProperty(date)) {
        lastAdded = date;
        groupedTransactions.push({
          isMonthGrouping: true,
          execution_date: date,
          ...daysList[date]
        });
      }
      groupedTransactions.push(t);
    });
    return groupedTransactions;
  }

  useEffect(() => {
    const transactionsList = getGroupedTransactions(transactions);
    setData(transactionsList);
  }, [transactions]);

  const trans = (key) => t(`kyc:${key}`);
  const cols = [
    {name: t('type')},
    {name: trans('source')},
    {name: trans('classification')},
    {name: t('user')},
    {name: t('date')},
    {name: trans('cashIn'), className: 'main-font-color'},
    {name: trans('cashOut'), className: 'secondary-font-color'}
  ];

  const tableProps = {
    cols,
    minWidth,
    hasSelectAll: false,
    rowComponent: TransactionsTableRow,
    disableContainerHeight: true
  }

  return (
    <TableControlled
      {...tableProps}
      rows={helpers.createRows(data)}
      lastAlignRight={true}
      className={classes.table}
    />
  );
}

TransactionsTable.propTypes = {
  transactions: PropTypes.array
}

TransactionsTable.defaultProps = {
  transactions: []
}

export default TransactionsTable;
