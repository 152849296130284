import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@mui/styles';
import {InvoiceIcon, NotesIcon, ReportIcon, TagIcon} from '@h1-card/h1-lib-ui/dist/components/icons';
import {expenseTypesConstants} from '../../../../constants';


const useStyles = makeStyles(() => ({
  badges: {
    display: 'flex',
    '& span, svg > *': {
      transition: '.2s ease',
    },
    '& span:not(:last-of-type)': {
      marginRight: 8,
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const ExpenseBadges = ({ expense, hideNote }) => {
  const classes = useStyles();
  const {note, tags, expense_type} = expense.data;

  return (
    <div className={classes.badges}>
      <span data-testid='expense-type'>
        {expense_type === expenseTypesConstants.REPORT
          ? <ReportIcon size='medium' />
          : <InvoiceIcon size='medium' />}
      </span>
      {!hideNote && (
        <span data-testid='notes' className={!note ? 'disabled' : ''}>
          <NotesIcon size='medium' />
        </span>
      )}
      <span data-testid='tags' className={!tags ? 'disabled' : ''}>
        <TagIcon size='medium' />
      </span>
    </div>
  );
}

ExpenseBadges.propTypes = {
  expense: PropTypes.object.isRequired,
  hideNote: PropTypes.bool
}

ExpenseBadges.defaultProps = {
  hideNote: false
}

export default ExpenseBadges;
