import React from 'react';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import {helpers} from '../../../../helpers';


const useStyles = makeStyles(() => ({
  details: {
    maxWidth: '60%',
    '& div': {
      borderBottom: '1px solid #D8D7EA',
      marginBottom: 22,
      paddingBottom: 22
    }
  },
}));

const EmptySubmissionPreview = ({
  additionalTitleContent,
  user,
  userType,
  ...rest
}) => {
  const classes = useStyles();
  const [t] = useTranslation('kyc');

  const getDescription = () => {
    let text = t('noSubmissionFrom');
    if (user) {
      text = `${text} ${user.firstname} ${user.lastname} (${user.email})`;
    } else {
      text = `${text} ${t('thisUser')}`
    }
    return text;
  }

  const description = getDescription();

  const title = helpers.getUserTypeLabel(userType, t);

  return (
    <div
      {...rest}
      className={classes.details}
    >
      <div className='d-flex align-items-center justify-space-between f-20 fw-600'>
        {title}
        {additionalTitleContent}
      </div>
      {description}
    </div>
  );
}

EmptySubmissionPreview.propTypes = {
  additionalTitleContent: PropTypes.any,
  user: PropTypes.shape({
    firstname: PropTypes.string,
    email: PropTypes.string.isRequired,
    lastname: PropTypes.string,
  }),
  userType: PropTypes.string
}


export default EmptySubmissionPreview;
