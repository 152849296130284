import React from 'react';
import Helmet from 'react-helmet';
import { Route } from 'react-router-dom';

const RouteWithTitle = ({ title, ...props }) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Route {...props} />
    </>
  );
};

export default RouteWithTitle;
