import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@mui/styles';
import UserInfo from '@h1-card/h1-lib-ui/dist/components/molecules/UserInfo';
import TableCol from '@h1-card/h1-lib-ui/dist/components/molecules/Table/components/TableCol';
import TableRow from '@h1-card/h1-lib-ui/dist/components/molecules/Table/components/TableRow';
import {CreditCardIcon, RepeatIcon} from '@h1-card/h1-lib-ui/dist/components/icons';
import ExpenseBadges from '../ExpenseBadges';
import {helpers} from '../../../../helpers';
import {transactionTypesConstants} from '../../../../constants';

const useStyles = makeStyles(() => ({
  tableRow: {
    minWidth: 900,
    '& .row-container': {
      minHeight: 62,
      '& > div': {
        pointerEvents: 'unset',
        fontSize: 14
      }
    },
  },
  groupedRow: {
    '& .row-container': {
      '& > div': {
        fontSize: 16
      },
    },
    '&:not(:first-of-type)': {
      marginTop: 24
    },
  },
  typeCol: {
    '& i': {
      marginRight: 12
    }
  },
  budgetIcon: {
    marginRight: 8
  }
}));

const CardsTableRow = ({
  row,
  handleRowClick
}) => {
  const classes = useStyles();

  const {actionOpen, id, data} = row;
  const {amount, user, is_incoming: isIncoming, isMonthGrouping, execution_date: date} = data;

  const onRowClick = (e, id) => {
    e.stopPropagation();
    handleRowClick && handleRowClick(e, id);
  }

  if (isMonthGrouping) {
    const {income, outcome} = data;
    return (
      <div
        data-testid='row'
        className={`${classes.tableRow} ${classes.groupedRow}`}
      >
        <TableRow
          key={id}
          isActionOpen={actionOpen}
        >
          <TableCol>
            <span className={classes.budgetIcon}>
              {helpers.getDate(date, 'MMM YY')}
            </span>
          </TableCol>
          <TableCol />
          <TableCol />
          <TableCol />
          <TableCol />
          <TableCol>
            <span className='main-font-color'>
              &euro;{income}
            </span>
          </TableCol>
          <TableCol halign='right'>
            &euro;{outcome}
          </TableCol>
        </TableRow>
      </div>
    )
  }


  const isCard = data.transaction_type === transactionTypesConstants.CARD_TRANSACTION;

  return (
    <div
      data-testid='row'
      className={`${classes.tableRow} pointer`}
      onClick={(e) => onRowClick(e, data.id)}
    >
      <TableRow
        key={id}
        isActionOpen={actionOpen}
      >
        <TableCol>
          <div className={`${classes.typeCol} d-flex align-items-center`}>
            {isCard ? (
              <>
                <CreditCardIcon color='#0D175C' />
                {helpers.hideCardNumber(data.masked_pan)}
              </>
            ) : <RepeatIcon fill='#0D175C' />}
          </div>
        </TableCol>
        <TableCol>
          {data.source}
        </TableCol>
        <TableCol>
          {isCard && (
            <ExpenseBadges expense={row} hideNote={true} />
          )}
        </TableCol>
        <TableCol>
          {isCard && (
            <UserInfo
              size='medium'
              image={helpers.getObjProp(user, 'logo')}
              initials={helpers.getInitials(helpers.getObjProp(user, 'full_name'))}
            />
          )}
        </TableCol>
        <TableCol>
          {helpers.getDateWithMonth(date)}
        </TableCol>
        <TableCol>
          {isIncoming && <span className='main-font-color'>&euro;{amount}</span>}
        </TableCol>
        <TableCol halign='right'>
          {!isIncoming && <span className='secondary-font-color'>&euro;{amount}</span>}
        </TableCol>
      </TableRow>
    </div>
  )
}

CardsTableRow.propTypes = {
  row: PropTypes.object.isRequired,
  handleRowClick: PropTypes.func
}

export default CardsTableRow;
