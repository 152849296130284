import { userConstants } from '../constants';

const userState = {
  isAuth: false,
  checkedAuth: false,
  superusers: []
};


const userReducer = (state = userState, action) => {
  const {type, isAuth, user} = action;
  switch (type) {
    case userConstants.GET_USER_PROFILE:
      return {
        ...state,
        isAuth: true,
        checkedAuth: true,
        user
      }
    case userConstants.SET_CHECKED_AUTH:
      return {
        ...state,
        checkedAuth: true
      }
    case userConstants.SET_AUTH:
      return {
        ...state,
        isAuth
      }
    case userConstants.GET_USERS_LIST:
      return {
        ...state,
        superusers: action.users
      }
    default:
      return state
  }
};

export default userReducer;
