import React, {useState} from 'react';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@mui/styles';
import PhoneAuth from '../../components/pages/LoginPage/PhoneAuth';
import CodeAuth from '../../components/pages/LoginPage/CodeAuth';
import {authActions, userActions} from '../../state/actions';
import {history} from '../../state/history';
import {localStorageKeysConstants} from '../../constants';

import bgImage from '../../static/images/auth/login-background.png';
import PageContainer from "../../components/pages/LoginPage/PageContainer";
import SignUpHeader from "../../components/atoms/Header/SignUpHeader";

const useStyles = makeStyles(theme => ({
  images: {
    paddingTop: '4rem',

    [theme.breakpoints.only('md')]: {
      paddingTop: '2.5rem'
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '1.75rem',
    },

    '& p': {
      maxWidth: 220,
      fontSize: '0.875rem',
      marginBottom: '1rem',
      marginTop: 0,
      fontWeight: 500,
      color: '#343536',
    },
    '& img': {
      height: 40
    },
    '& a': {
      '&:last-of-type': {
        marginLeft: 10
      },
    },
  },
  userAction: {
    height: 100,

    [theme.breakpoints.down('sm')]: {
      height: 80,
    },
  },
  information: {
    fontSize: 14,
    color: '#03ab93',
    marginTop: 0,
    fontWeight: 600
  },
  codeAuth: {
    [theme.breakpoints.down('md')]: {
      '& .inputs': {
        '& > div:not(:last-of-type)': {
          marginRight: 12
        }
      },
    },

    '& input': {
      height: 54,
      borderRadius: 6,
      width: 22,
      fontSize: '2rem',

      [theme.breakpoints.down('md')]: {
        height: 50,
        width: 18,
        fontSize: '1.75rem'
      },

      [theme.breakpoints.down('sm')]: {
        height: 42,
        fontSize: '1.5rem'
      },
    }
  }
}));

const LoginPage = ({
  login,
  verification,
  getUserProfile
}) => {
  const [trans] = useTranslation('auth');
  const classes = useStyles();
  const t = (key) => trans(`login.${key}`);
  const [isCodeAuth, setIsCodeAuth] = useState(false);
  const [loginData, setLoginData] = useState(null);
  const [errors, setErrors] = useState({phone: null, code: null});

  const onPhoneAuth = (data) => {
    setLoginData(data);
    login(
      data,
      (session) => {
        setLoginData({
          ...data,
          session
        });
        setIsCodeAuth(true);
      },
      (phoneErrors) => setErrors({...errors, phone: phoneErrors})
    );
  }

  const onCodeAuth = (data) => {
    verification(
      {
        ...data,
        phone_number: loginData.phone_number,
        session: loginData.session,
      },
      () => {
        getUserProfile();
        localStorage.setItem(localStorageKeysConstants.PHONE_NUMBER, btoa(loginData.phone_number));
        history.push('/');
      },
      (codeErrors) => setErrors({...errors, code: codeErrors})
    );
  }

  const onResend = () => onPhoneAuth({
    phone_number: loginData.phone_number,
    password: loginData.password
  });

  const description = `${t('description')}<br />${t(isCodeAuth ? 'enterThe4DigitCode' : 'insertYourPhoneNumber')}`;

  return (
    <>
      <SignUpHeader
        buttonText={t('headerButton')}
        buttonLink='/sign-up'
      />
      <PageContainer
        title={t('title')}
        description={description}
        content={
          <div className={classes.userAction}>
            {
            isCodeAuth
              ? (
                <div className={classes.codeAuth}>
                  <CodeAuth
                    onSuccess={onCodeAuth}
                    onResend={onResend}
                    additionalErrors={errors.code}
                  />
                </div>
              )
              : (
                <>
                  <PhoneAuth
                    onSuccess={onPhoneAuth}
                    errors={errors.phone}
                  />
                </>
              )
          }
          </div>
        }
        bgImage={bgImage}
      />
    </>
  );
}

const mapDispatchToProps = {
  getUserProfile: userActions.getUserProfile,
  login: authActions.login,
  verification: authActions.codeVerification
}

export default connect(null, mapDispatchToProps)(LoginPage);
