import React from 'react';
import PropTypes from 'prop-types';
import ListItemBasic from '@h1-card/h1-lib-ui/dist/components/atoms/ListItemBasic';
import {
  StyledListItemArrowRight,
  StyledListItemIconLeft, StyledListItemIconRight,
  StyledListItemText, StyledOverIconContainer
} from '@h1-card/h1-lib-ui/dist/components/atoms/ListItem/StyledListItem';
import IconButton from '@h1-card/h1-lib-ui/dist/components/molecules/IconButton';
import InfoLabel from '@h1-card/h1-lib-ui/dist/components/atoms/InfoLabel';
import {ChevronRightIcon, DoneIcon} from "@h1-card/h1-lib-ui/dist/components/icons";
import ChevronDownIcon from "@h1-card/h1-lib-ui/dist/components/icons/ChevronDownIcon";


const getIconColor = (isDisabled, isActive) => {
  if (isDisabled) {
    return 'light_darker_1';
  }

  if (isActive) {
    return 'secondary_hover';
  }

  return 'dark';
};

const CustomText = ({count, text, isActive, countColor, image, countClassName, countType}) => {
  const flagString = image ? <div className='d-flex pre-image'>{image}</div> : null;
  return (
    <div className='d-flex align-items-center justify-space-between'>
      <div className={`d-flex align-items-center justify-space-between select-item ${isActive ? 'active' : ''}`}>
        <div className='d-flex align-items-center'>
          {flagString} {text}
        </div>
        {count !== null && (
          <>
            {countType === 'text' ? (
              <div className={`custom-label d-flex ${countClassName}`}>{count}</div>
            ) : (
              <InfoLabel
                className={`custom-label d-flex ${countClassName}`}
                label={`${count}`}
                style={{backgroundColor: countColor ? countColor :'#f2f2f2'}}
              />
            )}
          </>
        )}
      </div>
    </div>
  )
}

const ListItem = ({
  iconLeft,
  iconRight,
  text,
  children,
  hasArrow,
  isActive,
  isDisabled,
  onClick,
  onArrowClick,
  className,
  hoverIcon,
  count,
  countColor,
  image,
  countClassName,
  countType,
  onHoverButtonIconClick,
  parent,
  withChildren,
  onOpen,
  isOpen,
  isActiveAsParent,
  ...rest
}) => {
  const handleOnClick = () => {
    if (isDisabled || !onClick) {
      return false;
    }

    onClick(parent);
  };

  const onOpenClick = (e) => {
    e.stopPropagation();
    onOpen && onOpen();
  }

  const getIcon = (props) => {
    if (isActiveAsParent) {
      return <DoneIcon {...props} />
    } else {
      return isOpen ? <ChevronDownIcon {...props} /> : <ChevronRightIcon {...props} />
    }
  }

  const preImageText = withChildren && (
    <span className='tree-list-icon'>
      {getIcon({onClick: onOpenClick})}
    </span>
  )

  const textItem = (
    <CustomText
      tag='span'
      size='xbody'
      variant={getIconColor(isDisabled, isActive)}
      text={text}
      count={count}
      countColor={countColor}
      countClassName={countClassName}
      isActive={isActive}
      image={image}
      countType={countType}
    />
  );

  const iconLeftItem = iconLeft &&
    React.createElement(iconLeft, {variant: getIconColor(isDisabled, isActive)});

  const iconRightItem = iconRight &&
    React.createElement(iconRight, {
      size: 'xsmall',
      variant: getIconColor(isDisabled, isActive)
    });


  const iconArrowRight = (
    <ChevronRightIcon
      size='xsmall'
      variant={getIconColor(isDisabled, isActive)}
    />
  );

  const handleOnArrowClick = e => {
    onArrowClick();
    e.stopPropagation();
  };

  const handleOnHoverButtonIconClick = e => {
    onHoverButtonIconClick();
    e.stopPropagation();
  };

  return (
    <ListItemBasic
      isActive={isActive}
      isDisabled={isDisabled}
      onClick={handleOnClick}
      className={className}
      hoverIcon={hoverIcon}
      {...rest}
    >
      {preImageText && preImageText}
      {iconLeft && (
        <StyledListItemIconLeft data-testid='icon-left'>{iconLeftItem}</StyledListItemIconLeft>
      )}
      {text ? (
        <StyledListItemText>
          {textItem}
          {hoverIcon && (
            <StyledOverIconContainer>
              <IconButton
                icon={hoverIcon}
                size='small'
                onClick={e => handleOnHoverButtonIconClick(e)}
              />
            </StyledOverIconContainer>
          )}
        </StyledListItemText>
      ) : (
        children
      )}
      {hasArrow && (
        <StyledListItemArrowRight onClick={e => handleOnArrowClick(e)} data-testid='icon-arrow-right'>
          {iconArrowRight}
        </StyledListItemArrowRight>
      )}
      {!hasArrow && iconRight && (
        <StyledListItemIconRight data-testid='icon-right'>{iconRightItem}</StyledListItemIconRight>
      )}
    </ListItemBasic>
  );
};

ListItem.propTypes = {
  iconLeft: PropTypes.func,
  text: PropTypes.string,
  hasArrow: PropTypes.bool,
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  onArrowClick: PropTypes.func,
  hoverIcon: PropTypes.string,
  onHoverButtonIconClick: PropTypes.func,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) || null,
  countColor: PropTypes.string || null,
  parent: PropTypes.any,
  withChildren: PropTypes.bool,
  onOpen: PropTypes.func,
  isOpen: PropTypes.bool,
  isActiveAsParent: PropTypes.bool,
};

ListItem.defaultProps = {
  hasArrow: false
};

export default ListItem;
