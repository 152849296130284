import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 22px',
  },
  header: {
    fontSize: 20,
    '& svg': {
      marginRight: 12,
      height: 20,
      width: 20,
    },
    '& img': {
      height: 29,
      marginRight: 12,
    }
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    '&:not(:last-of-type)': {
      marginRight: 10,
    },
    '& button': {
      paddingLeft: 28,
      paddingRight: 28,
      minWidth: 128
    },
  },
}));

const PageHeader = ({
  title,
  icon,
  buttons,
  leftSideContent
}) => {
  const classes = useStyles();

  const iconView = () => {
    if (icon) {
      return typeof icon === 'object' ? icon : React.createElement(icon);
    } else {
      return null;
    }
  }

  const pageIcon = iconView();

  return (
    <div className={classes.container}>
      <div className='d-flex align-items-center'>
        <h2 className={`${classes.header} d-flex align-items-center`}>
          {pageIcon}<span data-test='title'>{title}</span>
        </h2>
        {leftSideContent && leftSideContent}
      </div>
      <div className={classes.buttons}>
        {buttons && buttons.map((button, key) =>
          <div className={classes.button} key={key}>{button}</div>)}
      </div>
    </div>
  );
}

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  buttons: PropTypes.array,
  leftSideContent: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
}

export default PageHeader;
