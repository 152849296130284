import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@mui/styles';

import { TZKYCLevelLabels, userTypesLabels } from '../../../../constants';

import TableCol from '@h1-card/h1-lib-ui/dist/components/molecules/Table/components/TableCol';
import TableRow from '@h1-card/h1-lib-ui/dist/components/molecules/Table/components/TableRow';


const useStyles = makeStyles(() => ({
  tableRow: {
    transition: '0.3s ease',
    minWidth: 700,
    '& .row-container > div': {
      pointerEvents: 'unset',
      fontSize: 14,
      paddingTop: 12,
      paddingBottom: 12,
      color: '#191927',
    },
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#F0F8FF',
    },
  }
}));

const UsersTableRow = ({
  row,
  handleRowClick
}) => {
  const classes = useStyles();
  const {id, data} = row;

  const user_name = data.user_type === 'legal_person' ? data.legal_name : `${data.firstname} ${data.lastname}`;
  const user_type = userTypesLabels[data.user_type] || 'unknown';
  const kyc_level = TZKYCLevelLabels[data.kyc_level] || 'unknown';

  return (
  <div
    data-testid='row'
    className={classes.tableRow}>
    <TableRow key={id}>
      <TableCol>
        {user_name}
      </TableCol>
      <TableCol>
        {data.phone}
      </TableCol>
      <TableCol>
        {data.city}
      </TableCol>
      <TableCol>
        {data.email}
      </TableCol>
      <TableCol>
        {user_type}
      </TableCol>
      <TableCol>
        {data.h1_review_status}
      </TableCol>
      <TableCol>
        {kyc_level}
      </TableCol>
      <TableCol>
        {data.kyc_review}
      </TableCol>
      <TableCol>
        {data.kyc_review_comment}
      </TableCol>
    </TableRow>
  </div>)
}

UsersTableRow.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    data: PropTypes.shape({
      id: PropTypes.string.isRequired,
      legal_name: PropTypes.string.isRequired,
      firstname: PropTypes.string.isRequired,
      lastname: PropTypes.string.isRequired,
      phone: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      user_type: PropTypes.string.isRequired,
      h1_review_status: PropTypes.string.isRequired,
    })
  }).isRequired
}

export default UsersTableRow;
